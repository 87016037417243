import React, { useState, useEffect, useRef } from "react"
import Select, { components } from "react-select";
import {
  Card,
  Col,
  Container,
  Row,
  Alert,
  Button,
  CardBody,
  Form,
  Label,
  Input
} from "reactstrap"
import { useNavigate } from "react-router-dom"
import { Link } from "react-router-dom"
import TxnNavbar from "./TxnNavbar"
import Loader from "components/Common/Loader"
import { saveDetails, retrieveDetails, processWP } from "helpers/backend_helper"
import { getAddress, isNumber, getMonthsDD, getContMonthsDesc, findTheMax, getNextCollectionDate} from "helpers/function_helper"
import { isEmpty } from "lodash";

const PaymentHome = props => {
  const navigate = useNavigate();
  document.title = "Drops | Donate";

  const [inputToRetrieve, setInputToRetrieve] = useState('')
  const [error, setError] = useState('')
  const [showLoading, setShowLoading] = useState(false)
  const [gotMultipleResponse, setGotMultipleResponse] = useState(false)
  const [showUserDetails, setShowUserDetails] = useState(false)
  const [donateAsGuest, setDonateAsGuest] = useState(false)
  const [userDetails, setUserDetails] = useState({})
  const [paymentDetails, setPaymentDetails] = useState({
    type: ''
  })
  const [monthsDD, setMonthsDD] = useState(getMonthsDD(-1,12))
  const [contributingMonths, setContributingMonths] = useState([])
  const [allResponses, setAllResponses] = useState([])

  const [validation, setValidation] = useState({
    inputToRetrieve: null,
  })

  const mandatoryFields = ['inputToRetrieve']

  const onChangeValidation = (fieldName, value) => {
    const modifiedV = { ...validation }

    if(mandatoryFields.includes(fieldName)){
      if (value !== "") {
        modifiedV[fieldName] = true
      } else {
        modifiedV[fieldName] = false
      }
      setValidation(modifiedV)
    }
    setValidation(modifiedV)
  }

  async function validateAndretrieveRegistrationDetails(){
    let formValid = validateRetrievalForm()
    if(formValid){
      retrieveRegistrationDetails(inputToRetrieve)
    }
  }

  function validateRetrievalForm(){

    const modifiedV = { ...validation }
    let formValid = true;
    if(inputToRetrieve && inputToRetrieve != undefined && inputToRetrieve.trim() != "" && inputToRetrieve.trim().length > 5){
      modifiedV['inputToRetrieve'] = true
    }
    else{
      modifiedV['inputToRetrieve'] = false
      formValid = false
    }
    setValidation(modifiedV)
    return formValid
  }

  async function retrieveRegistrationDetails(id){
    //initialiseForm()
    setError('')
    setShowLoading(true)
    const inputToSend = {
      "data" : {
        "type" : "RET_REG_P",
        "user" : {
          id:id.trim()
        }
      }
    }
    const response = await retrieveDetails(inputToSend);
    setShowLoading(false)
    if(response && response.status === 'failure'){
      setError(response.error.errorMsg)
    }
    else if(response && response.status === 'success' && response.data){
      if(response.data.length > 1){
        setGotMultipleResponse(true)
        setAllResponses(response.data)
      }
      else{
        convertToUserObj(response.data[0])
        setShowUserDetails(true)
      }
      //prefillEmpDetails(response.data)
      //setIsEdit(true)
      //setRegistrationConfirmationId(id)
      //setRefId(id)
    }
}

function convertToUserObj(data){
  const retData = {
    name: data.fullname,
    address: getAddress(data),
    postOffice: data.post_office,
    pinCode: data.pin_code,
    panchayath: data.panchayath,
    mobileNo:data.mobile_no,
    whatsAppNo:data.whatsapp_no,
    monthlySubsAmount:data.monthly_amount,
    subsDepositMethod:data.deposit_method,
    modeOfPayment:data.mode_payment,
    place:data.place,
    recordStatus: data.record_status,
    randomId: data.random_id,
    regId: data.regId,
    id: data.id,
    nextContDate: data.next_contribution_date,
    lastContDate: data.last_contribution_date,
    collectionDate: data.collection_date,
  }
  setUserDetails(retData)
}

const updateContributingMonths=(selectedMonths)=>{

  //console.log('userDetails:',userDetails)

  const highestDate = findTheMax(selectedMonths)
  
  setPaymentDetails({
    ...paymentDetails,
    contMonths : selectedMonths,
    contMonthsDesc: getContMonthsDesc(selectedMonths),
    lastPaymentMonth: highestDate,
    nextDueDate: getNextCollectionDate(highestDate,userDetails.collectionDate)
  })
}

const onCancelPayment=()=>{
  setDonateAsGuest(false)
  setGotMultipleResponse(false)
  setAllResponses([])
  setShowUserDetails(false)
  setPaymentDetails({})
  setUserDetails({})
  setContributingMonths([])
  setValidationPay({
    subsAmount: null,
    contMonths: null,
  })
}

const onConfirmTheCorrect=(record)=>{
  //console.log(record)
  setGotMultipleResponse(false)
  setAllResponses([])
  convertToUserObj(record)
  setShowUserDetails(true)
}

const onChangeValidationPay = (fieldName, value) => {
  const modifiedV = { ...validationPay }

  if(mandatoryPaymentFields.includes(fieldName)){
    if (value !== "") {
      modifiedV[fieldName] = true
    } else {
      modifiedV[fieldName] = false
    }
    setValidationPay(modifiedV)
  }
  setValidationPay(modifiedV)
}

const mandatoryPaymentFields = ['subsAmount','contMonths']
const mandatoryPaymentGuestFields = ['subsAmount','name','mobileNo']

const [validationPay, setValidationPay] = useState({
  subsAmount: null,
  contMonths: null,
})

const [validationGuestPay, setValidationGuestPay] = useState({
  subsAmount: null,
  name: null,
  mobileNo: null,
})

const onChangeValidationGuestPay = (fieldName, value) => {
  const modifiedV = { ...validationGuestPay }

  if(mandatoryPaymentGuestFields.includes(fieldName)){
    if (value !== "") {
      modifiedV[fieldName] = true
    } else {
      modifiedV[fieldName] = false
    }
    setValidationGuestPay(modifiedV)
  }
  setValidationGuestPay(modifiedV)
}

function validatePaymentGuestForm(){

  const modifiedV = { ...validationGuestPay }
  let formValid = true;
  if(isEmpty(paymentDetails.subsAmount)){
    modifiedV['subsAmount'] = false
    formValid = false
  }
  else if(Number(paymentDetails.subsAmount) < 100){
    modifiedV['subsAmount'] = false
    formValid = false
  }
  else{
    modifiedV['subsAmount'] = true
  }

  if(isEmpty(paymentDetails.name)){
    modifiedV['name'] = false
    formValid = false
  }
  else{
    modifiedV['name'] = true
  }

  if(isEmpty(paymentDetails.mobileNo)){
    modifiedV['mobileNo'] = false
    formValid = false
  }
  else{
    modifiedV['mobileNo'] = true
  }

  setValidationGuestPay(modifiedV)
  return formValid
}

function validatePaymentForm(){

  const modifiedV = { ...validationPay }
  let formValid = true;
  if(isEmpty(paymentDetails.subsAmount)){
    modifiedV['subsAmount'] = false
    formValid = false
  }
  else if(Number(paymentDetails.subsAmount) < 100){
    modifiedV['subsAmount'] = false
    formValid = false
  }
  else{
    modifiedV['subsAmount'] = true
  }

  if(isEmpty(paymentDetails.contMonths)){
    modifiedV['contMonths'] = false
    formValid = false
  }
  else{
    modifiedV['contMonths'] = true
  }

  setValidationPay(modifiedV)
  return formValid
}

const onMakePayment=async(type)=>{
  setError('')
  let formValid = false;
  if(type == 'registered'){
    formValid = validatePaymentForm()
  }
  else if(type == 'guest'){
    formValid = validatePaymentGuestForm()
  }
  
  if(formValid){
    setShowLoading(true)
      let inputToSend = {
        "data" : {
          "type" : "INITIATE_PAYMENT",
          "payment" : {
            ...paymentDetails,
            type: type,
            userId: userDetails.id,
            regNo: userDetails.regId,
            mobileNo: userDetails.mobileNo,
          }
        }
      }

      if(type == 'guest'){
        inputToSend = {
          "data" : {
            "type" : "INITIATE_PAYMENT",
            "payment" : {
              ...paymentDetails,
              type: type,
              userId: '100',
              regNo: '100185',
            }
          }
        }
      }

      const response = await saveDetails(inputToSend);
      //setShowLoading(false)
      //scrollToResults()
      if(response && response.status === 'failure'){
        setError(response.error.errorMsg)
        setShowLoading(false)
      }
      else if(response && response.status === 'success'){
        const inputToSend = {
          "data" : {
            "type" : "PP_INIT",
            "xverify": response.data.xverify,
            "request": response.data.requestB64
          }
        }
        const responseWP = await processWP(inputToSend);
        if(responseWP.code == 'PAYMENT_INITIATED' 
        && responseWP.data && responseWP.data.instrumentResponse 
        && responseWP.data.instrumentResponse.redirectInfo
        && responseWP.data.instrumentResponse.redirectInfo.url){
          localStorage.setItem('pppay_data',JSON.stringify({
            ...response.data,
            userId: userDetails.id,
            regNo: userDetails.regId,
            contMonthsDesc: paymentDetails.contMonthsDesc,
            subsAmount: paymentDetails.subsAmount,
            address: type == 'guest' ? paymentDetails.address : userDetails.address,
            name: type == 'guest' ? paymentDetails.name : userDetails.name,
            whatsAppNo: type == 'guest' ? paymentDetails.mobileNo : userDetails.whatsAppNo,
            mobileNo: type == 'guest' ? paymentDetails.mobileNo : userDetails.mobileNo,
            type: type
          }))
          window.location.replace(responseWP.data.instrumentResponse.redirectInfo.url)
        }
        else{
          setError('Sorry, Unable to Process. Please try again later.')
          setShowLoading(false)
        }
        //console.log(responseWP)
        
        //setShowPaymentSuccess(true)
        //setReceiptId(response.data.id)
        //setAmountInWords(response.data.amountInWords)
      }
  }
}

const onRegisterClick=()=>{
  navigate('/register')
}

const onContinueAsGuest=()=>{
  setDonateAsGuest(true)
}

  return (
    <React.Fragment>
      <Loader showLoader={showLoading} />
      <TxnNavbar/>
      <section className="small-section" id="payment">
        <Container fluid={false}>
          <Row className="justify-content-center mt-2">
          </Row>
          <Row>
            <Col md="8" sm="12" className="order-2 order-md-1 mt-3 mt-md-0">
              <Row className="bg-light m-0 py-1 mb-1 mt-0">
                <div className="text-center fw-bold font-size-15">
                  Donate
                </div>
              </Row>
              <Row>
                <Col lg="12">
                  <Card>
                    <CardBody className="p-0">
                      <div className="wizard clearfix">
                        <div className="content clearfix mt-2">
                        {error ? <Alert color="danger" className="text-center mb-2">{error}</Alert> : null}
                          {!showUserDetails && !gotMultipleResponse && !donateAsGuest &&
                          <Form>
                            <Row>
                              <Col md="6" sm="12">
                                <div className="mb-3">
                                  <Label for="inputToRetrieve">
                                    Registration ID / Mobile Number
                                  </Label>
                                  <Input
                                    type="text"
                                    className="form-control"
                                    id="inputToRetrieve"
                                    maxLength="14"
                                    value={inputToRetrieve || ''}
                                    onChange={e => {
                                      setInputToRetrieve(e.target.value.trim())
                                      onChangeValidation("inputToRetrieve", e.target.value.trim())
                                    }}
                                  />
                                  {(validation["inputToRetrieve"] === false) &&
                                    <div className="custom-error">
                                      Please enter a valid Registration Number or Mobile Number
                                    </div>
                                  }
                                </div>
                                <div>
                                  <Button
                                    color="success"
                                    className="btn w-100 drops-bg-shade-9 btn-block"
                                    onClick={() => {
                                      validateAndretrieveRegistrationDetails()
                                    }}
                                    >
                                      Retrieve
                                  </Button>
                                </div>
                              </Col>
                              <Col md="6" sm="12" className="text-center mt-1">
                                <div>
                                  Not registered yet?
                                </div>
                                <div className="mt-2 mb-1">
                                  <button onClick={(e)=>{
                                      e.preventDefault()
                                      onRegisterClick()
                                    }} className="btn btn-sm btn-soft-primary w-100">
                                    Register
                                  </button>
                                </div>
                                  or 
                                <div className="mt-1">
                                 <button onClick={(e)=>{ 
                                   e.preventDefault()
                                    onContinueAsGuest()
                                  }} className="btn btn-sm btn-soft-info w-100">
                                    Donate as Guest
                                  </button>
                                </div>
                              </Col>
                            </Row>
                          </Form>
                          }
                          {gotMultipleResponse && 
                          <>
                            <div className="font-size-13 mb-3">
                              We have found multiple records with the given details. Please select the correct one to continue.
                            </div>
                            {allResponses.map((each,i)=>(
                                <Row key={i} className="py-1 border-bottom">
                                  <Col md="2" xs="5">
                                    {each.regId}
                                  </Col>
                                  <Col md="3" xs="5">
                                    {each.fullname}
                                  </Col>
                                  <Col md="3" xs="5">
                                    {each.house_name}
                                  </Col>
                                  <Col md="2" xs="5">
                                    {each.mobile_no}
                                  </Col>
                                  <Col md="2" xs="2">
                                    <Button
                                    color="info"
                                    className="btn btn-sm btn-soft-info"
                                    onClick={() => {
                                      onConfirmTheCorrect(each)
                                      //const regData = cellProps.row.original;
                                      //handleEdit('/edit-reg/'+regData.id+''+regData.random_id)
                                    }}
                                    >
                                      Select
                                    </Button>
                                  </Col>
                                </Row>
                            ))}
                            <div className="hstack gap-2 mt-3">
                              <button onClick={()=>onCancelPayment()} className="btn btn-soft-secondary w-100">
                                  <i className="mdi mdi-close-thick font-size-15 align-middle" /> Cancel
                                </button>
                            </div>
                          </>
                          }
                          {showUserDetails &&
                          <>
                            <h5 className="fw-semibold">Confirm Details</h5>

                            <div className="table-responsive">
                                <table className="table">
                                    <tbody>
                                        <tr>
                                          <td>Name</td>
                                          <th scope="row">{userDetails.name}</th>
                                        </tr>
                                        <tr>
                                          <td>Address</td>
                                          <th scope="row">{userDetails.address}</th>
                                        </tr>
                                        <tr>
                                          <td>Reference No.</td>
                                          <th scope="row">{userDetails.regId}</th>
                                        </tr>
                                        <tr>
                                          <td>Mobile No.</td>
                                          <th scope="row">{userDetails.mobileNo}</th>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className=" clearfix">
                              <div className="content clearfix mt-0">
                                <Row className="mt-0">
                                  <Col xl={4} xs={12} md={4}>
                                    <div className="mb-3">
                                      <Label for="subsAmount">
                                        Amount *
                                      </Label>
                                      <Input
                                        type="tel"
                                        className="form-control"
                                        id="subsAmount"
                                        maxLength="12"
                                        value={paymentDetails.subsAmount || ''}
                                        onChange={e => {
                                          if(isNumber(e.target.value)){
                                            setPaymentDetails({
                                              ...paymentDetails,
                                              subsAmount : e.target.value
                                            })
                                            onChangeValidationPay("subsAmount", e.target.value.trim())
                                          }
                                        }}
                                      />
                                      {(validationPay["subsAmount"] === false) &&
                                        <div className="custom-error">
                                          Please enter a valid Amount
                                        </div>
                                      }
                                    </div>
                                  </Col>
                                  <Col xl={8} xs={12} md={8}>
                                    <div className="mb-3">
                                      <Label for="contMonths">
                                        Contribution for *
                                      </Label>
                                      <Select
                                        value={contributingMonths}
                                        isClearable={true}
                                        onChange={(e) => {
                                          setContributingMonths(e)
                                          updateContributingMonths(e)
                                          onChangeValidationPay('contMonths',e)
                                        }}
                                        isMulti={true}
                                        options={monthsDD}
                                        classNamePrefix="select2-selection"
                                        id="contMonths"
                                        styles={{menuPortal:base => ({...base, zIndex:9999})}}
                                      />
                                      {(validationPay["contMonths"] === false) &&
                                        <div className="custom-error">
                                          Please select a valid Month
                                        </div>
                                      }
                                    </div>
                                  </Col>
                                </Row>
                              </div>
                            </div>
                            <div className="hstack gap-2 mt-2">
                                <button onClick={()=>onCancelPayment()} className="btn btn-soft-secondary w-100">
                                  <i className="mdi mdi-close-thick font-size-18 align-middle" /> Cancel
                                </button>
                                <button onClick={()=>onMakePayment('registered')} className="btn drops-bg-shade-9 w-100">
                                  <i className="mdi mdi-cash-plus font-size-18 align-middle" /> Make Payment
                                </button>
                                {/**
                                <button disabled={downLoadInProgress} onClick={()=>downloadReceipt()} className="btn btn-soft-primary w-100">
                                  <i className="mdi mdi-download font-size-18 align-middle" /> Download
                                  {downLoadInProgress && <i className="bx bx-loader bx-spin font-size-18 align-middle ms-2" />}
                                </button>
                                <button disabled={downLoadInProgress} onClick={()=>sendReceipt()} className="btn btn-soft-success w-100">
                                  <i className="mdi mdi-whatsapp font-size-18 align-middle" /> Send
                                  {downLoadInProgress && <i className="bx bx-loader bx-spin font-size-18 align-middle ms-2" />}
                                </button>
                                 */}
                            </div>
                          </>
                          }
                          {donateAsGuest &&
                            <>
                              <div className=" clearfix">
                                <div className="content clearfix mt-0">
                                  <Row className="mt-0">
                                    <Col xl={6} xs={12} md={6}>
                                      <div className="mb-3">
                                        <Label for="name">
                                          Name *
                                        </Label>
                                        <Input
                                          type="text"
                                          className="form-control"
                                          id="name"
                                          maxLength="50"
                                          value={paymentDetails.name || ''}
                                          onChange={e => {
                                            
                                              setPaymentDetails({
                                                ...paymentDetails,
                                                name : e.target.value
                                              })
                                              onChangeValidationGuestPay("name", e.target.value.trim())
                                            
                                          }}
                                        />
                                        {(validationGuestPay["name"] === false) &&
                                          <div className="custom-error">
                                            Please enter a valid Name
                                          </div>
                                        }
                                      </div>
                                    </Col>
                                    <Col xl={6} xs={12} md={6}>
                                      <div className="mb-3">
                                        <Label for="mobileNo">
                                          Mobile Number *
                                        </Label>
                                        <Input
                                          type="tel"
                                          className="form-control"
                                          id="mobileNo"
                                          maxLength="12"
                                          value={paymentDetails.mobileNo || ''}
                                          onChange={e => {
                                            if(isNumber(e.target.value)){
                                              setPaymentDetails({
                                                ...paymentDetails,
                                                mobileNo : e.target.value
                                              })
                                              onChangeValidationGuestPay("mobileNo", e.target.value.trim())
                                            }
                                          }}
                                        />
                                        {(validationGuestPay["mobileNo"] === false) &&
                                          <div className="custom-error">
                                            Please enter a valid Mobile Number
                                          </div>
                                        }
                                      </div>
                                    </Col>
                                    <Col xl={12} xs={12} md={12}>
                                      <div className="mb-3">
                                        <Label for="address">
                                          Address
                                        </Label>
                                        <textarea
                                          id="address"
                                          className="form-control"
                                          rows="2"
                                          maxLength="150"
                                          value={paymentDetails.address || ''}
                                          onChange={e => {
                                            //onChangeValidation("comments", e.target.value.trim())
                                            setPaymentDetails({
                                              ...paymentDetails,
                                              address : e.target.value
                                            })
                                          }}
                                        />
                                      </div>
                                    </Col>
                                    <Col xl={6} xs={12} md={6}>
                                      <div className="mb-3">
                                        <Label for="subsAmount">
                                          Amount *
                                        </Label>
                                        <Input
                                          type="tel"
                                          className="form-control"
                                          id="subsAmount"
                                          maxLength="12"
                                          value={paymentDetails.subsAmount || ''}
                                          onChange={e => {
                                            if(isNumber(e.target.value)){
                                              setPaymentDetails({
                                                ...paymentDetails,
                                                subsAmount : e.target.value
                                              })
                                              onChangeValidationGuestPay("subsAmount", e.target.value.trim())
                                            }
                                          }}
                                        />
                                        {(validationGuestPay["subsAmount"] === false) &&
                                          <div className="custom-error">
                                            Please enter a valid Amount
                                          </div>
                                        }
                                      </div>
                                    </Col>
                                    <Col xl={12} xs={12} md={12}>
                                      <div className="hstack gap-2 mt-2">
                                          <button onClick={()=>onCancelPayment()} className="btn btn-soft-secondary w-100">
                                            <i className="mdi mdi-close-thick font-size-18 align-middle" /> Cancel
                                          </button>
                                          <button onClick={()=>onMakePayment('guest')} className="btn drops-bg-shade-9 w-100">
                                            <i className="mdi mdi-cash-plus font-size-18 align-middle" /> Make Payment
                                          </button>
                                      </div>
                                    </Col>

                                  </Row>
                                </div>
                              </div>
                            </>
                          }
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
            <Col md="4" sm="12" className="order-1 order-md-2">
              <div className="font-size-15 text-justify">
                Every drop matters.
              </div>
              <div className="mt-4 font-size-14 text-justify">
                With your generous support, we can make a positive impact on the lives of those in need. Every donation, no matter how small, brings us closer to achieving our mission of creating a better world for all.
              </div>
              <div className="mt-4 font-size-14 text-justify">
                Here at DROPS, we are committed to transparency and accountability. Your payment is secure, and we use the latest encryption technology to ensure the safety of your personal information.
              </div>
            </Col>
          </Row>
          <Row>

          </Row>
        </Container>
      </section>

    </React.Fragment>
  )
}

PaymentHome.propTypes = {
}

export default PaymentHome
