import React from "react"
import { Row, Col } from "reactstrap"

const DropsRegistrationDetails = props => {
  return (
    <>
    <Row className="justify-content-center bg-white m-0 py-2 mb-1 mt-0">
      <Col xs={12} md={9} lg={8} xl={6}>
        <div className="text-center fw-bold drops-title font-size-15 ">
          DROPS Charitable and Welfare Society
        </div>
        <div className="text-center drops-title font-size-14">
          B.No. 2096, Ward-9, Menonpotty Rd, Near Palliative Clinic
          <br/>
          Edakkara PO, Malappuram DT, Kerala
        </div>
        <div className="text-center fw-bold drops-title font-size-15 pt-2">
          Reg. No : MPM/CA/352/2023
        </div>
      </Col>
    </Row>
    </>
  )
}

DropsRegistrationDetails.propTypes = {
}

export default DropsRegistrationDetails
