import PropTypes from 'prop-types';
import React, { useState } from "react";
import {
  Nav,
  NavbarToggler,
  NavItem,
  NavLink,
  Container,
  Collapse,
} from "reactstrap";
import { Link } from "react-router-dom";
import ScrollspyNav from "./scrollSpy";

//Import Images
import logodark from "assets/images/logo.png"
//import logodark from "../../../assets/images/logo-dark.png";
import logolight from "assets/images/logo-inv-1.png";
import LanguageDropdown from "../../../components/CommonForBoth/TopbarDropdown/LanguageDropdown";

const navItems = [
  { id: 1, idnm: "home", navheading: "Home" },
  { id: 2, idnm: "about", navheading: "About" },
  { id: 3, idnm: "footer", navheading: "Contact Us" },
  //{ id: 3, idnm: "roadmap", navheading: "Roadmap" },
  //{ id: 4, idnm: "team", navheading: "Team" },
  //{ id: 5, idnm: "news", navheading: "News" },
  //{ id: 6, idnm: "faqs", navheading: "FAQs" },
];

const Navbar_Page = props => {
  const [isOpenMenu, setisOpenMenu] = useState(false);

  //Store all NavigationbaFr Id into TargetID variable(Used for Scrollspy)
  let TargetId = navItems.map(item => {
    return item.idnm;
  });

  return (
    <React.Fragment>
      <nav
        className={"navbar navbar-expand-lg navigation fixed-top sticky " + props.navClass}
      >
        <Container>
          <Link className="navbar-logo" to="/">
            {props.imglight !== true ? (
              <img
                src={logodark}
                alt=""
                height="50"
                className="logo logo-dark"
              />
            ) : (
              <img
                src={logolight}
                alt=""
                height="50"
                className="logo logo-light"
              />
            )}
          </Link>

          <NavbarToggler
            className="p-0"
            onClick={() => {
              setisOpenMenu(!isOpenMenu);
            }}
          >
            <i className={props.imglight !== true ? "fa fa-fw fa-bars" : "fa fa-fw fa-bars text-white"} />
          </NavbarToggler>

          <Collapse id="topnav-menu-content" isOpen={isOpenMenu} navbar className={isOpenMenu? "drops-bg-shade-1" : ""}>
            <ScrollspyNav
              scrollTargetIds={TargetId}
              scrollDuration="500"
              headerBackground="false"
              activeNavClass="active"
              className="navbar-collapse"
            >
              <Nav className="ms-auto navbar-nav " id="topnav-menu">
                {navItems.map((item, key) => (
                  <NavItem
                    key={key}
                    className={item.navheading === "Home" ? "active" : ""}
                  >
                    <NavLink href={"#" + item.idnm}> {item.navheading}</NavLink>
                  </NavItem>
                ))}
              </Nav>
            </ScrollspyNav>
            <LanguageDropdown />
            <div className="ms-lg-2 ms-1">
              <Link to="/register" className="btn drops-bg-shade-8 w-xs">
                Register
              </Link>
            </div>
            <div className="ms-lg-2 ms-1 my-1 my-md-0">
              <Link to="/donate" className="btn drops-bg-shade-7 w-xs">
                Donate
              </Link>
            </div>
          </Collapse>
        </Container>
      </nav>
    </React.Fragment>
  );
};

Navbar_Page.propTypes = {
  imglight: PropTypes.any,
  navClass: PropTypes.string
};

export default Navbar_Page;
