import React,{useState,useEffect} from "react"
import { Card, CardBody, Col, Row, Spinner } from "reactstrap"
import ReactApexChart from "react-apexcharts"

const ApexChartComponent = props => {

  const [seletedMonth, setSeletedMonth] = useState("15d")
  const [series, setSeries] = useState([])
  const [options, setOptions] = useState(getOptions())
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if(props && props.type == 'donut' && props.series && props.series[0]){
      setSeries(props.series[0].data)
    }
    else{
      setSeries(props.series)
    }
    
  }, [props.series]);

  useEffect(() => {
    if(props && props.type == 'donut'){
      setOptions({
        ...options,
        xaxis: {
          categories: props.xaxisCategories,
        },
        labels: props.xaxisCategories,
        fill: {
          type: "gradient",
          gradient:{}
        },
        colors: getColors(props.id)
      })
    }
    else{
      setOptions({
        ...options,
        xaxis: {
          categories: props.xaxisCategories,
          title: {
            text: props.xaxisTitle ? props.xaxisTitle : undefined,
          }
        },
        colors: getColors(props.id),
        labels: props.xaxisCategories,
        fill: {
          type: "solid",
          gradient: {
            shadeIntensity: 1,
            inverseColors: false,
            opacityFrom: 0.45,
            opacityTo: 0.05,
            stops: [20, 100, 100, 100],
          },
        },
      })
    }
  
    
  }, [props.xaxisCategories]);

  const onChangeMonth = value => {
    //setSeletedMonth(value)
    //getSalesData(value,'saleschart')
  }

  /*
  const getSalesData = async(input,type) => {
    setLoading(true)
    getSalesChartData({
      key : input,
      id: type
    }).then(response => {
      if(response.series){
        setSeries(response.series)
        setOptions({
          ...options,
          xaxis: {
            categories: response.xaxisCategories
          }
        })
        setLoading(false)
      }
    })
  }
  */

  /*
  const series = [
    {
      name: "Current",
      data: [18, 21, 45, 36, 65, 47, 51, 32, 40, 28, 31, 26],
    },
    {
      name: "Previous",
      data: [30, 11, 22, 18, 32, 23, 58, 45, 30, 36, 15, 34],
    },
  ]
*/

function getColors(type){
  let colors = ["#34c38f", "#556ee6"]
  switch (type) {
    case 'state':
      colors = ["#34c38f", "#556ee6"]
      break;
    case 'bank':
      colors = ["#6F1AB6", "#3D1766"]
      break;
    case 'age':
      colors = ["#CB1C8D", "#F56EB3"]
      break;
    case 'ctc':
      colors = ["#5DA7DB", "#7DE5ED"]
      break;
    case 'category':
      colors = ["#F94A29", "#698269", "#5B8FB9", "#B5D5C5"]
      break;
    default:
      break;
  }

  return colors
}
  function getOptions(){
    return {
      chart: {
        height: 300,
        type: props.type,
        toolbar: {
          show: true,
        },
      },
      theme: {
        palette: 'palette9' // upto palette10
      },
      colors: ["#34c38f", "#556ee6"],
      dataLabels: {
        enabled: true,
          formatter: function (val) {
            return val + "";
          },
          offsetY: -25,
          style: {
            fontSize: '12px',
            colors: ["#304758"]
          }
      },
      plotOptions: {
        pie: {
          startAngle: -90,
          endAngle: 270
        },
        bar: {
          borderRadius: 5,
          dataLabels: {
            position: 'top', // top, center, bottom
          },
        }
      },
      responsive: [{
        breakpoint: 480,
        options: {
          chart: {
            width: 200
          },
          legend: {
            position: 'bottom'
          }
        }
      }]
      ,
      stroke: {
        curve: "smooth",
        width: 2,
      },
      xaxis: {
        categories: [
        ],
      },
  
      markers: {
        size: 3,
        strokeWidth: 3,
  
        hover: {
          size: 4,
          sizeOffset: 2,
        },
      },
      legend: {
        position: "top",
        horizontalAlign: "center",
      }

    }
  }

  return (
    <React.Fragment>
        <Card>
          <CardBody>
            <div className="clearfix">

              {/*}
              <div className="float-end">
                <div className="input-group input-group">
                  <select
                    className="form-select form-select-sm"
                    value={seletedMonth}
                    onChange={(e) => {
                      onChangeMonth(e.target.value)
                    }}
                  >
                    <option value="15d">Last 15 Days</option>
                    <option value="30d">Last 1 Month</option>
                    <option value="3m">Last 3 Months</option>
                    <option value="6m">Last 6 Months</option>
                    <option value="11m">Last 1 Year</option>
                  </select>
                </div>
              </div>
              */}

              {props.loading && <div className="float-end">
               <Spinner style={{ width: '1.5rem', height: '1.5rem' }} className="mx-1" color="primary" />
              </div> 
              }
              <h4 className="card-title mb-4">{props.title}</h4>
            </div>

            <div id="area-chart" dir="ltr">
              
              <ReactApexChart
                options={options}
                series={series}
                type={props.type}
                height={300}
                className="apex-charts"
              />
            </div>
          </CardBody>
        </Card>
    </React.Fragment>
  )
}

export default ApexChartComponent
