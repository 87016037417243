import React, { useState, useEffect, useRef } from "react"
import {
  Card,
  Col,
  Container,
  Row,
  Alert,
  UncontrolledAlert
} from "reactstrap"

import { Link } from "react-router-dom"
import logo from "assets/images/logo.jpeg"

const RegistrationSuccess = props => {

  return (
    <React.Fragment>
      <div className="">
        <Container fluid={false}>
          <Row className="justify-content-center mt-2">
            <Col xs={12} md={9} lg={8} xl={6}>
              <Card className="overflow-hidden mb-1">
                <div className="primary-dark bg-soft">
                  <Row>
                    <Col className="col-5 justify-content-end text-center d-flex align-items-center p-1">
                      <img src={logo} alt="" className="img-fluid" style={{maxHeight:"60px"}}/>
                    </Col>
                    <Col xs={7} className="d-flex align-items-center">
                      <div className="">
                        <h5 className="drops-title pb-0 mb-0">ഒന്നായ് തണലേകാം</h5>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Card>
            </Col>
          </Row>
          <Row className="bg-light m-0 py-2 mb-1 mt-0 text-center">
            <Alert color="success">
              <i className="mdi mdi-check-all me-2"></i>
              നിങ്ങളുടെ രജിസ്ട്രേഷൻ പൂർത്തീകരിച്ചിരിക്കുന്നു. <br/><br/> <b>Thank You</b> for being part of <span className="drops-title fw-bold">DROPS</span> family.
            </Alert>
          </Row>
          <Row>
          <div className="mt-4 text-center">
            <Link to="/register" className="text-primary">
              <i className="mdi mdi-plus-box-outline me-1" />
              Add New Registration
            </Link>
          </div>
          </Row>
        </Container>
      </div>

    </React.Fragment>
  )
}

RegistrationSuccess.propTypes = {
}

export default RegistrationSuccess
