import React, { useState, useEffect, useRef } from "react"
import {
  Card,
  CardBody,
  Col,
  Row,
  Table,
} from "reactstrap"


const EditEmployeeChanges = props => {

  const filedMap = {
    name: "Name",
    houseName: "House Name",
    postOffice: "Post Office",
    pinCode: "PIN Code",
    panchayath: "Panchayath",
    wardNo:"Ward No",
    mobileNo:"Mobile No",
    whatsAppNo:"WhatsApp No",
    aadharNo:"Aadhaar No",
    monthlySubsAmount:"Monthly Donation",
    collectionDate:"Collection Date",
    subsDepositMethod:"Donation Deposit Method",
    modeOfPayment:"Mode of Payment",
    otherpanchayath :"Panchayath Name",
    recommendedPerson:"Recommended Person",
    place:"Place",
    age:"Age",
    gender:"Gender",
  }

  return (
    <React.Fragment>
      <Row>
        <Col lg="12">
          <Card className="mb-0">
            <CardBody className="pt-0">
              <div className="table-responsive">
                <Table className="table table-nowrap align-middle mb-0">
                  <thead className="table-light">
                    <tr>
                      <th scope="col">Field</th>
                      <th scope="col">Old Value</th>
                      <th scope="col">New Value</th>
                    </tr>
                  </thead>
                  <tbody>
                    {props.changedFields.map((field, key) => (
                      <tr key={key}>
                        <th scope="row">
                          <div className="d-flex align-items-center">
                            <span>{filedMap[field]}</span>
                          </div>
                        </th>
                        <td>
                          <div className="font-size-14">
                            {props.oldValues[field]}
                          </div>
                        </td>
                        <td>
                          <div className="font-size-14">
                            {props.newValues[field]}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

EditEmployeeChanges.propTypes = {
}

export default EditEmployeeChanges
