import React, { useState, useEffect, useRef, useMemo } from "react"
import {
  Card,
  Col,
  Container,
  Row,
  Alert,
  UncontrolledAlert,
  UncontrolledTooltip,
  Button,
  CardBody
} from "reactstrap"
import { Link } from 'react-router-dom';
import { useNavigate } from "react-router-dom"
import withRouter from "components/Common/withRouter";
import { isEmpty } from "lodash";
import TableContainer from 'components/Common/TableContainer';
import Loader from "components/Common/Loader"
import {BoldValue, NormalText, NormalDate, ReferenceID } from "./ListCol";
import { saveDetails, retrieveDetails } from "helpers/backend_helper"
import { getFormattedDate, showSuccessPopUp } from "helpers/function_helper"
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
const MySwal = withReactContent(Swal)

const PaymentsList = props => {

  const navigate = useNavigate();
  const [paymentsList, setPaymentsList] = useState([])
  const [showLoading, setShowLoading] = useState(false)
  const [error, setError] = useState('')
  const [dFileName, setDFileName] = useState('export-data-payments')
  const [excelData, setExcelData] = useState([])

  useEffect(()=>{
    fetchPaymentsList()
  },[])

  const fetchPaymentsList=async()=>{
    setShowLoading(true)
    setPaymentsList([])
    const inputToSend = {
      "data" : {
        "type" : "PAYMENT_LIST"
      }
    }
    const response = await retrieveDetails(inputToSend);
    setShowLoading(false)
    if(response && response.status === 'failure'){
      setError(response.error.errorMsg)
    }
    else if(response && response.status === 'success' && response.data){
      setPaymentsList(response.data)
      populateExportData(response.data)
    }
  }

  function populateExportData(searchResult){
    const updatedDetails = []
    searchResult.forEach(each=>{
      updatedDetails.push({
        'Receipt ID': ''+each.id,
        'Receipt Date': getFormattedDate(each.receipt_date,'DD-MMM-YYYY'),
        'Name': each.fullname,
        'House Name': each.house_name,
        'Reg. Ref. No': each.ref_fk,
        'Mobile No':each.mobile_no,
        'Monthly Amount': (each.amount == '' ? '': Number(each.amount)).toLocaleString('en-IN',{minimumFractionDigits: 2}),
        'Mode Of Payment':each.mode_of_payment,
        'Contribution for':each.cont_months_desc,
        'Payment Ref No.':each.payment_ref_no,
        'Comments':each.comments,
        //'Salary Account No.': {t:'s',v: ""+each.salAccountNo+"" || '', z: "@"},
      })
    })
    setExcelData(updatedDetails)
  }

  const handleEdit=(url)=>{     
    //console.log('url',url)
    navigate(url);
  }

  const handleDelete=async(data)=>{
    const result  = await MySwal.fire({
      title: `Are you sure to delete Payment from - ${data.fullname} ?`,
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
      showLoaderOnConfirm:true,
      allowOutsideClick: () => !MySwal.isLoading(),
      preConfirm: (sme) => {
        const inputToSend = {
          "data" : {
            "type" : "DEL_PAY",
            "payment" : {
              'id': ''+data.id,
              'refNo': ''+data.ref_fk
            }
          }
        }
        return saveDetails(inputToSend)
        .then(response => {
          //console.log('response:',response)
          if (response.status != 'success') {
            throw new Error(response.statusText)
          }
          return response
        })
        .catch(error => {
          MySwal.showValidationMessage(
            `Request failed: ${error}`
          )
        })
      },
    })

    if(result.isConfirmed){
      fetchPaymentsList()
      showSuccessPopUp('Deleted Successfully !')
    }
    //console.log('result:',result)
  }

  const columns = useMemo(
    () => [
        {
            Header: 'Receipt ID',
            accessor: 'id',
            filterable: true,
            Cell: (cellProps) => {
                return <BoldValue {...cellProps} />;
            }
        },
        {
          Header: 'Receipt Date',
          accessor: 'receipt_date',
          Cell: (cellProps) => {
              return <NormalDate {...cellProps} />;
          }
        },
        {
            Header: 'Name',
            accessor: 'fullname',
            filterable: true,
            Cell: (cellProps) => {
                return <NormalText {...cellProps} />;
            }
        },
        {
            Header: 'House Name',
            accessor: 'house_name',
            filterable: true,
            Cell: (cellProps) => {
                return <NormalText {...cellProps} />;
            }
        },
        {
          Header: 'Reg. Ref. No',
          accessor: 'ref_fk',
          filterable: true,
          Cell: (cellProps) => {
              return <NormalText {...cellProps} />;
          }
        },
        {
          Header: 'Mobile No',
          accessor: 'mobile_no',
          filterable: true,
          Cell: (cellProps) => {
              return <NormalText {...cellProps} />;
          }
        },
        {
          Header: 'Amount',
          accessor: 'amount',
          Cell: (cellProps) => {
              return <NormalText {...cellProps} />;
          }
        },
        {
            Header: 'Mode',
            accessor: 'mode_of_payment',
            Cell: (cellProps) => {
                return <NormalText {...cellProps} />;
            }
        },
        {
          Header: 'Contribution for',
          accessor: 'cont_months_desc',
          Cell: (cellProps) => {
              return <NormalText {...cellProps} />;
          }
        },
        {
          Header: 'Payment Ref No.',
          accessor: 'payment_ref_no',
          Cell: (cellProps) => {
              return <NormalText {...cellProps} />;
          }
        },
        {
          Header: 'Comments',
          accessor: 'comments',
          Cell: (cellProps) => {
              return <NormalText {...cellProps} />;
          }
        },
        {
            Header: 'Action',
            accessor: 'action',
            disableFilters: true,
            Cell: (cellProps) => {
                return (
                    <ul className="list-unstyled hstack gap-1 mb-0">
                        {/**
                        <li data-bs-toggle="tooltip" data-bs-placement="top" title="View">
                            <Link to="/job-details" className="btn btn-sm btn-soft-primary">
                                <i className="mdi mdi-eye-outline" id="viewtooltip"></i></Link>
                        </li>
                        <UncontrolledTooltip placement="top" target="viewtooltip">
                            View
                        </UncontrolledTooltip>
                        */}

                        {<li>
                            <Button
                              color="info"
                              className="btn btn-sm btn-soft-info"
                              onClick={() => {
                                const regData = cellProps.row.original;
                                    handleEdit('/edit-payment/'+regData.id)
                              }}
                              >
                                <i className="mdi mdi-pencil-outline" id="edittooltip" />
                                <UncontrolledTooltip placement="top" target="edittooltip">
                                    Edit.
                                </UncontrolledTooltip>
                            </Button>
                            </li>
                          }

                        <li>
                            <Link
                                to="#"
                                className="btn btn-sm btn-soft-danger"
                                onClick={() => {
                                    const regData = cellProps.row.original;
                                    handleDelete(regData);
                                }}
                            >
                                <i className="mdi mdi-delete-outline" id="deletetooltip" />
                                <UncontrolledTooltip placement="top" target="deletetooltip">
                                    Delete
                                </UncontrolledTooltip>
                            </Link>
                        </li>
                    </ul>
                );
            }
        },
    ],
    [paymentsList]
);

const handleJobClicks=(e)=>{
  console.log('click:',e)
}

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
              <Col lg="12">
                  <Card>
                      <CardBody>
                        {error ? <Alert color="danger">{error}</Alert> : null}
                          <TableContainer
                              columns={columns}
                              data={paymentsList}
                              isGlobalFilter={true}
                              isAddOptions={false}
                              handleJobClicks={handleJobClicks}
                              isJobListGlobalFilter={false}
                              customPageSize={30}
                              isShowDownLoad={true}
                              downloadData={excelData}
                              downloadFileName={dFileName}
                              isLoading={showLoading}
                          />
                      </CardBody>
                  </Card>
              </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

PaymentsList.propTypes = {
}

export default withRouter(PaymentsList);