import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody
} from "reactstrap";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import withRouter from "components/Common/withRouter";
import { retrieveDetails } from "helpers/backend_helper"
import ApexChartComponent from "./ApexChartComponent"

const Dashboard = props => {

  //meta title
  document.title = "Dashboard | Drops";

  const [showLoading, setShowLoading] = useState(false)
  const [regStats, setRegStats] = useState({})

  useEffect(()=>{
    getRegistrationStats()
  },[])
  
    
    async function getRegistrationStats(){
      setShowLoading(true)
      const inputToSend = {
        "data" : {
          "type" : "REG_STATS"
        }
      }
      const result = await retrieveDetails(inputToSend)
      if(result && result.status && result.status=='failure'){
        //setErrorMessage('Sorry, we cannot any matching details.')
        //setShowErrorPopUp(true)
      }
      else if(result){
        setRegStats(result)
        //setAttendanceDetails(result.data)
        //setEmpDetails(result.data[0])
      }
      setShowLoading(false)
    }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={"Dashboards"}
            breadcrumbItem={"Dashboard"}
          />

          <Row>
            <Col xs="12" md="8">
              <ApexChartComponent title="Registration Count by Panchayath" loading={showLoading} 
                series={regStats.panchayath && regStats.panchayath.values ? [{name:"count", data : regStats.panchayath.values}] : []} 
                xaxisCategories={regStats.panchayath && regStats.panchayath.keys ? regStats.panchayath.keys : []}
                type={'bar'}
                id="panchayath"
                />
            </Col>
            <Col xs="12" md="4">
              <Card>
                  <CardBody>
                      <div className="table-responsive">
                          {!showLoading && 
                            <table className="table">
                              <tbody>
                                  <tr>
                                    <td scope="col">Total Registrations</td>
                                    <th scope="col">{regStats.totalRegistrations ? regStats.totalRegistrations : ""}</th>
                                  </tr>
                                  <tr>
                                    <td>Total Monthly Amount</td>
                                    <th scope="col">{regStats.totalAmount ? (Number(regStats.totalAmount)).toLocaleString('en-IN',{minimumFractionDigits: 2}) : ""}</th>
                                  </tr>
                              </tbody>
                          </table>
                          }
                      </div>
                  </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col xs="12" md="4">
              <ApexChartComponent title="By Amount" loading={showLoading} 
                series={regStats.amount && regStats.amount.values ? [{name:"count", data : regStats.amount.values}] : []} 
                xaxisCategories={regStats.amount && regStats.amount.keys ? regStats.amount.keys : []}
                type={'bar'}
                id="amount"
                />
            </Col>
            <Col xs="12" md="4">
              <ApexChartComponent title="By Collection Date" loading={showLoading} 
                series={regStats.collectionDate && regStats.collectionDate.values ? [{name:"count", data : regStats.collectionDate.values}] : []} 
                xaxisCategories={regStats.collectionDate && regStats.collectionDate.keys ? regStats.collectionDate.keys : []}
                type={'bar'}
                id="collectionDate"
                />
            </Col>
            <Col xs="12" md="4">
              <ApexChartComponent title="By Deposit Method" loading={showLoading} 
                series={regStats.depositMethod && regStats.depositMethod.values ? [{name:"count", data : regStats.depositMethod.values}] : []} 
                xaxisCategories={regStats.depositMethod && regStats.depositMethod.keys ? regStats.depositMethod.keys : []}
                type={'bar'}
                id="depositMethod"
                />
            </Col>
          </Row>

          <Row>
            <Col xs="12" md="12">
              <ApexChartComponent title="By Age" loading={showLoading} 
                series={regStats.age && regStats.age.values ? [{name:"count", data : regStats.age.values}] : []} 
                xaxisCategories={regStats.age && regStats.age.keys ? regStats.age.keys : []}
                type={'bar'}
                id="age"
                />
            </Col>
          </Row>
          <Row>
            <Col xs="12" md="6">
              <ApexChartComponent title="By Mode Of Payment" loading={showLoading} 
                series={regStats.modeOfPayment && regStats.modeOfPayment.values ? [{name:"count", data : regStats.modeOfPayment.values}] : []} 
                xaxisCategories={regStats.modeOfPayment && regStats.modeOfPayment.keys ? regStats.modeOfPayment.keys : []}
                type={'bar'}
                id="modeOfPayment"
                />
            </Col>
          </Row>
          </Container>
          </div>
    </React.Fragment>
  );
};


export default withRouter(Dashboard);
