import axios from "axios";
import accessToken from "./jwt-token-access/accessToken";

//pass new generated access token here
const token = accessToken;

//apply base url for axios
const API_URL = "";

const dev = {
  //apiUrl : 'http://Bineshs-MacBook-Pro.local:3006'
  apiUrl : 'https://55ndz1awki.execute-api.us-east-1.amazonaws.com/prod'
  //apiUrl : 'https://aues09lede.execute-api.us-east-1.amazonaws.com/prod' //preprod
};

const prod = {
  //apiUrl : 'http://Bineshs-MacBook-Pro.local:3005'
  apiUrl : 'https://55ndz1awki.execute-api.us-east-1.amazonaws.com/prod'
};

const envConfigs = process.env.REACT_APP_STAGE === 'DEV'
  ? dev
  : prod;

const axiosApi = axios.create({
  //baseURL: API_URL,
  baseURL: envConfigs.apiUrl,
  //baseURL: 'https://flamingo.tekymart.com/',
  //withCredentials: true
})

const axiosApiWP = axios.create({
  //baseURL: API_URL,
  //baseURL: 'https://281zwgqpu3.execute-api.us-east-1.amazonaws.com/prod',
  baseURL: 'https://questbox.in/',
  //baseURL: 'http://Bineshs-MacBook-Pro.local:3005',
  //withCredentials: true
})

/*
const axiosApi = axios.create({
  baseURL: API_URL,
});
*/

//axiosApi.defaults.headers.common["Authorization"] = token;
axiosApiWP.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject(error)
);

export async function postWP(url, data, config = {}) {
  return axiosApiWP
    .post(url, { ...data }, { ...config })
    .then((response) => response.data);
}


axiosApi.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject(error)
);

export async function get(url, config = {}) {
  return await axiosApi
    .get(url, { ...config })
    .then((response) => response.data);
}

export async function post(url, data, config = {}) {
  return axiosApi
    .post(url, { ...data }, { ...config })
    .then((response) => response.data);
}

export async function put(url, data, config = {}) {
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then((response) => response.data);
}

export async function del(url, config = {}) {
  return await axiosApi
    .delete(url, { ...config })
    .then((response) => response.data);
}
