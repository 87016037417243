import React, { useState, useEffect, useRef } from "react"
import Select, { components } from "react-select";
import { useNavigate } from "react-router-dom"
import {
  Card,
  Col,
  Container,
  Row,
  Alert,
  Button,
  CardBody,
  Form,
  Label,
  Input
} from "reactstrap"
import { Link } from "react-router-dom"
import Loader from "components/Common/Loader"
import { saveDetails, retrieveDetails, processWP } from "helpers/backend_helper"
import { getTodaysDate, downloadPDF, showCloseableToast,getCurrentUUID  } from "helpers/function_helper"
import { isEmpty } from "lodash";
import TxnNavbar from "./TxnNavbar"

const PaymentRedirectHandler = props => {

  document.title = "Drops | Donation Confirmation";
  const navigate = useNavigate();
  const [error, setError] = useState('')
  const [showLoading, setShowLoading] = useState(true)
  const [showPaymentSuccess, setShowPaymentSuccess] = useState(false)
  const [downLoadInProgress, setDownLoadInProgress] = useState(false)
  const [showPaymentFailure, setShowPaymentFailure] = useState(false)
  
  
  const [paymentAndUserData, setPaymentAndUserData] = useState({});
  const [paymentResp, setPaymentResp] = useState({});

  useEffect(() => {
    handleRedirectBack()
  }, []);

  async function handleRedirectBack(){
    const ppData = localStorage.getItem('pppay_data')
    if(ppData){
      const ppObj = JSON.parse(ppData)
      setPaymentAndUserData(ppObj)
      await checkPaymentStatus(ppObj)
      localStorage.removeItem('pppay_data')
    }
  }

  async function checkPaymentStatus(ppObj){
    const inputToSend = {
      "data" : {
        "type" : "PP_STATUS",
        "xverify": ppObj.xverifyStatus,
        "request": {
          "merchantId": ppObj.merchantId,
          "txnId": ppObj.txnId
        }
      }
    }
    const responseWP = await processWP(inputToSend);
    setShowLoading(false)
    if(responseWP && responseWP.code && responseWP.code == 'PAYMENT_SUCCESS'){
      setPaymentResp(responseWP)
      setShowPaymentSuccess(true)
      sendReceipt(ppObj, responseWP?.data?.paymentInstrument?.type || '')
    }
    else if(responseWP && responseWP.code && responseWP.code == 'PAYMENT_PENDING'){
      setTimeout(() => {
        checkPaymentStatus(ppObj)
      }, 5000);
    }
    else{
      setShowPaymentFailure(true)
    }
  }

  const onCreateNewPayment=()=>{
    navigate('/donate')
  }

  const downloadReceipt=async()=>{
    setDownLoadInProgress(true)
    const inputToSend = {
      "data" : {
        "type" : "DOWNLOAD_RECEIPT",
        "payment" : {
          id: paymentAndUserData.txnId,
          receiptDate: getTodaysDate('DD-MMM-YY'),
          uName: paymentAndUserData.name,
          address: paymentAndUserData.address,
          regNo : paymentAndUserData.regNo,
          mobNo : paymentAndUserData.mobileNo,
          subsAmount : Number(paymentAndUserData.subsAmount).toLocaleString('en-IN',{minimumFractionDigits: 2}),
          subsAmountInWords : paymentAndUserData.amountInWords,
          modeOfPayment : paymentResp?.data?.paymentInstrument?.type || '',
          contMonthsDesc : paymentAndUserData.contMonthsDesc,
          userType: paymentAndUserData.type
        }
      }
    }
    const response = await processWP(inputToSend);
    
    if(response && response.status === 'failure'){
      showCloseableToast('Sorry, unable to process.','top-center','error')
    }
    else if(response && response.status === 'success' && response.data){
      downloadPDF(response.data, 'drops_payment_receipt_'+paymentAndUserData.txnId+'.pdf')
    }
    setDownLoadInProgress(false)

  }

  const sendReceipt=(ppObj, modeOfPayment)=>{

    const inputToSend = {
      "data" : {
        "type" : "SEND_RECEIPT",
        "key": getCurrentUUID(),
        "message" : {'caption':'DROPS Payment Receipt'},
        "payment" : {
          id: ppObj.txnId,
          receiptDate: getTodaysDate('DD-MMM-YY'),
          uName: ppObj.name,
          address: ppObj.address,
          regNo : ppObj.regNo,
          mobNo : ppObj.mobileNo,
          subsAmount : Number(ppObj.subsAmount).toLocaleString('en-IN',{minimumFractionDigits: 2}),
          subsAmountInWords : ppObj.amountInWords,
          modeOfPayment : modeOfPayment || '',
          contMonthsDesc : ppObj.contMonthsDesc,
          whatsAppNo: ppObj.whatsAppNo,
          userType: ppObj.type
        }
      }
    }
    processWP(inputToSend)
  }

  return (
    <React.Fragment>
      <Loader showLoader={showLoading} />
      <TxnNavbar/>
      {showPaymentFailure && 
      <Alert color="danger" className="mb-0">
        <i className="mdi mdi-close me-2"></i>
        Payment failed, Please try again later!
      </Alert>
      }
      {showPaymentSuccess &&
      <div id="paymentReceipt">
        <Row className="bg-light m-0 py-2 mb-1 mt-5 text-center">
          <Alert color="success" className="mb-0">
            <i className="mdi mdi-check-all me-2"></i>
            Payment successful!
            <div className="mt-3">
            Dear <b>{paymentAndUserData.name}</b>{", "}<br/>
            <b>Thank You</b> for the contribution and showing your care for your <span className="drops-title fw-bold">DROPS</span> family.
            </div>
          </Alert>
          {/*<Alert color="success" className="mb-0">
            <i className="mdi mdi-check-all me-2"></i>
            Dear <b>{empDetails.name}</b>{", "}<br/>
            <b>Thank You</b> for the contribution and showing your care for your <span className="drops-title fw-bold">DROPS</span> family.
          </Alert>
          */}
        </Row>
        <Row className="justify-content-center">
          <Col xl={6} xs={12} md={9}>
              <Card>
                  <CardBody>
                      <h5 className="fw-semibold">Payment Receipt</h5>

                      <div className="table-responsive">
                          <table className="table">
                              <tbody>
                                  <tr>
                                    <td scope="col" style={{width:"40%"}}>Receipt No.</td>
                                    <th scope="col" style={{width:"60%"}}>{paymentAndUserData.txnId}</th>
                                  </tr>
                                  <tr>
                                    <td>Date</td>
                                    <th scope="row">{getTodaysDate('DD-MMM-YY')}</th>
                                  </tr>
                                  <tr>
                                    <td>Name</td>
                                    <th scope="row">{paymentAndUserData.name}</th>
                                  </tr>
                                  <tr>
                                    <td>Address</td>
                                    <th scope="row">{paymentAndUserData.address}</th>
                                  </tr>
                                  {paymentAndUserData && paymentAndUserData.type == 'registered'&&
                                  <tr>
                                    <td>Reference No.</td>
                                    <th scope="row">{paymentAndUserData.regNo}</th>
                                  </tr>
                                  }
                                  <tr>
                                    <td>Mobile No.</td>
                                    <th scope="row">{paymentAndUserData.mobileNo}</th>
                                  </tr>
                                  <tr>
                                    <td>Amount (Rs)</td>
                                    <th scope="row">{Number(paymentAndUserData.subsAmount).toLocaleString('en-IN',{minimumFractionDigits: 2})}</th>
                                  </tr>
                                  <tr>
                                    <td>Amount in Words</td>
                                    <th scope="row">{paymentAndUserData.amountInWords}</th>
                                  </tr>
                                  <tr>
                                    <td>Mode of Payment</td>
                                    <th scope="row">{paymentResp?.data?.paymentInstrument?.type || ''}</th>
                                  </tr>
                                  {paymentAndUserData && paymentAndUserData.type == 'registered'&&
                                  <tr>
                                    <td>Contribution Period</td>
                                    <th scope="row">{paymentAndUserData.contMonthsDesc}</th>
                                  </tr>
                                  }
                              </tbody>
                          </table>
                      </div>
                      <div className="hstack gap-2 d-print-none">
                          <button onClick={()=>onCreateNewPayment()} className="btn btn-soft-info w-100">
                          <i className="mdi mdi-cash-plus font-size-18 align-middle" /> Make New Payment
                          </button>
                          <button disabled={downLoadInProgress} onClick={()=>downloadReceipt()} className="btn btn-soft-primary w-100">
                            <i className="mdi mdi-download font-size-18 align-middle" /> Download
                            {downLoadInProgress && <i className="bx bx-loader bx-spin font-size-18 align-middle ms-2" />}
                          </button>
                      </div>
                  </CardBody>
              </Card>
          </Col>
        </Row>
      </div>
      }

    </React.Fragment>
  )
}

PaymentRedirectHandler.propTypes = {
}

export default PaymentRedirectHandler
