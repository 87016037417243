const titles = [{
  value:'Mr',
  label: 'Mr'
},
{
  value:'Mrs',
  label: 'Mrs'
},
{
  value:'Dr',
  label: 'Dr'
},
{
  value:'Ms',
  label: 'Ms'
},
{
  value:'Miss',
  label: 'Miss'
}]

const panchayats = [{
  value:'Chungathara',
  label: 'Chungathara (ചുങ്കത്തറ)'
},
{
  value:'Edakkara',
  label: 'Edakkara (എടക്കര)'
},
{
  value:'Moothedam',
  label: 'Moothedam (മൂത്തേടം)'
},
{
  value:'Pothukallu',
  label: 'Pothukallu (പോത്തുകല്ല്)'
},
{
  value:'Vazhikadavu',
  label: 'Vazhikadavu (വഴിക്കടവ്)'
},
{
  value:'Others',
  label: 'Other Pachayaths (മറ്റു പഞ്ചായത്തുകൾ)'
}]

const wards = {
  Moothedam : [
    { value:'1',label:'1 - Marathinkadav (മരത്തിൻകടവ്)'}
    ,{ value:'2',label:'2 - Nellikkuth (നെല്ലിക്കുത്ത്)'}
    ,{ value:'3',label:'3 - Kalkulam (കൽക്കുളം)'}
    ,{ value:'4',label:'4 - Balamkulam (ബാലംകുളം)'}
    ,{ value:'5',label:'5 - Karappuram (കാരപ്പുറം)'}
    ,{ value:'6',label:'6 - Nellipoil (നെല്ലിപ്പൊയിൽ)'}
    ,{ value:'7',label:'7 - Cholamunda (ചോലമുണ്ട)'}
    ,{ value:'8',label:'8 - Vellaramunda (വെള്ളാരമുണ്ട)'}
    ,{ value:'9',label:'9 - Palankara (പാലാങ്കര)'}
    ,{ value:'10',label:'10 - Vattappadam (വട്ടപ്പാടം)'}
    ,{ value:'11',label:'11 - Kuttikkad (കുറ്റിക്കാട്)'}
    ,{ value:'12',label:'12 - Maruthangad (മരുതങ്ങാട്)'}
    ,{ value:'13',label:'13 - Maramvettichal (മരംവെട്ടിച്ചാൽ)'}
    ,{ value:'14',label:'14 - Kattadi (കാറ്റാടി)'}
    ,{ value:'15',label:'15 - Chemmanthitta (ചെമ്മന്തിട്ട)'}
  ],
  Vazhikadavu : [{ value:'1',label:'1 - Thannikkadavu (താന്നിക്കടവ്)'}
  ,{ value:'2',label:'2 - Madhalappara (മാതളപ്പാറ)'}
  ,{ value:'3',label:'3 - Marutha vengapadam (മറുത വേങ്ങപ്പാടം)'}
  ,{ value:'4',label:'4 - Vendekkumpotty (വെണ്ടേക്കുംപൊട്ടി)'}
  ,{ value:'5',label:'5 - Mamankara (മാമാങ്കര)'}
  ,{ value:'6',label:'6 - Kamblakallu (കംബ്ലക്കല്ല്)'}
  ,{ value:'7',label:'7 - Manalpadam (മണൽപാടം)'}
  ,{ value:'8',label:'8 - Karakode (കാരക്കോട്)'}
  ,{ value:'9',label:'9 - Vellakkatta (വെള്ളക്കട്ട)'}
  ,{ value:'10',label:'10 - Vazhikkadavu (വഴിക്കടവ്)'}
  ,{ value:'11',label:'11 - Poovathipoyil (പൂവത്തിപ്പൊയിൽ)'}
  ,{ value:'12',label:'12 - Panchayatanghadi (പഞ്ചായത്തങ്ങാടി)'}
  ,{ value:'13',label:'13 - Vallikkadu (വള്ളിക്കാട്)'}
  ,{ value:'14',label:'14 - Alapoyil (ആലപ്പൊയിൽ)'}
  ,{ value:'15',label:'15 - Manimooly (മണിമൂളി)'}
  ,{ value:'16',label:'16 - Varakulam (വരകുളം)'}
  ,{ value:'17',label:'17 - Palad (പാലാട്)'}
  ,{ value:'18',label:'18 - Munda (മുണ്ട)'}
  ,{ value:'19',label:'19 - Modapoyika (മൊടപൊയ്ക)'}
  ,{ value:'20',label:'20 - Narivalamunda (നരിവാളമുണ്ട)'}
  ,{ value:'21',label:'21 - Narokkavu (നാരോക്കാവ്)'}
  ,{ value:'22',label:'22 - Mekkorava (മേക്കൊരവ)'}
  ,{ value:'23',label:'23 - Kunnummalpotti (കുന്നുമ്മൽപൊട്ടി)'}],
  Edakkara : [{ value:'1',label:'1 - Malachi (മലാച്ചി)'}
  ,{ value:'2',label:'2 - Karunechi (കരുനെച്ചി)'}
  ,{ value:'3',label:'3 - Pallippadi (പള്ളിപ്പടി)'}
  ,{ value:'4',label:'4 - Palemad (പാലേമാട്)'}
  ,{ value:'5',label:'5 - Payimbadam (പയിമ്പാടം)'}
  ,{ value:'6',label:'6 - Sankaramkulam (ശങ്കരംകുളം)'}
  ,{ value:'7',label:'7 - Parli (പറളി)'}
  ,{ value:'8',label:'8 - Vellaramkunnu (വെള്ളാരംകുന്ന്)'}
  ,{ value:'9',label:'9 - Menonpotty (മേനോൻപൊട്ടി)'}
  ,{ value:'10',label:'10 - Edakkara (എടക്കര)'}
  ,{ value:'11',label:'11 - Perumkulam (പെരുംകുളം)'}
  ,{ value:'12',label:'12 - Kakkaparatha (കാക്കപരത)'}
  ,{ value:'13',label:'13 - Theyyathumpadam (തെയ്യത്തുംപാടം)'}
  ,{ value:'14',label:'14 - Muppini (മുപ്പിനി)'}
  ,{ value:'15',label:'15 - Pathirippadam (പാതിരിപ്പാടം)'}
  ,{ value:'16',label:'16 - Chathamunda (ചാത്തമുണ്ട)'}],
  Pothukallu : [{ value:'1',label:'1 - Santhigram (ശാന്തിഗ്രാം)'}
  ,{ value:'2',label:'2 - Thamburattikkallu (തമ്പുരാട്ടിക്കല്ല്)'}
  ,{ value:'3',label:'3 - Munderi (മുണ്ടേരി)'}
  ,{ value:'4',label:'4 - Muramthookki (മുറംതൂക്കി)'}
  ,{ value:'5',label:'5 - Velumbiyampadam (വെളുമ്പിയംപാടം)'}
  ,{ value:'6',label:'6 - Ambittampotty (അമ്പിട്ടാംപൊട്ടി)'}
  ,{ value:'7',label:'7 - Pothukallu (പോത്തുകല്ല്)'}
  ,{ value:'8',label:'8 - Muthukulam (മുതുകുളം)'}
  ,{ value:'9',label:'9 - Kodalipoyil (കോടാലിപ്പൊയിൽ)'}
  ,{ value:'10',label:'10 - Anakkallu (ആനക്കല്ല്)'}
  ,{ value:'11',label:'11 - Nettikulam (നെട്ടിക്കുളം)'}
  ,{ value:'12',label:'12 - Uppada (ഉപ്പട)'}
  ,{ value:'13',label:'13 - Vellimuttam (വെള്ളിമുട്ടം)'}
  ,{ value:'14',label:'14 - Pathar (പാതാർ)'}
  ,{ value:'15',label:'15 - Poolappadam (പൂളപ്പാടം)'}
  ,{ value:'16',label:'16 - Panamkayam (പനംകയം)'}
  ,{ value:'17',label:'17 - Bhoodanam (ഭൂദാനം)'}],
  Chungathara : [{ value:'1',label:'1 - Erumamunda (എരുമമുണ്ട)'}
  ,{ value:'2',label:'2 - Kurumbalangode (കുറുമ്പലങ്ങോട്)'}
  ,{ value:'3',label:'3 - Pookkottumanna (പൂക്കോട്ടുമണ്ണ)'}
  ,{ value:'4',label:'4 - Nallamthanni (നല്ലംതണ്ണി)'}
  ,{ value:'5',label:'5 - Pulimunda (പുളിമുണ്ട)'}
  ,{ value:'6',label:'6 - Kattichira (കാട്ടിച്ചിറ)'}
  ,{ value:'7',label:'7 - Kotteppadam (കോട്ടേപ്പാടം)'}
  ,{ value:'8',label:'8 - Konnamanna (കൊന്നമണ്ണ)'}
  ,{ value:'9',label:'9 - Kattilappadam (കട്ടിലപ്പാടം)'}
  ,{ value:'10',label:'10 - Pallikuth (പള്ളിക്കുത്ത്)'}
  ,{ value:'11',label:'11 - Panamanna (പനമണ്ണ)'}
  ,{ value:'12',label:'12 - Muttikkadavu (മുട്ടിക്കടവ്)'}
  ,{ value:'13',label:'13 - Manali (മണാലി)'}
  ,{ value:'14',label:'14 - Kalakkunnu (കലക്കുന്ന്)'}
  ,{ value:'15',label:'15 - Vellaramkunnu (വെള്ളാരംകുന്ന്)'}
  ,{ value:'16',label:'16 - Chalikulam (ചാലിക്കുളം)'}
  ,{ value:'17',label:'17 - Kunnath (കുന്നത്ത്)'}
  ,{ value:'18',label:'18 - Kaippini (കൈപ്പിനി)'}
  ,{ value:'19',label:'19 - Mundappadam (മുണ്ടപ്പാടം)'}
  ,{ value:'20',label:'20 - Chembankolly (ചെമ്പൻകൊല്ലി)'}]
}

const collDates = [{
  value:'1',
  label: '1'
},
{
  value:'5',
  label: '5'
},
{
  value:'10',
  label: '10'
},
{
  value:'15',
  label: '15'
},
{
  value:'20',
  label: '20'
},
{
  value:'25',
  label: '25'
}]

const genders = [{
  value:'M',
  label: 'Male'
},
{
  value:'F',
  label: 'Female'
}]

const subsDepMethod = [{
  value:'Monthly',
  label: 'Monthly (മാസത്തിൽ)'
},
{
  value:'Quarterly',
  label: 'Quarterly (3 മാസത്തിൽ ഒന്നിച്ച്)'
},
{
  value:'HalfYearly',
  label: 'Half Yearly (6 മാസത്തിൽ ഒന്നിച്ച്)'
},
{
  value:'Yearly',
  label: 'Yearly (വർഷത്തിൽ)'
}]

const postOffices = [{
  value:'Chungathara',
  label: 'Chungathara (ചുങ്കത്തറ)',
  po:'679334',
  panchayat: 'Chungathara'
},
{
  value:'Kurumbalangode',
  label: 'Kurumbalangode (കുറുമ്പലങ്ങോട്)',
  po:'679334',
  panchayat: 'Chungathara'
},
{
  value:'Padiripadam',
  label: 'Padiripadam (പാതിരിപ്പാടം)',
  po:'679334',
  panchayat: 'Chungathara'//Pothukallu
},
{
  value:'Munderi',
  label: 'Munderi (മുണ്ടേരി)',
  po:'679334',
  panchayat: 'Pothukallu'
},
{
  value:'Pallikuth',
  label: 'Pallikuth (പള്ളിക്കുത്ത്)',
  po:'679334',
  panchayat: 'Chungathara'
},
{
  value:'Pathar',
  label: 'Pathar (പാതാർ)',
  po:'679334',
  panchayat: 'Pothukallu'
},
{
  value:'Pookottumanna',
  label: 'Pookottumanna (പൂക്കോട്ടുമണ്ണ)',
  po:'679334',
  panchayat: 'Chungathara'
},
{
  value:'Bhoodan Colony',
  label: 'Bhoodan Colony (ഭൂദാനം കോളനി)',
  po:'679334',
  panchayat: 'Pothukallu'
},
{
  value:'Erumamunda',
  label: 'Erumamunda (എരുമമുണ്ട)',
  po:'679334',
  panchayat: 'Chungathara'
},
{
  value:'Uppada',
  label: 'Uppada (ഉപ്പട)',
  po:'679334',
  panchayat: 'Pothukallu'
},
{
  value:'Edakkara',
  label: 'Edakkara (എടക്കര)',
  po:'679331',
  panchayat: 'Edakkara'
},
{
  value:'Kunnummalpotti',
  label: 'Kunnummalpotti (കുന്നുമ്മൽപൊട്ടി)',
  po:'679331',
  panchayat: 'Edakkara'
},
{
  value:'Munda',
  label: 'Munda (മുണ്ട)',
  po:'679331',
  panchayat: 'Edakkara'
},
{
  value:'Modapoika',
  label: 'Modapoika (മൊടപൊയ്ക)',
  po:'679331',
  panchayat: 'Edakkara'
},
{
  value:'Palemad',
  label: 'Palemad (പാലേമാട്)',
  po:'679331',
  panchayat: 'Edakkara'
},
{
  value:'Moothedam',
  label: 'Moothedam (മൂത്തേടം)',
  po:'679331',
  panchayat: 'Moothedam'
},
{
  value:'Karapuram',
  label: 'Karapuram (കാരപ്പുറം)',
  po:'679331',
  panchayat: 'Moothedam'
},
{
  value:'Theyyathumpadam',
  label: 'Theyyathumpadam (തെയ്യത്തുംപാടം)',
  po:'679331',
  panchayat: 'Moothedam'
},
{
  value:'Vazhikadavu',
  label: 'Vazhikadavu (വഴിക്കടവ്)',
  po:'679333',
  panchayat: 'Vazhikadavu'
},
{
  value:'Manimooly',
  label: 'Manimooly (മണിമൂളി)',
  po:'679333',
  panchayat: 'Vazhikadavu'
},
{
  value:'Marutha',
  label: 'Marutha (മരുത)',
  po:'679333',
  panchayat: 'Vazhikadavu'
},
{
  value:'Kambalakallu',
  label: 'Kambalakallu (കമ്പളക്കല്ല്)',
  po:'679333',
  panchayat: 'Vazhikadavu'
},
{
  value:'Nambooripotty',
  label: 'Nambooripotty (നമ്പൂരിപൊട്ടി)',
  po:'679333',
  panchayat: 'Vazhikadavu'
},
{
  value:'Karakkode',
  label: 'Karakkode (കാരക്കോട്)',
  po:'679333',
  panchayat: 'Vazhikadavu'
}]

const modeOfPayments = [{
  value:'UPI',
  label: 'UPI (ഗൂഗിൾ പേ / ഫോൺ പേ)'
},
{
  value:'Cash',
  label: 'Cash (ക്യാഷ്)'
},
{
  value:'BankTransfer',
  label: 'Bank Transfer (ബാങ്ക് ട്രാൻസ്ഫർ)'
},
{
  value:'Cheque',
  label: 'Cheque'
}]

export { titles, panchayats,collDates,subsDepMethod,modeOfPayments,genders,postOffices,wards }
