import PropTypes from 'prop-types';
import React, { useState } from "react";
import {
  Container,
} from "reactstrap";
import { Link } from "react-router-dom";

//Import Images
import logodark from "assets/images/logo.png"
//import logodark from "../../../assets/images/logo-dark.png";
import logolight from "assets/images/logo-inv-1.png";

const TxnNavbar = props => {

  return (
    <React.Fragment>
      <nav
        className={"navbar navbar-expand-lg navigation fixed-top sticky nav-sticky"}
      >
        <Container>
          <Link className="navbar-logo py-1" to="/">
            <img
                src={logodark}
                alt=""
                height="36"
                className="logo logo-dark"
              />
          </Link>
          
        </Container>
      </nav>
    </React.Fragment>
  );
};

TxnNavbar.propTypes = {
  imglight: PropTypes.any,
  navClass: PropTypes.string
};

export default TxnNavbar;
