import React, { useState, useEffect, useRef, useMemo } from "react"
import {
  Card,
  Col,
  Container,
  Row,
  Label,
  Input,
  UncontrolledTooltip,
  Button,
  CardBody
} from "reactstrap"
import { Link } from 'react-router-dom';
import { useNavigate } from "react-router-dom"
import withRouter from "components/Common/withRouter";
import { isEmpty } from "lodash";
import TableContainer from 'components/Common/TableContainer';
import Loader from "components/Common/Loader"
import {BoldValue, NormalText, NormalDate, ReferenceID } from "./ListCol";
import { saveDetails, processWP } from "helpers/backend_helper"
import { getFormattedDate, showSuccessPopUp, showCloseableToast, isNumber, getBase64StringFromDataURL } from "helpers/function_helper"
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { v4 as uuidv4 } from 'uuid';
import QRCode from "react-qr-code";
import PieSocket from 'piesocket-js';
import Select, { components } from "react-select";
import SimpleBar from "simplebar-react";

import html2canvas from 'html2canvas';
import { jsPDF } from "jspdf";

const MySwal = withReactContent(Swal)

var pieSocket = new PieSocket({
  clusterId: "free.nyc1",
  apiKey: "WKfYemehehooplGk7aVA5YG6Csw0q7qefaF5jyzh",
  notifySelf: false
});


const WPTest = props => {

  const navigate = useNavigate();
  const [qrResponse, setQrResponse] = useState('')
  const [currentUUID, setCurrentUUID] = useState('')
  const [showPleaseWait, setShowPleaseWait] = useState(false)
  const [sendingMessage, setSendingMessage] = useState(false)
  const [allContacts, setAllContacts] = useState([])
  const [allChats, setAllChats] = useState([])
  const [selectedContact, setSelectedContact] = useState()
  const [messageToSend, setMessageToSend] = useState({
    contactNo: '',
    message: '',
    type: 'chat'
  })

  useEffect(()=>{
    //initWP()
  },[])
  
  const initWP=async()=>{

    setShowPleaseWait(true)

    const inputToSend = {
      "data" : {
        "type" : "INIT",
        "key": currentUUID
      }
    }
    const response = await processWP(inputToSend);
    if(response && response.status === 'success' && response.data && response.data == 'connected'){
      setShowPleaseWait(false)
      showCloseableToast('Connected Successfully','top-center','info')
    }
  }

  useEffect(()=>{
    let uuid = ""
    if (localStorage.getItem("dps_wp_uuid") != undefined && localStorage.getItem("dps_wp_uuid") != null && localStorage.getItem("dps_wp_uuid") != '') {
      uuid = localStorage.getItem("dps_wp_uuid")
    }
    else{
      uuid = uuidv4()
      localStorage.setItem('dps_wp_uuid',uuid)
    }
    console.log('final:uu:',uuid)
    setCurrentUUID(uuid)
  },[])

useEffect(()=>{
  console.log('currentUUID:',currentUUID)
  if(currentUUID && currentUUID != ''){
    pieSocket.subscribe(currentUUID).then((channel)=>{
      //console.log("Channel is ready");
  
      channel.listen("qr_code", (data, meta)=>{
          //console.log("New message: ", data);
          setQrResponse(data)
      });

      channel.listen("client_ready", (data, meta)=>{
        console.log("Client Ready: ", data);
        setQrResponse('')
        setShowPleaseWait(false)
      });

    });
  }
},[currentUUID])

const getAllContacts=async()=>{
  const inputToSend = {
    "data" : {
      "type" : "ALL_CONTACTS",
      "key": currentUUID
    }
  }
  const response = await processWP(inputToSend);
  if(response && response.status === 'success'){
    processAllContacts(response.data)
  }
  else if(response && response.error && response.error.code && response.error.code == 'E1001'){
    showCloseableToast('No valid session. Please initialise','top-center','error')
  }
}

const getAllChats=async()=>{
  const inputToSend = {
    "data" : {
      "type" : "ALL_CHATS",
      "key": currentUUID
    }
  }
  const response = await processWP(inputToSend);
  if(response && response.status === 'success'){
    setAllChats(response.data)
    //processAllContacts(response.data)
  }
  else if(response && response.error && response.error.code && response.error.code == 'E1001'){
    showCloseableToast('No valid session. Please initialise','top-center','error')
  }
}

const processAllContacts=(data)=>{
  let contactsArray = []

  data.forEach(each=>{
    contactsArray.push({
      label: each.name ? each.name + ' | ' : ''  + each.shortName ? each.shortName + ' | ' : '' + each.id.user,
      value: each.id._serialized
    })
  })

  setAllContacts(contactsArray)
}

const doLogout=async()=>{
  const inputToSend = {
    "data" : {
      "type" : "LOGOUT",
      "key": currentUUID
    }
  }
  const response = await processWP(inputToSend);
  if(response && response.status === 'success'){
    
  }
  else if(response && response.error && response.error.code && response.error.code == 'E1001'){
    showCloseableToast('No valid session. Please initialise','top-center','error')
  }
}

const mandatoryFields = ['contactNo','message']

  const [validation, setValidation] = useState({
    contactNo: null,
    message: null
  })
  const onChangeValidation = (fieldName, value) => {
    const modifiedV = { ...validation }

    if(mandatoryFields.includes(fieldName)){
      if (value !== "") {
        modifiedV[fieldName] = true
      } else {
        modifiedV[fieldName] = false
      }
      setValidation(modifiedV)
    }
    setValidation(modifiedV)
  }

  function validateForm(){

    const modifiedV = { ...validation }
    let formValid = true;
    mandatoryFields.forEach(eachItem =>{
      if(!isEmpty(messageToSend[eachItem])){
        modifiedV[eachItem] = true
      }
      else{
        modifiedV[eachItem] = false
        formValid = false
      }
    })
    setValidation(modifiedV)
    
    return formValid
  }

  const doSendMessage=async()=>{
    if(validateForm()){
      setSendingMessage(true)
      const inputToSend = {
        "data" : {
          "type" : "MSG",
          "key": currentUUID,
          "message" : messageToSend
        }
      }
      const response = await processWP(inputToSend);
      if(response && response.status === 'success'){
        
      }
      else if(response && response.error && response.error.code && response.error.code == 'E1001'){
        showCloseableToast('No valid session. Please initialise','top-center','error')
      }
    }
  }

  const doSendMessages=async()=>{
    if(validateForm()){

      let userId = ''
      if (localStorage.getItem("authUser")) {
        const obj = JSON.parse(localStorage.getItem("authUser"));
        userId = obj.id
      }

      let cNos = messageToSend.contactNo.split(",")
      const cNoArr = []
      for(let cNo of cNos){
        cNoArr.push({
          'number': cNo
        })
      }

      const updatedMsgToSend = {
        contactNos: cNoArr,
        message: messageToSend.message,
        type: messageToSend.type,
        "userId": ''+userId,
      }

      setSendingMessage(true)
      const inputToSend = {
        "data" : {
          "type" : "MSGS",
          "key": currentUUID,
          "message" : updatedMsgToSend
        }
      }
      const response = await processWP(inputToSend);
      if(response && response.status === 'success'){
        
      }
      else if(response && response.error && response.error.code && response.error.code == 'E1001'){
        showCloseableToast('No valid session. Please initialise','top-center','error')
      }
    }
  }


  const printPage=async()=>{
    const input = document.getElementById('detail-tocapture');
    const canvas = await html2canvas(input, {
      scale:2,
      dpi: 144,
      })
    console.log('canvas:',canvas)
    //let dataURL = canvas.toDataURL();
    //console.log('dataURL:',dataURL)

    const imgData = canvas.toDataURL('image/png');
    const pdf = new jsPDF('p', 'pt','a4',true);
    //pdf.addImage(imgData, "JPEG", -5, 0, ((canvas.width/2)*.275), ((canvas.height/2)*.275));
    pdf.addImage(imgData, 'PNG', -5, 0, ((canvas.width/2)*.275), ((canvas.height/2)*.275),'','FAST');
    let dataURL = pdf.output('datauristring')

    console.log('dataURL:',dataURL)
    const base64String = getBase64StringFromDataURL(dataURL)
    console.log('base64String:',base64String)
    
    await doSendAttachment({
      type:'pdf',
      contactNo : messageToSend.contactNo,
      message : base64String,
    })
  }

  const doSendAttachment=async(msg)=>{
    if(validateForm()){
      setSendingMessage(true)
      const inputToSend = {
        "data" : {
          "type" : "MSG",
          "key": currentUUID,
          "message" : msg
        }
      }
      const response = await processWP(inputToSend);
      setSendingMessage(false)
      if(response && response.status === 'success'){
        
      }
      else if(response && response.error && response.error.code && response.error.code == 'E1001'){
        showCloseableToast('No valid session. Please initialise','top-center','error')
      }
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
              <Col lg="12">
                  <Card>
                      <CardBody>
                        
                        <Button
                          color="success"
                          className="btn w-lg drops-bg-shade-9 btn-block"
                          onClick={() => {
                            initWP()
                          }}
                          >
                            Initialise
                        </Button>

                        <Button
                          color="success"
                          className="btn w-lg drops-bg-shade-9 btn-block"
                          onClick={() => {
                            getAllContacts()
                          }}
                          >
                            ALL Contacts
                        </Button>

                        <Button
                          color="success"
                          className="btn w-lg drops-bg-shade-9 btn-block"
                          onClick={() => {
                            getAllChats()
                          }}
                          >
                            ALL Chats
                        </Button>
                        
                        <Button
                          color="danger"
                          className="btn w-lg btn-danger btn-block"
                          onClick={() => {
                            doLogout()
                          }}
                          >
                            LOGOUT
                        </Button>

                        <Button
                          color="danger"
                          className="btn w-lg btn-danger btn-block"
                          onClick={() => {
                            printPage()
                          }}
                          >
                            TEST PRINT
                        </Button>

                      </CardBody>
                  </Card>
              </Col>
          </Row>
          <div id="detail-tocapture">
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    {showPleaseWait && 
                    <div className="text-center my-3">
                      <div className="text-success">
                        <i className="bx bx-loader bx-spin font-size-18 align-middle me-2" />
                        Please wait...
                      </div>
                    </div>
                    }
                    {qrResponse != '' && 
                    <div className="text-center my-3">
                      <QRCode size={300} level={"L"} value={qrResponse} />
                      <div className="mt-4"> Please scan the QR code and wait</div>
                    </div>
                    }
                    <Row>
                      <Col lg="4" sm="12">
                        <Row>
                          <Select
                            onChange={(e) => {
                              setSelectedContact(e)
                              setMessageToSend({
                                ...messageToSend,
                                contactNo : e.value
                              })
                            }}
                            options={allContacts}
                            classNamePrefix="select2-selection"
                            isLoading={false}
                            id={"selectedcontact"}
                            value={selectedContact}
                            menuPortalTarget={document.body}
                            styles={{menuPortal:base => ({...base, zIndex:9999})}}
                          />
                        </Row>
                        <Row>
                          <SimpleBar style={{ maxHeight: 300 }}>
                            {allChats.map((eachChat, i)=>(
                              <div key={i}>
                                <Card className="m-1">
                                  <CardBody>
                                    <div>
                                      {eachChat.name}
                                    </div>
                                  </CardBody>
                                </Card>
                              </div>
                            ))}
                          </SimpleBar>
                        </Row>
                        
                      </Col>
                      <Col lg="8" sm="12">
                        <Row>
                          <Col xl={12} xs={12} md={12}>
                            <div className="mb-3">
                              <Label for="contactNo">
                                Contact No.
                              </Label>
                              <Input
                                type="tel"
                                className="form-control"
                                id="contactNo"
                                maxLength="500"
                                value={messageToSend.contactNo || ''}
                                onChange={e => {
                                  
                                    setMessageToSend({
                                      ...messageToSend,
                                      contactNo : e.target.value
                                    })
                                  
                                }}
                              />
                              {(validation["contactNo"] === false) &&
                                <div className="custom-error">
                                  Please enter a valid Contact Number
                                </div>
                              }
                            </div>
                          </Col>
                          <Col xl={12} xs={12} md={12}>
                            <div className="mb-3">
                              <Label for="message">
                                Message
                              </Label>
                              <textarea
                                id="message"
                                className="form-control"
                                rows="3"
                                maxLength="250"
                                value={messageToSend.message || ''}
                                onChange={e => {
                                  setMessageToSend({
                                    ...messageToSend,
                                    message : e.target.value
                                  })
                                }}
                              />
                              {(validation["message"] === false) &&
                                <div className="custom-error">
                                  Please enter a valid Message
                                </div>
                              }
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Button
                              color="danger"
                              className="btn w-lg btn-danger btn-block"
                              onClick={() => {
                                doSendMessages()
                              }}
                              >
                                SEND
                          </Button>
                        </Row>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

WPTest.propTypes = {
}

export default withRouter(WPTest);