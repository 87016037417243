import React from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  Button,
} from "reactstrap";
import { Link } from "react-router-dom";
import PropTypes from 'prop-types';
import { withTranslation } from "react-i18next";

const Section = (props) => {
  
  return (
    <React.Fragment>
      <section className="section hero-section bg-ico-hero" id="home">
        <div className="bg-overlay"></div>
        <Container>
          <div className="page-header header-filter">
            <div className="drops-rotate">
              <div className="squares square1" />
              <div className="squares square2" />
              <div className="squares square3" />
              <div className="squares square4" />
              <div className="squares square5" />
              <div className="squares square6" />
              <div className="squares square7" />
            </div>
          </div>
          <Row className="align-items-center">
            <Col lg={5}>
              <div className="text-white-50">
                <h1 className="text-white fw-semibold mb-3 hero-title">{props.t("t-caption")}</h1>
                <p className="font-size-15 text-warning">{props.t("t-sub-caption")}</p>

                <div className="d-flex flex-wrap gap-2 mt-4">
                  <Link to="/register" className="btn drops-bg-shade-8"><span className="px-3">Register</span></Link>
                  <Link to="/donate" className="btn drops-bg-shade-7">Donate</Link>
                </div>
              </div>
            </Col>
            <Col lg={5} md={8} sm={10} className="ms-lg-auto">
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

Section.propTypes = {
  t: PropTypes.any
};
export default withTranslation()(Section);