import React, {useEffect, useState} from "react"
import PropTypes from 'prop-types'
import { Link } from "react-router-dom"
import { Row, Col, Card, CardBody, Modal, ModalBody } from "reactstrap"
import { v4 as uuidv4 } from 'uuid';
import QRCode from "react-qr-code";
import PieSocket from 'piesocket-js';

var pieSocket = new PieSocket({
  clusterId: "free.nyc1",
  apiKey: "WKfYemehehooplGk7aVA5YG6Csw0q7qefaF5jyzh",
  notifySelf: false
});

//not used currently
const WhatsAppComponent = props => {

  const [currentUUID, setCurrentUUID] = useState('')
  const [qrResponse, setQrResponse] = useState('')
  const [show, setShow] = useState(false)

  useEffect(()=>{
    let uuid = ""
    if (localStorage.getItem("dps_wp_uuid") != undefined && localStorage.getItem("dps_wp_uuid") != null && localStorage.getItem("dps_wp_uuid") != '') {
      uuid = localStorage.getItem("dps_wp_uuid")
    }
    else{
      //localStorage.setItem('dps_wp_uuid','drops-wp-no-918075252627')
      uuid = uuidv4()
      localStorage.setItem('dps_wp_uuid',uuid)
    }
    //console.log('final:uu:',uuid)
    setCurrentUUID(uuid)
    props.setCurrentUUID(uuid)
  },[])

  useEffect(()=>{
    console.log('currentUUID:',currentUUID)
    if(currentUUID && currentUUID != ''){
      pieSocket.subscribe(currentUUID).then((channel)=>{
        //console.log("Channel is ready");
    
        channel.listen("qr_code", (data, meta)=>{
            //console.log("New message: ", data);
            setQrResponse(data)
            setShow(true)
        });
  
        channel.listen("client_ready", (data, meta)=>{
          console.log("Client Ready: ", data);
          setQrResponse('')
          setShow(false)
        });
  
      });
    }
  },[currentUUID])

  const onCloseClick=()=>{
    setShow(!show)
  }

  return (
    <>
    <Modal size="sm" isOpen={show} toggle={onCloseClick} centered={true}>
      <div className="modal-content">
        <ModalBody className="px-4 py-5 text-center">
          <p className="text-muted font-size-16 mb-4">Please scan the QR code and wait. It will take a while.</p>
          <QRCode size={300} level={"L"} value={qrResponse} />
        </ModalBody>
      </div>
    </Modal>
    </>
  )
}

WhatsAppComponent.propTypes = {
}

export default WhatsAppComponent
