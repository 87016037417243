import React, { useState } from "react";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import PropTypes from 'prop-types';
import { withTranslation } from "react-i18next";
import aboutImg from "../../../assets/images/about.png";

const AboutUs = (props) => {
  
  return (
    <React.Fragment>
      <section className="section pt-4" id="about">
        <Container>
          <Row>
            <Col lg="12">
              <div className="text-center mb-5">
                <h4>About</h4>
              </div>
            </Col>
          </Row>
          <Row className="align-items-center">
            <Col lg="6">
              <div className="mx-2">
                <h4>VISION</h4>
                <p className="font-size-15 text-justify">
                {props.t("t-vision")}
                </p>
                <p className="mb-4">
                </p>
                <h4>MISSION</h4>
                <p className="font-size-15 text-justify">
                {props.t("t-mission")}
                </p>
              </div>
            </Col>

            <Col lg="6" className="ms-auto">
              <div className="mt-4 mt-lg-0">
                <img
                  src={aboutImg}
                  alt=""
                  className="img-fluid mx-auto d-block"
                />
              </div>
            </Col>
          </Row>

        </Container>
      </section>
    </React.Fragment>
  );
};

AboutUs.propTypes = {
  t: PropTypes.any
};
export default withTranslation()(AboutUs);