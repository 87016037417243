import { func } from "prop-types"
import React from 'react'
import toastr from "toastr"
import "toastr/build/toastr.min.css"
import * as moment from "moment"
import {UPLOAD_PREFIX_FOLDER} from "./url_helper"
//import imageCompression from 'browser-image-compression';
//import { getSignedURL, uploadImageToS3 } from "./backend_helper"
import { putFile } from "./api_helper"
//import { AES } from 'crypto-js';
//import Utf8 from 'crypto-js/enc-utf8';
import dayjs from "dayjs";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { isEmpty } from "lodash";
const MySwal = withReactContent(Swal)

const passphrase = 'blah2012';

export function showAlertPopUp(msg){
  MySwal.fire({
    icon: 'error',
    title: 'Oops...',
    text: msg,
  })
}

export function showSuccessPopUp(msg){
  MySwal.fire({
    icon: 'success',
    title: 'Success',
    text: msg,
  })
}

export function downloadPDF(pdf, filename) {
  //const linkSource = `data:application/pdf;base64,${pdf}`;
  const downloadLink = document.createElement("a");
  //const fileName = filename //"drops_registration_confirmation_"+id+".pdf";
  downloadLink.href = pdf;
  downloadLink.download = filename;
  downloadLink.click();
}

export function getCurrentUUID(){
  let uuid = ""
    if (localStorage.getItem("dps_wp_uuid") != undefined && localStorage.getItem("dps_wp_uuid") != null && localStorage.getItem("dps_wp_uuid") != '') {
      uuid = localStorage.getItem("dps_wp_uuid")
    }
    else{
      uuid = "uuid-8075252627-dps"//uuidv4()
      localStorage.setItem('dps_wp_uuid',uuid)
    }
    return uuid
}

export const getAddress=(empDetails)=>{
  let address = ''
    if(!isEmpty(empDetails.house_name)){
      address += empDetails.house_name
    }
    if(!isEmpty(empDetails.place)){
      address += ", "+ empDetails.place
    }
    if(!isEmpty(empDetails.post_office)){
      address += ", "+ empDetails.post_office
    }
    if(!isEmpty(empDetails.panchayath) && empDetails.panchayath != empDetails.post_office){
      address += ", "+ empDetails.panchayath
    }
    if(!isEmpty(empDetails.otherpanchayath)){
      address += ", "+ empDetails.otherpanchayath
    }
    if(!isEmpty(empDetails.pin_code)){
      address += " - "+ empDetails.pin_code
    }
    return address
}

export const getContMonthsDesc=(selectedMonths)=>{
  let contMonDescTemp = ''
  selectedMonths.forEach(each=>{
    contMonDescTemp += each.label + ', '
  })
  if(contMonDescTemp.endsWith(', ')){
    contMonDescTemp = contMonDescTemp.substring(0, contMonDescTemp.lastIndexOf(', '))
  }
  return contMonDescTemp
}

export const findTheMax=(datesArray)=>{
  const highestDate = datesArray.reduce((maxDate, currentDate) => {
    const parsedDate = dayjs(currentDate.value, 'MMM-YY');
    return parsedDate.isAfter(maxDate) ? parsedDate : maxDate;
  }, dayjs('1900-01-01'));
  return highestDate.format('YYYY-MM-DD')
}

export const getNextCollectionDate=(lastPayMonth, collDate)=>{
  const nextDueDate = dayjs(lastPayMonth).add(1,"month").set("date",collDate)
  return nextDueDate.format('YYYY-MM-DD')
}

export const convertRegObjToIRegister=(data)=>{
  const retData = {
    name: data.fullname,
    houseName: data.house_name,
    postOffice: data.post_office,
    pinCode: data.pin_code,
    panchayath: data.panchayath,
    otherpanchayath:data.otherpanchayath,
    wardNo:data.ward_no,
    mobileNo:data.mobile_no,
    whatsAppNo:data.whatsapp_no,
    aadharNo: data.aadhar_no,
    monthlySubsAmount:data.monthly_amount,
    collectionDate:data.collection_date,
    subsDepositMethod:data.deposit_method,
    modeOfPayment:data.mode_payment,
    recommendedPerson:data.recommended_person,
    place:data.place,
    age:data.age,
    gender:data.gender,
    recordStatus: data.record_status,
    randomId: data.random_id,
    id: data.id,
    refId: data.id + ''+data.random_id
  }
  return retData
}
export const showNoDataIndication = (isLoading) => {
    if(isLoading){
      return (
        <div className="text-center my-3">
          <div className="text-success">
            <i className="bx bx-loader bx-spin font-size-18 align-middle me-2" />
            Loading...
          </div>
        </div>
      )
    }
    else{
      return <div className="text-center my-3">- No transactions found -</div>
    }
}

export function getNextSeries(inputDate, seriesType){
  
  let beforeDate = moment(inputDate)
  if(seriesType == 'DL'){ //Daily
    beforeDate = beforeDate.add(1, 'days')
  }
  else if(seriesType == 'WL'){ //Weekly
    beforeDate = beforeDate.add(7, 'days')
  }
  else if(seriesType == 'FN'){ //FortNightly
    beforeDate = beforeDate.add(14, 'days')
  }
  else if(seriesType == 'ML'){ //Monthly
    beforeDate = beforeDate.add(1, 'month')
  }
  else if(seriesType == '3M'){ //3 Months
    beforeDate = beforeDate.add(3, 'month')
  }
  else if(seriesType == '6M'){ //6 Months
    beforeDate = beforeDate.add(6, 'month')
  }
  else if(seriesType == 'YL'){ //Yearly
    beforeDate = beforeDate.add(1, 'year')
  }
  return new Date(beforeDate)
}

export function getUpdatedReminderDate(currentDue, currentReminder, updatedDue){
  //console.log('currentDue',currentDue)
  //console.log('currentReminder',currentReminder)
  const currentDueTemp = new Date(currentDue).getTime()
  const currentReminderTemp = new Date(currentReminder).getTime()
  const diffInTime = currentDueTemp - currentReminderTemp
  let beforeDate = moment(updatedDue)
  //console.log('beforeDate 0:',beforeDate)
  beforeDate = beforeDate.add(-diffInTime, 'milliseconds')
  //console.log('beforeDate 1:',beforeDate)
  return new Date(beforeDate)
}

export function secondsToHms(d) {
  d = Number(d);
  var h = Math.floor(d / 3600);
  var m = Math.floor(d % 3600 / 60);
  var s = Math.floor(d % 3600 % 60);

  var hDisplay = h > 0 ? h + (h == 1 ? " hour, " : " hours, ") : "";
  var mDisplay = m > 0 ? m + (m == 1 ? " min, " : " min, ") : "";
  var sDisplay = s > 0 ? s + (s == 1 ? " sec" : " sec") : "";
  return hDisplay + mDisplay + sDisplay; 
}

export function showSuccessToast(msg) {
    toastr.options = {
        positionClass: "toast-top-right",
        timeOut: 3000,
        closeButton: false,
        debug: false,
        progressBar: false,
        preventDuplicates: false,
        newestOnTop: true,
        showEasing: 'swing',
        hideEasing: 'linear',
        showMethod: 'fadeIn',
        hideMethod: 'fadeOut',
        showDuration: 300,
        hideDuration: 1000
    }
    toastr.success(msg, 'Success')
}

export function showCloseableToast(message, position,toastType) {
    toastr.options = {
        positionClass: "toast-"+position, //top/bottom-left/right/center/full-width, 
        timeOut: 3000,
        closeButton: true,
        debug: false,
        progressBar: true,
        preventDuplicates: false,
        newestOnTop: true,
        showEasing: 'swing',
        hideEasing: 'linear',
        showMethod: 'fadeIn',
        hideMethod: 'fadeOut',
        showDuration: 300,
        hideDuration: 1000
    }
    if (toastType === "info") toastr.info(message, "Info")
    else if (toastType === "warning") toastr.warning(message, "Warning")
    else if (toastType === "error") toastr.error(message, "Alert")
    else toastr.success(message, "Success")
}

function fallbackCopyTextToClipboard(text) {
    var textArea = document.createElement("textarea");
    textArea.value = text;
    
    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.position = "fixed";
    
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    var successful = false
    try {
      successful = document.execCommand('copy');
      if (successful) {
        showCloseableToast('Copied Successfully', 'top-center', 'info')
      }
    } catch (err) {
      console.error('Fallback: Oops, unable to copy', err);
    }
  document.body.removeChild(textArea);
 }
  
 export function copyTextToClipboard(text) {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text);
    return
  }
  navigator.clipboard.writeText(text).then(function () {
    showCloseableToast('Copied Successfully', 'top-center', 'success')
  }, function (err) {
    console.error('Async: Could not copy text: ', err);
  });
 }

export function validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
}

export function validateAadhar(aadharNo){
  //const regexp = /^[2-9]{1}[0-9]{3}\s[0-9]{4}\s[0-9]{4}$/; 
  const regexp = /^[2-9]{1}[0-9]{3}\s{0,1}[0-9]{4}\s{0,1}[0-9]{4}$/;
  return regexp.test(aadharNo);
}

export function vailidatePAN(panNo){
  const regpan = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;
  return regpan.test(panNo);
}

export function validateIFSCCode(ifscCode){
  const regexp = /^[A-Za-z]{4}0[A-Za-z0-9]{6}$/;
  return regexp.test(ifscCode);
}

export function validateBankAccount(bankAccNo){
  const regexp = /^\d{9,18}$/;
  return regexp.test(bankAccNo);
}

export function validateIndianMobileNo(mobNo){
  const regexp = /^\d{10}$/;
  return regexp.test(mobNo);
}

export function isLoggedIn(){
  if(localStorage.getItem('authUser')){
    return true
  }
  else{
    return false
  }
}

export function getAge(dateString) {
  var today = new Date();
  var birthDate = new Date(dateString);
  var age = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
  }
  return age;
}

const priorityOrder = {
  'H':1,
  'N':2,
  'L':3
}

export const getTodayDateStr = (inpFormat) => {
  return moment(new Date()).format(inpFormat)
}

export const getFormatedDateStr = (inpDate, inpFormat) => {
  return moment(new Date(inpDate)).format(inpFormat)
}

export const createDropDownFromMasterData = (inputArray) => {
  const finalDD = []
  inputArray.forEach(each => {
    finalDD.push({
      label: each.pbvalue,
      value: each.id
    })
  })
  return finalDD;
}

export const createDropDownFromValue = (inputArray) => {
  const finalDD = []
  inputArray.forEach(each => {
    finalDD.push({
      label: each.pbvalue,
      value: each.pbvalue
    })
  })
  return finalDD;
}


export const getExperienceYearsDD = (maxLimit) => {
  const dropDown = []
  for (let index = 0; index < maxLimit; index++) {
    dropDown.push({
      value:index,
      label: index
    })
  }
  return dropDown
}

export const getExperienceMonthsDD = () => {
  const dropDown = []
  for (let index = 0; index < 12; index++) {
    dropDown.push({
      value:index,
      label: index
    })
  }
  return dropDown
}

export const sortTasks = (tasks, option) =>{
  //console.log('tasks before:',tasks)
  if(option == 'Due Date'){
    const sortedArray = tasks.sort((a, b) => {
      //console.log('a.due::',a.due)
      let firstDate = moment(a.due).format('YYYYMMDDHH24MMSS')
      if(!a.due){
        firstDate = moment(new Date()).add(1, 'years').format('YYYYMMDDHH24MMSS')
      }
      let secondDate = moment(b.due).format('YYYYMMDDHH24MMSS')
      if(!b.due){
        secondDate = moment(new Date()).add(1, 'years').format('YYYYMMDDHH24MMSS')
      }
      return firstDate - secondDate
    })
    return sortedArray
  }
  else if(option == 'Reminder Date'){
    const sortedArray = tasks.sort((a, b) => {
      //console.log('a.due::',a.due)
      let firstDate = moment(a.remnds).format('YYYYMMDDHH24MMSS')
      if(!a.remnds){
        firstDate = moment(new Date()).add(1, 'years').format('YYYYMMDDHH24MMSS')
      }
      let secondDate = moment(b.remnds).format('YYYYMMDDHH24MMSS')
      if(!b.remnds){
        secondDate = moment(new Date()).add(1, 'years').format('YYYYMMDDHH24MMSS')
      }
      return firstDate - secondDate
    })
    return sortedArray
  }
  else if(option == 'Created Date'){
    const sortedArray = tasks.sort((a, b) => (a.id) - (b.id))
    return sortedArray
  }
  else if(option == 'Priority'){
    const sortedArray = tasks.sort((a, b) => priorityOrder[a.priority] - priorityOrder[b.priority])
    return sortedArray
  }
}

/*
export function SetS3Config(level,bucket){
  if(bucket == undefined || bucket === ''){
    bucket = process.env.REACT_APP_BUCKET_NAME
  }
  Storage.configure({ 
      bucket: bucket,
      level: level,
      region: process.env.REACT_APP_REGION,  
      identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID
  });
}
*/

export function toHoursAndMinutes(totalMinutes) {
  const hours = Math.floor(totalMinutes / 60);
  const minutes = Math.floor(totalMinutes % 60);
  return `${hours}h${minutes > 0 ? ` ${minutes}m` : ''}`;
}

export function getTodaysDate(format){
  const dFormat = format ? format : 'YYYY-MM-DD'
  return dayjs().format(dFormat)
}

export function getTheDate(days, format){
  const dFormat = format ? format : 'YYYY-MM-DD'
  return dayjs().add(days,"days").format(dFormat)
}

export function getFormattedDate(date,format){
  const dFormat = format ? format : 'YYYY-MM-DD'
  return dayjs(date).format(dFormat)
}

export function getTimeNow(){
  return dayjs().format('HH:mm')
}

export function parseLocation(inputLocationStr){
  const inputLocation = JSON.parse(inputLocationStr)
  if(inputLocation && inputLocation.place && inputLocation.place[0]){
    let place = inputLocation.place[0]
    let locationString = ""
    if(place.name && place.name.indexOf("+")==-1){
      locationString += place.name+", "
    }
    if(place.streetNumber){
      locationString += place.streetNumber+", "
    }
    if(place.street){
      locationString += place.street+", "
    }
    if(place.district){
      locationString += place.district+", "
    }
    if(place.city){
      locationString += place.city+", "
    }
    if(place.subregion){
      locationString += place.subregion+", "
    }
    if(place.region){
      locationString += place.region+", "
    }
    if(place.postalCode){
      locationString += place.postalCode+""
    }
    return locationString
  }
  return "-"
}

export function getCurrentAndLastMonthsDD(months){
  const finalArray = []
  for (let index = 0; index < months; index++) {
    const each = dayjs().add(-index,"month").format("MMM-YY")
    finalArray.push({
      label: each,
      value: each
    })
  }
  return finalArray
}

export function getMonthsDD(startMonth, endMonth){
  const finalArray = []
  for (let index = startMonth; index <= endMonth; index++) {
    const each = dayjs().add(index,"month").format("MMM-YY")
    finalArray.push({
      label: each,
      value: each
    })
  }
  return finalArray
}

export function getMonthsDifference(inputDateStr){
  if(inputDateStr == null || inputDateStr == undefined){
    return 0
  }

  try {
    const inputDate = dayjs(inputDateStr).startOf("month")
    const tempDate = dayjs().startOf("month")
    return inputDate.diff(tempDate,'month')

  } catch (error) {
    return 0
  }
  return 0
}

export const getURLReplacedContent = str => {

  var m,
  urls = [],
  rex = /(img src=\"https?:\/\/[^"]*)/g;

  //m = rex.exec( str )

  let counter = 1;
  while ( m = rex.exec( str ) ) {
    urls.push( m[1] );
    counter++
    if(counter > 100){
      console.log('breaking..')
      break
    }
  }
  urls.forEach(each =>{
    if(each.indexOf(UPLOAD_PREFIX_FOLDER) > 0){
      let extracted = each.substring(each.indexOf(UPLOAD_PREFIX_FOLDER), each.indexOf("?"))
      str = str.replace(each,"img src=\"https://"+extracted)
    }
  })
  return str
}

export function ckImageUploadPlugin(editor) {
  editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
    return uploadAdapter(loader);
  };
}

//Adapter to upload the image to S3 and give the presigned URL
/*
function uploadAdapter(loader) {
  return {
    upload: () => {
      return new Promise((resolve, reject) => {
        const body = new FormData();
        loader.file.then((file) => {
          SetS3Config("public");
          //let compressedImage = compressImageFile(file)
          compressImageFile(file).then(compressed => {
            //console.log('UPLOAD_PREFIX_FOLDER:'+UPLOAD_PREFIX_FOLDER)
            Storage.put(UPLOAD_PREFIX_FOLDER+"/"+compressed.name, compressed, { contentType: compressed.type })
            .then(result => {
            getImageURL(result.key).then(response =>{
              resolve({
                default: response
              });
            })
            })
            .catch(err => {
              console.log('err...:',err)
            });
          })
        });
      });
    }
  };
}
*/

/*
export async function uploadImageToS3WithPreSignedURL(inpFile){
  const renamedFile = renameFile(inpFile, new Date().getTime()+'_'+inpFile.name);
  console.log('renamedFile::',renamedFile)
  const respURL = await getSignedURL({
    fileName: renamedFile.name,
    contentType: renamedFile.type
  })

  const finalStatus = await putFile(respURL.signedURL, renamedFile,renamedFile.type)
  console.log('finalStatus::',finalStatus)
  return finalStatus;
}
*/

export function isNumber(num){
  if(Number(num) || num == 0){
    return true;
  }
  else{
    return false;
  }
}

export function getBase64StringFromDataURL(dataURL){
  return dataURL.replace('data:', '').replace(/^.+,/, '');
}

export function onlyDigits(str){
  var pattern = /^\d+$/;
  return pattern.test(str);  // returns a boolean
}
/*
export function uploadImage(inpFile){
  return new Promise((resolve, reject) => {
    SetS3Config("public");
    const renamedFile = renameFile(inpFile, new Date().getTime()+'_'+inpFile.name);
    Storage.put(UPLOAD_PREFIX_FOLDER+"/"+renamedFile.name, renamedFile, { contentType: renamedFile.type })
      .then(result => {
        resolve ({
          status: 'success',
          fileName: result.key
        })
      })
      .catch(err => {
        console.log('err',err)
        reject({
            status: 'failure',
            error: err
        })
      });
  });
};

export function uploadPDFtoS3(inpFile, fileName){
  return new Promise((resolve, reject) => {
    SetS3Config("public");
    Storage.put(UPLOAD_PREFIX_FOLDER+"/"+fileName, inpFile, { contentType: inpFile.type })
      .then(result => {
        resolve ({
          status: 'success',
          fileName: result.key
        })
      })
      .catch(err => {
        console.log('err',err)
        reject({
            status: 'failure',
            error: err
        })
      });
  });
};

export function deleteFile(inpFile){
  return new Promise((resolve, reject) => {
    SetS3Config("public");
    Storage.remove(inpFile)
    .then(result => {
      resolve ({
        status: 'success'
      })
    })
    .catch(err => {
      console.log('err',err)
      reject({
          status: 'failure',
          error: err
      })
    });
  });
}
*/
export const renameFile = (bits, name, options) => {
  try {
    // If this fails, we go for Blob
    return new File([bits], name, options);
  } catch (e) {
    // If we're here a new File could not be constructed
    var myBlob = new Blob([bits], options || {});
    myBlob.lastModified = new Date();
    myBlob.name = name;
    return myBlob;
  }
};

export function downloadBlob(blob,newFileName) {
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = newFileName || 'download';
  const clickHandler = () => {
    setTimeout(() => {
      URL.revokeObjectURL(url);
      a.removeEventListener('click', clickHandler);
    }, 150);
  };
  a.addEventListener('click', clickHandler, false);
  a.click();
  return a;
}

/*
export async function downloadFile(fileKey, newFileName) {
  const result = await Storage.get(fileKey, { download: true });
  downloadBlob(result.Body, newFileName ? newFileName: fileKey);
}

export async function getSignedUrl(inpFile) {
  const url = await Storage.get(inpFile)
  return url;
}
*/

export function getOnlyFileName(inpFileName){
  if(inpFileName == null || inpFileName == "" || inpFileName.indexOf(UPLOAD_PREFIX_FOLDER) == -1){
    return inpFileName;
  }
  else {
    return inpFileName.substring(inpFileName.indexOf(UPLOAD_PREFIX_FOLDER)+UPLOAD_PREFIX_FOLDER.length+1)
  }
}

/**
   * Formats the size
   */
export function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return "0 Bytes"
  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

  const i = Math.floor(Math.log(bytes) / Math.log(k))
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
}

/** Get pre signed URL */
/*
async function getImageURL(fileName){
  const resp = await Storage.get(fileName, {
    //level?: private | protected | public, // defaults to `public`
    //identityId?: string, // id of another user, if `level: protected`
    //download?: boolean, // defaults to false
    expires: 2 * 60 * 60, // validity of the URL, in seconds. defaults to 900 (15 minutes)
    //contentType?: string // set return content type, eg "text/html"
  });
  return resp
}
*/

export function addDueAndReminderExpiryFlags(tasks){
  tasks = tasks.map(each => {
    return {
      ...each,
      isDueOver:isPastTime(each.due),
      isReminderOver:isPastTime(each.remnds)
    }
  })
  return tasks
}

export function isPastTime(dateToCheck){
  
  if(dateToCheck == undefined || dateToCheck == null || dateToCheck == ''){
    return false
  }

  const now = new Date().getTime()
  const selectedTime = new Date(dateToCheck).getTime()
  if(now < selectedTime){
    return false
  }
  else{
    return true
  }
}

/*
export const compressImageFile = async (image) => {
  var options = {
    maxSizeMB: 1,
  }
  try {
    const compressedFile = await imageCompression(image, options);
    //console.log('compressedFile 0',compressedFile)
    return compressedFile
  } catch (error) {
    console.log(error);
  }
}
*/

export const convertToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      resolve(fileReader.result);
    };
    fileReader.onerror = (error) => {
      reject(error);
    };
  });
};

export const getClassNameForStatus = (status)=>{
  let clazz = "info"
  switch (status) {
    case "New":
      clazz = "danger"
      break;
    case "In Progress":
      clazz = "warning"
      break;
    case "Assigned":
      clazz = "primary"
      break;
    case "Completed":
      clazz = "success"
      break;
    default:
      break;
  }
  return clazz
}

/*
const substitutionsAfterEncryption = new Map([
  ['+', '-'],
  ['/', '_'],
  ['=', '~'],
]);
const substitutionsBeforeDecryption = new Map([
  ['-', '+'],
  ['_', '/'],
  ['~', '='],
]);

export const encryptCrypto=(plaintext) =>{
  const encrypted = AES.encrypt(plaintext, passphrase).toString();
    return encrypted.replace(
        /[+/=]/g,
        (match) => substitutionsAfterEncryption.get(match) ?? match
    );
}

export const decryptCrypto=(cipher) =>{
  const toDecrypt = cipher.replace(
    /[-_~]/g,
    (match) => substitutionsBeforeDecryption.get(match) ?? match
);
const decryped = AES.decrypt(toDecrypt, passphrase).toString(Utf8);
if(decryped && decryped != ''){
  return decryped 
}
return 'UKN'
}

export const encrypt = (salt, text) => {
  const textToChars = (text) => text.split("").map((c) => c.charCodeAt(0));
  const byteHex = (n) => ("0" + Number(n).toString(16)).substr(-2);
  const applySaltToChar = (code) => textToChars(salt).reduce((a, b) => a ^ b, code);

  return text
    .split("")
    .map(textToChars)
    .map(applySaltToChar)
    .map(byteHex)
    .join("");
};

export const decrypt = (salt, encoded) => {
  const textToChars = (text) => text.split("").map((c) => c.charCodeAt(0));
  const applySaltToChar = (code) => textToChars(salt).reduce((a, b) => a ^ b, code);
  return encoded
    .match(/.{1,2}/g)
    .map((hex) => parseInt(hex, 16))
    .map(applySaltToChar)
    .map((charCode) => String.fromCharCode(charCode))
    .join("");
};
*/