import indiaFlag from "../assets/images/flags/india.png"

const languages = {
  
  en: {
    label: "English",
    flag: indiaFlag,
    lang: 'ENG'
  },
  ml: {
    label: "Malayalam",
    flag: indiaFlag,
    lang: 'MAL'
  },
}

export default languages
