import React from "react"
import { Navigate } from "react-router-dom"

// Profile
import UserProfile from "../pages/Authentication/user-profile"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import CreateNewUser from "../pages/Authentication/CreateNewUser"
import ForgetPwd from "../pages/Authentication/ForgetPassword"
import RegistrationWithAttachments from "../pages/Registration/RegistrationWithAttachments"
import RegistrationSuccess from "../pages/Registration/RegistrationSuccess"
import RegHome from "../pages/Registration/RegHome"
import PublicHome from "../pages/Home/PublicHome"
import RegistrationsList from "../pages/Dashboard/RegistrationsList"
import PaymentsList from "../pages/Dashboard/PaymentsList"
import AddPayment from "../pages/Dashboard/AddPayment"
import WPTest from "../pages/Dashboard/WPTest"
import InitialiseWhatsApp from "../pages/Dashboard/InitialiseWhatsApp"
import InitialiseWhatsAppScheduler from "../pages/Dashboard/InitialiseWhatsAppScheduler"

import PaymentHome from "../pages/Payment/PaymentHome"
import PaymentRedirectHandler from "../pages/Payment/PaymentRedirectHandler"
import WhatsAppScheduledMsgList from "../pages/Dashboard/WhatsAppScheduledMsgList"
import PrivacyPolicy from "../pages/Home/PrivacyPolicy"
import TnC from "../pages/Home/TnC"
import RefundPolicy from "../pages/Home/RefundPolicy"


// Dashboard
import Dashboard from "../pages/Dashboard/index"

const authProtectedRoutes = [
  { path: "/dashboard", component: <Dashboard/> },

  // //profile
  { path: "/profile", component: <UserProfile/> },
  { path: "/addnewuser", component: <CreateNewUser/> },
  { path: "/add-payment", component: <AddPayment/> },
  { path: "/edit-reg/:id", component: <RegistrationWithAttachments /> },
  { path: "/edit-payment/:id", component: <AddPayment /> },
  { path: "/registrations", component: <RegistrationsList /> },
  { path: "/payments", component: <PaymentsList /> },
  { path: "/wptest", component: <WPTest /> },
  { path: "/init-wp", component: <InitialiseWhatsApp /> },
  { path: "/init-wp-bin", component: <InitialiseWhatsAppScheduler /> },
  { path: "/msg-status", component: <WhatsAppScheduledMsgList /> },
  
  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
   { path: "/", exact: true, component: < Navigate to="/register" />},
]

const publicRoutes = [
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
  { path: "/forgot-password", component: <ForgetPwd /> },
  { path: "/reg-home", component: <RegHome /> },
  { path: "/home", component: <PublicHome /> },
  { path: "/register", component: <RegistrationWithAttachments /> },
  { path: "/donate", component: <PaymentHome /> },
  { path: "/pp-redirect", component: <PaymentRedirectHandler /> },
  { path: "/view-reg", component: <RegistrationWithAttachments /> },
  { path: "/view-reg/:id", component: <RegistrationWithAttachments /> },
  { path: "/registration-success", component: <RegistrationSuccess /> },
  { path: "/privacy-policy", component: <PrivacyPolicy /> },
  { path: "/terms-conditions", component: <TnC /> },
  { path: "/refund-policy", component: <RefundPolicy /> },
  { path: "/", exact: true, component: < Navigate to="/home" />},
]

export { authProtectedRoutes, publicRoutes }

