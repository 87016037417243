import React, { useState, useEffect, useRef } from "react"
import {
  Card,
  CardBody,
  Col,
  Row,
  Table,
} from "reactstrap"


const EditPaymentChanges = props => {
  const filedMap = {
    regNo: "Reg Id",
    userName: "Name",
    subsAmount: "Amount",
    receiptDate: "Receipt Date",
    contMonths: "Contribution Period",
    contMonthsDesc:"Contribution Period",
    comments:"Remarks",
    modeOfPayment:"Mode Of Payment",
    paymentRefNo: "Payment Reference No",
    refId: "User Reference No",
  }

  const excludeList = ['contMonths']

  return (
    <React.Fragment>
      <Row>
        <Col lg="12">
          <Card className="mb-0">
            <CardBody className="pt-0">
              <div className="table-responsive">
                <Table className="table table-nowrap align-middle mb-0">
                  <thead className="table-light">
                    <tr>
                      <th scope="col">Field</th>
                      <th scope="col">Old Value</th>
                      <th scope="col">New Value</th>
                    </tr>
                  </thead>
                  <tbody>
                    {props.changedFields.map((field, key) => (
                      !excludeList.includes(field) &&
                      <tr key={key}>
                        <th scope="row">
                          <div className="d-flex align-items-center">
                            <span>{filedMap[field]}</span>
                          </div>
                        </th>
                        <td>
                          <div className="font-size-14">
                            {props.oldValues[field]}
                          </div>
                        </td>
                        <td>
                          <div className="font-size-14">
                            {props.newValues[field]}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

EditPaymentChanges.propTypes = {
}

export default EditPaymentChanges
