import React from "react"
import { Row, Col, Card } from "reactstrap"
import logo from "assets/images/logo.jpeg"

const DropsHeader = props => {
  return (
    <>
    <Row className="justify-content-center mt-2">
      <Col xs={12} md={9} lg={8} xl={6}>
        <Card className="overflow-hidden mb-1">
          <div className="primary-dark bg-soft">
            <Row>
              <Col className="col-5 justify-content-end text-center d-flex align-items-center p-1">
                <img src={logo} alt="" className="img-fluid" style={{maxHeight:"60px"}}/>
              </Col>
              <Col xs={7} className="d-flex align-items-center">
                <div className="">
                  <h5 className="drops-title pb-0 mb-0">ഒന്നായ് തണലേകാം</h5>
                </div>
              </Col>
            </Row>
          </div>
        </Card>
      </Col>
    </Row>
    </>
  )
}

DropsHeader.propTypes = {
}

export default DropsHeader
