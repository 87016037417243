import React from "react"
import { Row, Col } from "reactstrap"

//Import Images
import logolight from "../../../assets/images/logo.jpeg"
import { Link } from "react-router-dom"

const FooterLink = () => {
  return (
    <React.Fragment>
      <Row>
        <Col lg="6">
          <div className="mb-4">
            <img src={logolight} alt="" height="50" />
          </div>

          <div className="fw-bold text-white font-size-15 ">
            DROPS Charitable and Welfare Society
          </div>
          <div className="fw-bold text-white font-size-15 pt-2">
            Reg. No : MPM/CA/352/2023
          </div>
          <div className="text-white font-size-14 py-3">
            B.No. 2096, Ward-9, Menonpotty Rd, Near Palliative Clinic
            <br />
            Edakkara PO, Malappuram DT, Kerala
          </div>

          <div className="mt-2 text-white text-wrap mb-4">
            +91 80 75 25 26 27 | dropsedakkara@gmail.com | www.dropscharity.com
          </div>

          <div className="mt-2 text-white text-wrap mb-4">
            <Link to="/privacy-policy">Privacy Policy</Link>
            <span className="mx-3">{" | "}</span>
            <Link to="/terms-conditions">Terms & Conditions</Link>
            <span className="mx-3">{" | "}</span>
            <Link to="/refund-policy">Refund Policy</Link>
          </div>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default FooterLink
