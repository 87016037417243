import React, { useEffect, useState } from "react";

//Import Components
import Navbar from "./Navbar/Navbar"
import Section from "./HeroSection/Section"
import CardsMini from "./HeroSection/cards-mini"
import AboutUs from "./AboutUs/about-us"
import Features from "./Features/features"
import RoadMap from "./RoadMap/road-map"
import OurTeam from "./Team/our-team"
import Blog from "./Blog/blog"
import FAQs from "./Faqs/FAQs"
import Footer from "./Footer/footer"
import PropTypes from 'prop-types';
import { withTranslation } from "react-i18next";

const PublicHome = (props) => {
  
  //meta title
  document.title="Drops | ഒന്നായ് തണലേകാം";

  const [imglight, setimglight] = useState(true);
  const [navClass, setnavClass] = useState("");

  // Use ComponentDidMount
  useEffect(() => {
    window.addEventListener("scroll", scrollNavigation, true)
  },[])

  function scrollNavigation() {
    var scrollup = document.documentElement.scrollTop
    if (scrollup > 80) {
      setimglight(false)
      setnavClass("nav-sticky")
    } else {
      setimglight(true)
      setnavClass("")
    }
  }

  return (
    <React.Fragment>
      {/* import navbar */}
      <Navbar navClass={navClass} imglight={imglight} />

      {/* Hero section */}
      <Section />
      <AboutUs />
      
      <Features />
      <OurTeam />
      {/**
      <CardsMini />
      
      
      <RoadMap />
      
      <Blog />
      <FAQs />
       */}

      {/* footer */}
      <Footer />
    </React.Fragment>
  )
}

PublicHome.propTypes = {
  t: PropTypes.any
};
export default withTranslation()(PublicHome);