import React from "react"
import { Container, Row, Col, Card, CardBody, CardFooter, UncontrolledTooltip } from "reactstrap"

//Import Images
import { Link } from "react-router-dom";
//swiper
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation } from "swiper";
import "../../../../node_modules/swiper/swiper.scss";

import u1 from "assets/images/users/u1.jpg";
import u2 from "assets/images/users/u2.jpg";
import u3 from "assets/images/users/u3.png";
import u4 from "assets/images/users/u4.jpg";
import u5 from "assets/images/users/u5.jpg";

const OurTeam = () => {

  const icoLandingTeam = [
    {
      id: 1,
      img: u2,
      author: "Sulaiman Karadan",
      post: "President",
    },
    {
      id: 2,
      img: u3,
      author: "K.R. Bhaskaranpillai",
      post: "General Secretary",
    },
    {
      id: 3,
      img: u1,
      author: "Abdul Hakeem",
      post: "Working President",
    },
    {
      id: 4,
      img: u4,
      author: "Priya George",
      post: "Co-Ordinating Secretary",
    },
    {
      id: 5,
      img: u5,
      author: "Ashraf E",
      post: "Treasurer",
    },
  ];

  return (
    <React.Fragment>
      <section className="section pt-4 pb-4 drops-bg-shade-overlay-9 opacity-9 text-dark" id="team">
        <Container>
          <Row>
            <Col lg="12">
              <div className="text-center mb-5 text-white">
                <h4>Leaders</h4>
              </div>
            </Col>
          </Row>

          <Col lg={12}>
            <Row className="d-flex align-items-center justify-content-center d-md-none">
              {icoLandingTeam.map((item, key) => (
              <Col sm="12" md="4" lg="4" key={key} className="text-center">
                  <Card className="text-center team-box">
                    <CardBody>
                      <div>
                        <img src={item.img} alt="" className="rounded w-100" />
                      </div>

                      <div className="mt-3">
                        <h5>{item.author}</h5>
                        <p className="text-muted mb-0">{item.post}</p>
                      </div>
                    </CardBody>
                  </Card>
                  </Col>
              ))}
            </Row>
            <Row className="d-none d-md-block">
            <Swiper
              slidesPerView={1}
              spaceBetween={10}
              navigation
              pagination={{
                clickable: true,
              }}
              breakpoints={{
                678: {
                  spaceBetween: 5,
                },
                992: {
                  slidesPerView: 5,
                },
                1400: {
                  slidesPerView: 5,
                }
              }}              
              loop={true}
              modules={[Pagination, Navigation]}
              className="owl-carousel owl-theme events navs-carousel" id="team-carousel" dir="ltr">
              {icoLandingTeam.map((item, key) => (
                <SwiperSlide key={key} className="item">
                  <Card className="text-center team-box">
                    <CardBody>
                      <div>
                        <img src={item.img} alt="" className="rounded w-100" />
                      </div>

                      <div className="mt-3">
                        <h5>{item.author}</h5>
                        <p className="text-muted mb-0">{item.post}</p>
                      </div>
                    </CardBody>
                  </Card>
                </SwiperSlide>
              ))}
            </Swiper>
            </Row>
          </Col>
        </Container>
      </section>
    </React.Fragment>
  )
}

export default OurTeam
