import React, { useState, useEffect, useRef } from "react"
import {
  Card,
  Col,
  Container,
  Row,
  Alert,
  UncontrolledAlert
} from "reactstrap"

import { Link } from "react-router-dom"
import logo from "assets/images/logo.jpeg"

const RegHome = props => {

  document.title = "Drops Registration form";
  
  return (
    <React.Fragment>
      <div className="">
        <Container fluid={false}>
          <Row className="justify-content-center mt-2">
            <Col xs={12} md={9} lg={8} xl={6}>
              <Card className="overflow-hidden mb-1">
                <div className="primary-dark bg-soft">
                  <Row>
                    <Col className="col-5 justify-content-end text-center d-flex align-items-center p-1">
                      <img src={logo} alt="" className="img-fluid" style={{maxHeight:"60px"}}/>
                    </Col>
                    <Col xs={7} className="d-flex align-items-center">
                      <div className="">
                        <h5 className="drops-title pb-0 mb-0">ഒന്നായ് തണലേകാം</h5>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Card>
            </Col>
          </Row>
          <Row className="justify-content-center mt-5">
            <Col xs={12} md={9} lg={8} xl={4} className="">
              <Link to="/register" className="text-primary ">
                <Card className="p-3 drops-bg-shade-9">
                  <div className="text-center">
                    <i className="mdi mdi-plus-box-outline me-1" />
                    Add New Registration
                  </div>
                </Card>
              </Link>
            </Col>
            <Col xs={12} md={9} lg={8} xl={4}>
              <Link to="/view-reg" className="text-primary">
                <Card className="p-3 drops-bg-shade-9">
                  <div className="text-center">
                    <i className="mdi mdi-card-search-outline me-1" />
                    Retrieve Registration
                  </div>
                </Card>
              </Link>
            </Col>
          </Row>
        </Container>
      </div>

    </React.Fragment>
  )
}

RegHome.propTypes = {
}

export default RegHome
