import React, { useState, useEffect, useRef, useMemo } from "react"
import {
  Card,
  Col,
  Container,
  Row,
  Alert,
  UncontrolledAlert,
  UncontrolledTooltip,
  Badge,
  CardBody
} from "reactstrap"
import { Link } from 'react-router-dom';
import { useNavigate } from "react-router-dom"
import withRouter from "components/Common/withRouter";
import { isEmpty } from "lodash";
import TableContainer from 'components/Common/TableContainer';
import Loader from "components/Common/Loader"
import {BoldValue, NormalText,  MsgStatus, MsgSendStatus } from "./ListCol";
import { saveDetails, retrieveDetails } from "helpers/backend_helper"
import { showSuccessPopUp, getFormattedDate } from "helpers/function_helper"
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { WP_UUID } from "helpers/url_helper"
import SimpleBar from "simplebar-react";

const MySwal = withReactContent(Swal)

const WhatsAppScheduledMsgList = props => {

  const navigate = useNavigate();
  const [messageList, setMessageList] = useState([])
  const [allContactList, setAllContactList] = useState([])
  const [showLoading, setShowLoading] = useState(false)
  const [clickedOnce, setClickedOnce] = useState(false)
  const [currentMsg, setCurrentMsg] = useState()
  const [error, setError] = useState('')

  useEffect(()=>{
    fetchSchedulesMsgList()
  },[])

  const fetchSchedulesMsgList=async()=>{
    setShowLoading(true)
    setMessageList([])
    const inputToSend = {
      "data" : {
        "type" : "GET_SCH_MSGS",
        "key": WP_UUID
      }
    }
    const response = await retrieveDetails(inputToSend);
    setShowLoading(false)
    if(response && response.status === 'failure'){
      setError(response.error.errorMsg)
    }
    else if(response && response.status === 'success' && response.data){
      setMessageList(response.data)
    }
  }

  const getAllContacts=async(id)=>{
    setClickedOnce(true)
    setCurrentMsg(id)
    setShowLoading(true)
    setAllContactList([])
    const inputToSend = {
      "data" : {
        "type" : "GET_MSG_NOS",
        "key": ''+id
      }
    }
    const response = await retrieveDetails(inputToSend);
    setShowLoading(false)
    
    if(response && response.status === 'failure'){
      setError(response.error.errorMsg)
    }
    else if(response && response.status === 'success' && response.data){
      setAllContactList(response.data)
    }
  }

  const columns = useMemo(
    () => [
        {
            Header: 'Id',
            accessor: 'id',
            filterable: true,
            Cell: (cellProps) => {
                return <BoldValue {...cellProps} />;
            }
        },
        {
            Header: 'WP No',
            accessor: 'wp_number',
            filterable: true,
            Cell: (cellProps) => {
                return <NormalText {...cellProps} />;
            }
        },
        {
            Header: 'Status',
            accessor: 'current_status',
            filterable: true,
            Cell: (cellProps) => {
                return <MsgStatus {...cellProps} />;
            }
        },
        {
          Header: 'Message Status',
          accessor: 'message_status',
          filterable: true,
          Cell: (cellProps) => {
              return <MsgSendStatus {...cellProps} />;
          }
        },
        {
            Header: 'Action',
            accessor: 'action',
            disableFilters: true,
            Cell: (cellProps) => {
                return (
                    <ul className="list-unstyled hstack gap-1 mb-0">
                        <li>
                            {cellProps.row.original.current_status == 0 && 
                            <Link
                                to="#"
                                className="btn btn-sm btn-soft-danger"
                                onClick={() => {
                                    const regData = cellProps.row.original;
                                    handleDelete(regData.id);
                                }}
                            >
                                <i className="mdi mdi-delete-outline" id="deletetooltip" />
                                <UncontrolledTooltip placement="top" target="deletetooltip">
                                    Delete
                                </UncontrolledTooltip>
                            </Link>
                            }
                        </li>
                    </ul>
                );
            }
        },
    ],
    [allContactList]
);

  const handleEdit=(url)=>{     
    //console.log('url',url)
    navigate(url);
  }

  const handleDelete=async(data)=>{
    const result  = await MySwal.fire({
      title: `Are you sure to delete  ?`,
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
      showLoaderOnConfirm:true,
      allowOutsideClick: () => !MySwal.isLoading(),
      preConfirm: (sme) => {
        const inputToSend = {
          "data" : {
            "type" : "DEL_WP_SCH_MSG_NOS",
            "key": ''+data
          }
        }
        return saveDetails(inputToSend)
        .then(response => {
          //console.log('response:',response)
          if (response.status != 'success') {
            throw new Error(response.statusText)
          }
          return response
        })
        .catch(error => {
          MySwal.showValidationMessage(
            `Request failed: ${error}`
          )
        })
      },
    })

    if(result.isConfirmed){
      getAllContacts(currentMsg)
      showSuccessPopUp('Deleted Successfully !')
    }
    //console.log('result:',result)
  }

  const handleDeleteWhatsAppMsg=async(data)=>{
    const result  = await MySwal.fire({
      title: `Are you sure to delete this message?`,
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
      showLoaderOnConfirm:true,
      allowOutsideClick: () => !MySwal.isLoading(),
      preConfirm: (sme) => {
        const inputToSend = {
          "data" : {
            "type" : "DEL_WP_SCH_MSG",
            "key": ''+data
          }
        }
        return saveDetails(inputToSend)
        .then(response => {
          //console.log('response:',response)
          if (response.status != 'success') {
            throw new Error(response.statusText)
          }
          return response
        })
        .catch(error => {
          MySwal.showValidationMessage(
            `Request failed: ${error}`
          )
        })
      },
    })

    if(result.isConfirmed){
      fetchSchedulesMsgList()
      showSuccessPopUp('Deleted Successfully !')
    }
    //console.log('result:',result)
  }

  function getStauts(status){
    if(status == 0){
      return <Badge className="badge-soft-info">Yet to Process</Badge>
    }
    if(status == 1){
      return <Badge className="badge-soft-warning">In Progress</Badge>
    }
    if(status == 2){
      return <Badge className="badge-soft-success">All Sent</Badge>
    }
    if(status == 3){
      return <Badge className="badge-soft-danger">Deleted</Badge>
    }
    return ""
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col md="4" sm="12">
            {error ? <Alert color="danger">{error}</Alert> :
              
              <div className="ps-2">
                <div className="fw-bold">All Messages</div>
                <div className="font-size-12">Click on each to view the details</div>
              </div>
              }
              <SimpleBar style={{ maxHeight: 500 }}>
                {messageList.map((msg, i)=>(
                  <div key={i}>
                    <Card className={currentMsg==msg.id ?"m-1 bg-light" : "m-1 pointer-cursor-with-hover"} onClick={()=>getAllContacts(msg.id)}>
                      <CardBody>
                        <div>
                          <span>
                            {getFormattedDate(msg.inserted_time,'DD-MMM-YY HH:mm')}
                          </span>
                          <span className="ms-2">
                            {getStauts(msg.current_status)}
                          </span>
                          <span>
                          {(msg.current_status == 0 || msg.current_status == 1) && <Link
                                to="#"
                                className="ms-2 btn btn-sm btn-soft-danger"
                                onClick={(e) => {
                                    e.preventDefault();
                                    handleDeleteWhatsAppMsg(msg.id);
                                }}
                            >
                                <i className="mdi mdi-delete-outline" id="deletetooltipwp" />
                            </Link>
                            }
                          </span>
                        </div>
                        <div className="c-blockquote">
                          {JSON.parse(msg.message).message}
                        </div>
                        <div className="mt-2">
                          {JSON.parse(msg.message).attachments.map((each,i)=>(
                            (each?.type?.indexOf('image') > -1  ? 
                            <img height="50" src={each.base64} alt="" key={i}/>
                            :
                            <span key={i}>File : {each.name}</span>
                            )
                          ))}
                        </div>
                      </CardBody>
                    </Card>
                  </div>
                ))}
              </SimpleBar>
            </Col>
            <Col md="8" sm="12">
              {clickedOnce &&<Card>
                <CardBody>
                  {error ? <Alert color="danger">{error}</Alert> : null}
                    <TableContainer
                        columns={columns}
                        data={allContactList}
                        isGlobalFilter={true}
                        isAddOptions={false}
                        isJobListGlobalFilter={false}
                        customPageSize={30}
                        isShowDownLoad={false}
                        isLoading={showLoading}
                    />
                </CardBody>
            </Card>
            }
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

WhatsAppScheduledMsgList.propTypes = {
}

export default withRouter(WhatsAppScheduledMsgList);