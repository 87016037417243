import React, { useState, useEffect, useRef, useMemo } from "react"
import {
  Card,
  Col,
  Container,
  Row,
  Label,
  Input,
  UncontrolledTooltip,
  Button,
  CardBody,
  Modal, 
  ModalBody 
} from "reactstrap"
import { Link } from 'react-router-dom';
import { useNavigate } from "react-router-dom"
import withRouter from "components/Common/withRouter";
import { retrieveDetails, processWP, saveDetails } from "helpers/backend_helper"
import { panchayats, wards } from "common/data/dropdowns"
import { getFormattedDate, showSuccessPopUp, showCloseableToast, isNumber, getBase64StringFromDataURL } from "helpers/function_helper"
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { v4 as uuidv4 } from 'uuid';
import QRCode from "react-qr-code";
import RegistrantsDropDown from "components/Common/RegistrantsDropDown"
import PieSocket from 'piesocket-js';
import Select, { components } from "react-select";
import { isEmpty } from "lodash";
import Loader from "components/Common/Loader"
import FileBase64 from 'react-file-base64';

const MySwal = withReactContent(Swal)

const uuidName = 'uuid-bin-scheduler-1206'
const uuidLocalStorageName = 'bin-scheduler-uuid'

var pieSocket = new PieSocket({
  clusterId: "free.nyc1",
  apiKey: "WKfYemehehooplGk7aVA5YG6Csw0q7qefaF5jyzh",
  notifySelf: false
});


const InitialiseWhatsAppScheduler = props => {

  const navigate = useNavigate();
  const [qrResponse, setQrResponse] = useState('')
  const [currentUUID, setCurrentUUID] = useState('')
  const [currentStatus, setCurrentStatus] = useState('Checking...')
  const [showPleaseWait, setShowPleaseWait] = useState(false)
  const [show, setShow] = useState(false)
  const [showLoading, setShowLoading] = useState(false)
  const [sendInProgress, setSendInProgress] = useState(false)

  const [selectedCategory, setSelectedCategory] = useState()
  const [selectedPanchayath, setSelectedPanchayath] = useState()
  const [selectedWard, setSelectedWard] = useState()
  const [selectedNos, setSelectedNos] = useState()
  const [messageToSend, setMessageToSend] = useState()
  const [contactNo, setContactNo] = useState()
  const [panchyathWards, setPanchyathWards] = useState([])
  const [whatsAppNos, setWhatsAppNos] = useState([])
  const [attachments, setAttachments] = useState([])
  const [fileKey, setFileKey] = useState(Math.random().toString(36))

  const allCategories = [{
    value: 'byPanchayath',
    label: 'By Panchayath'
  },{
    value: 'byWards',
    label: 'By Wards'
  },{
    value: 'byContacts',
    label: 'For Selected Contacts'
  },{
    value: 'byNumber',
    label: 'By Number'
  }]

  
  const initWP=async(uuid)=>{

    setShowPleaseWait(true)

    const inputToSend = {
      "data" : {
        "type" : "INIT",
        "key": uuid
      }
    }
    const response = await processWP(inputToSend);
    if(response && response.status === 'success' && response.data && response.data == 'connected'){
      setShowPleaseWait(false)
      setCurrentStatus('Connected')
      showCloseableToast('Connected Successfully','top-center','info')
    }
  }

  useEffect(()=>{
    let uuid = ""
    if (localStorage.getItem(uuidLocalStorageName) != undefined && localStorage.getItem(uuidLocalStorageName) != null && localStorage.getItem(uuidLocalStorageName) != '') {
      uuid = localStorage.getItem(uuidLocalStorageName)
    }
    else{
      uuid = uuidName//uuidv4()
      localStorage.setItem(uuidLocalStorageName,uuid)
    }
    console.log('final:uu:',uuid)
    setCurrentUUID(uuid)
  },[])


useEffect(()=>{
  console.log('currentUUID:',currentUUID)
  if(currentUUID && currentUUID != ''){
    pieSocket.subscribe(currentUUID).then((channel)=>{
      console.log("Channel is ready");
      channel.listen("qr_code", (data, meta)=>{
          console.log("New message: ", data);
          setQrResponse(data)
          setShow(true)
      });

      channel.listen("client_ready", (data, meta)=>{
        console.log("Client Ready: ", data);
        setQrResponse('')
        setShowPleaseWait(false)
        setShow(false)
        setCurrentStatus('Connected')
      });
    });
    initWP(currentUUID)
  }
},[currentUUID])


const mandatoryFields = ['selectedNos','messageToSend']
const [validation, setValidation] = useState({
  selectedNos: null,
  messageToSend: null,
  selectedCategory: null,
  selectedPanchayath: null,
  selectedWard: null
})

const doLogout=async()=>{
  const inputToSend = {
    "data" : {
      "type" : "LOGOUT",
      "key": currentUUID
    }
  }
  const response = await processWP(inputToSend);
  if(response && response.status === 'success'){
    
  }
  else if(response && response.error && response.error.code && response.error.code == 'E1001'){
    showCloseableToast('No valid session. Please initialise','top-center','error')
  }
}

const onCloseClick=()=>{
  setShow(!show)
}

const onSelectRegDD=(values)=>{
  setSelectedNos(values)
  onChangeValidation('selectedNos',values)
  const cNoArr = []
    for(let register of values){
        if(register && register.whatsapp_no && register.whatsapp_no != ''){
          cNoArr.push({
            'number': register.whatsapp_no
          })
        }
    }
    setWhatsAppNos(cNoArr)
}

const onChangeValidation = (fieldName, value) => {
  const modifiedV = { ...validation }

  if (value !== "") {
    modifiedV[fieldName] = true
  } else {
    modifiedV[fieldName] = false
  }
  setValidation(modifiedV)
}

const validateForm=()=>{
  const modifiedV = { ...validation }
  let isValid = true
  if(isEmpty(selectedCategory)){
    modifiedV['selectedCategory'] = false
    isValid = false
  }
  else{
    modifiedV['selectedCategory'] = true

    if(selectedCategory.value == 'byPanchayath'){
      if(isEmpty(selectedPanchayath)){
        modifiedV['selectedPanchayath'] = false
        isValid = false
      }
      else{
        modifiedV['selectedPanchayath'] = true
      }
    }
    else if(selectedCategory.value == 'byWards'){
      if(isEmpty(selectedPanchayath)){
        modifiedV['selectedPanchayath'] = false
        isValid = false
      }
      else{
        modifiedV['selectedPanchayath'] = true
      }

      if(isEmpty(selectedWard)){
        modifiedV['selectedWard'] = false
        isValid = false
      }
      else{
        modifiedV['selectedWard'] = true
      }
    }
    else if(selectedCategory.value == 'byContacts'){
      if(isEmpty(selectedNos)){
        modifiedV['selectedNos'] = false
        isValid = false
      }
      else{
        modifiedV['selectedNos'] = true
      }
    }
    else if(selectedCategory.value == 'byNumber'){
      if(isEmpty(contactNo)){
        modifiedV['contactNo'] = false
        isValid = false
      }
      else{
        modifiedV['contactNo'] = true
      }
    }
  }
  if(isEmpty(messageToSend) && isEmpty(attachments)){
    modifiedV['messageToSend'] = false
    isValid = false
  }
  else{
    modifiedV['messageToSend'] = true
  }
  
  setValidation(modifiedV)

  return isValid
}

const searchRegistration=async(key, value)=>{
  let inputToSend = null
  if(key=='byPanchayath'){
    inputToSend = {
      "data" : {
        "type" : "SEARCH_REG",
        "register" :{
          'panchayath':value
        }
      }
    }
  }
  else if(key=='byWard'){
    inputToSend = {
      "data" : {
        "type" : "SEARCH_REG",
        "register" :{
          'panchayath':selectedPanchayath.value,
          'wardNo':value,
        }
      }
    }
  }
  setShowLoading(true)
  const response = await retrieveDetails(inputToSend);
  if(response && response.status === 'success'){
    processAllContacts(response.data)
  }
  else if(response && response.status === 'failure'){
    showCloseableToast('Unable to fetch details','top-center','error')
  }
  setShowLoading(false)
}

const processManualContacts=(entry)=>{
  let cNos = entry.split(",")
    const cNoArr = []
    for(let cNo of cNos){
      if(!isEmpty(cNo) && isNumber(cNo)){
        cNoArr.push({
          'number': cNo
        })
      }
    }
    setWhatsAppNos(cNoArr)
}

const processAllContacts=(data)=>{
  const cNoArr = []
    for(let register of data){
        if(register && register.whatsapp_no && register.whatsapp_no != ''){
          cNoArr.push({
            'number': register.whatsapp_no
          })
        }
    }
    setWhatsAppNos(cNoArr)
}

const handleSendWhatsApp=async()=>{
  if(validateForm()){
    const result  = await MySwal.fire({
      title: `Are you sure to send message to ${whatsAppNos.length} contact with ${attachments.length} attachments ?` ,
      text: "Pleae confirm",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Send',
      showLoaderOnConfirm:true,
      allowOutsideClick: () => !MySwal.isLoading(),
      preConfirm: (sme) => {
        setSendInProgress(true)
        return sendWhatsAppMsg()
        .then(response => {
          //console.log('response:',response)
          setSendInProgress(false)
          if (response.status != 'success') {
            if(response.error && response.error.code && response.error.code == 'E1001'){
              throw new Error('No Authentication Exists, please check whatsapp status')
            }
            else{
              throw new Error(response.statusText)
            }
          }
          return response
        })
        .catch(error => {
          MySwal.showValidationMessage(
            `Request failed: ${error}`
          )
        })
      },
    })

    if(result.isConfirmed){
      showSuccessPopUp('Message Send Successfully !')
    }
  }
  //console.log('result:',result)
}

const sendWhatsAppMsg=async()=>{
  
    let userId = ''
    if (localStorage.getItem("authUser")) {
      const obj = JSON.parse(localStorage.getItem("authUser"));
      userId = obj.id
    }
    const updatedMsgToSend = {
      contactNos: whatsAppNos,
      message: messageToSend,
      type: 'chat',
      "userId": ''+userId,
      attachments: attachments
    }

    //setSendingMessage(true)
    const inputToSend = {
      "data" : {
        "type" : "MSGS",
        "key": currentUUID,
        "message" : updatedMsgToSend
      }
    }
    return await processWP(inputToSend);
    /*
    if(response && response.status === 'success'){
      showCloseableToast('Message Send Successfully','top-center','success')
    }
    else if(response && response.error && response.error.code && response.error.code == 'E1001'){
      showCloseableToast('No valid session. Please initialise','top-center','error')
    }
    */
  
}

const handleFileUpload=(files)=>{
  setAttachments(files)
}

const resetTheFileInput=()=>{
  setFileKey(Math.random().toString(36))
  setAttachments([])
}

async function scheduleWhatsAppMsg(){
  setShowLoading(true)
  let formValid = validateForm()
  if(formValid){
    
    let userId = ''
    if (localStorage.getItem("authUser")) {
      const obj = JSON.parse(localStorage.getItem("authUser"));
      userId = obj.id
    }
    const updatedMsgToSend = {
      contactNos: whatsAppNos,
      message: messageToSend,
      type: 'chat',
      "userId": ''+userId,
      attachments: attachments
    }

    //setSendingMessage(true)
    const inputToSend = {
      "data" : {
        "type" : "SCHEDULE_WP",
        "key": currentUUID,
        "message" : updatedMsgToSend
      }
    }
    const response = await saveDetails(inputToSend);

    setShowLoading(false)
    if(response && response.status === 'failure'){
      showCloseableToast('Unable to Scedule Message','top-center','error')
    }
    else if(response && response.status === 'success'){
      showCloseableToast('WhatsApp message scheduled successfully','top-center','success')
    }
  }
}

  return (
    <React.Fragment>
      <Loader showLoader={showLoading} />
      <div className="page-content">
        <Container fluid>
          <Row>
              <Col lg="12">
                  <Card>
                      <CardBody>
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="center">
                            Status : {currentStatus}
                          </div>

                          {currentStatus == 'Connected' && <Button
                            color="danger"
                            className="btn w-lg btn-danger btn-block"
                            onClick={() => {
                              doLogout()
                            }}
                            >
                              LOGOUT FROM WHATSAPP
                          </Button>
                          }
                        </div>

                      </CardBody>
                  </Card>
              </Col>
          </Row>
          {showPleaseWait && <div id="detail-tocapture">
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <div className="text-center my-3">
                      <div className="text-success">
                        <i className="bx bx-loader bx-spin font-size-18 align-middle me-2" />
                        Checking Status... Please wait... 
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
          }
          {
          <Row>
            <Col lg="12">
              <Card>
                <CardBody style={{minHeight:"500px"}}>
                  <div className="d-flex justify-content-center align-items-center fw-bold bg-light py-1">
                    Send WhatsApp Message
                  </div>
                  <Row className="mt-3">
                    <Col sm="12" md="5">
                      <div className="mb-3">
                        <Label for="selectedCategory">
                          Select
                        </Label>
                        <Select
                          onChange={(e) => {
                            setSelectedCategory(e)
                            onChangeValidation('selectedCategory',e.value)
                            setPanchyathWards(null)
                            setSelectedPanchayath(null)
                            setSelectedWard(null)
                            setWhatsAppNos([])
                          }}
                          options={allCategories}
                          classNamePrefix="select2-selection"
                          isLoading={false}
                          id={"selectedCategory"}
                          value={selectedCategory}
                          styles={{menuPortal:base => ({...base, zIndex:9999})}}
                        />
                        {(validation['selectedCategory'] === false) &&
                          <div className="custom-error">
                            Please select the Category
                          </div>
                        }
                      </div>
                      {selectedCategory 
                      && selectedCategory.value 
                      && (selectedCategory.value == 'byPanchayath' || selectedCategory.value == 'byWards') &&
                      <div className="mb-3">
                        <Label for="selectedPanchayath">
                          Select Panchayath
                        </Label>
                        <Select
                          onChange={(e) => {
                            setSelectedPanchayath(e)
                            onChangeValidation('selectedPanchayath',e.value)
                            const tempWards = wards[e.value]
                            setPanchyathWards(tempWards)
                            if(selectedCategory.value == 'byPanchayath'){
                              searchRegistration('byPanchayath',e.value)
                            }
                            setSelectedWard(null)
                            setWhatsAppNos([])
                          }}
                          options={panchayats}
                          classNamePrefix="select2-selection"
                          isLoading={false}
                          id={"selectedPanchayath"}
                          value={selectedPanchayath}
                          styles={{menuPortal:base => ({...base, zIndex:9999})}}
                        />
                        {(validation['selectedPanchayath'] === false) &&
                          <div className="custom-error">
                            Please select the Panchayath
                          </div>
                        }
                      </div>
                      }
                      {selectedCategory 
                      && selectedCategory.value 
                      && selectedCategory.value == 'byWards' &&
                      <div className="mb-3">
                        <Label for="selectedWard">
                          Select Ward
                        </Label>
                        <Select
                          onChange={(e) => {
                            setSelectedWard(e)
                            onChangeValidation('selectedWard',e.value)
                            searchRegistration('byWard',e.value)
                          }}
                          options={panchyathWards}
                          classNamePrefix="select2-selection"
                          isLoading={false}
                          id={"selectedWard"}
                          value={selectedWard}
                          styles={{menuPortal:base => ({...base, zIndex:9999})}}
                        />
                        {(validation['selectedWard'] === false) &&
                          <div className="custom-error">
                            Please select the Ward
                          </div>
                        }
                      </div>
                      }
                      {selectedCategory 
                      && selectedCategory.value 
                      && selectedCategory.value == 'byContacts' &&
                      <div className="mb-3">
                        <Label for="selectedContacts">
                          Select All Contacts
                        </Label>
                        <RegistrantsDropDown selectOnChangeMulti={onSelectRegDD} isMulti={true}/>
                        {(validation['selectedNos'] === false) &&
                          <div className="custom-error">
                            Please select the Numbers
                          </div>
                        }
                      </div>
                      }
                      {whatsAppNos && whatsAppNos.length > 0 &&
                      <div className="bg-info bg-soft py-2 px-2 mt-4">
                        Total Number of Contacts : <span className="fw-bold">{whatsAppNos.length}</span>
                      </div>
                      }
                    </Col>
                    <Col sm="12" md="7">
                      <div className="mb-3">
                        <Label for="messageToSend">
                          Message
                        </Label>
                        <textarea
                          id="messageToSend"
                          className="form-control"
                          rows="10"
                          maxLength="2000"
                          value={messageToSend || ''}
                          onChange={e => {
                            setMessageToSend(e.target.value)
                            onChangeValidation('messageToSend',e.target.value)
                          }}
                        />
                        {(validation["messageToSend"] === false) &&
                          <div className="custom-error">
                            Please enter a valid Message
                          </div>
                        }
                      </div>
                      {selectedCategory 
                      && selectedCategory.value 
                      && selectedCategory.value == 'byNumber' &&
                      <div className="mb-3">
                          <Label for="contactNo">
                            Contact No.
                          </Label>
                          <Input
                            type="tel"
                            className="form-control"
                            id="contactNo"
                            maxLength="500"
                            value={contactNo || ''}
                            onChange={e => {
                                setContactNo(e.target.value)
                                onChangeValidation('contactNo',e.target.value)
                                processManualContacts(e.target.value)
                            }}
                          />
                          {(validation["contactNo"] === false) &&
                            <div className="custom-error">
                              Please enter a valid Contact Number
                            </div>
                          }
                        </div>
                      }
                      <div>
                      <FileBase64 key={fileKey} multiple={ true } onDone={(files)=>handleFileUpload(files) } />
                      {attachments.length > 0 && <span onClick={()=>resetTheFileInput()}><i className="pointer-cursor mdi mdi-delete text-danger font-size-18"/></span>}
                      </div>
                      <div className="mt-4">
                        {/** 
                        <Link to="#" onClick={()=> sendInProgress ? null : handleSendWhatsApp()} className="text-primary mt-3">
                          <Card className="p-2 drops-bg-shade-9">
                            <div className="text-center">
                              <i className="mdi mdi-whatsapp me-1" />
                              Send Message
                            </div>
                          </Card>
                        </Link>*/}
                        <Link to="#" onClick={()=> sendInProgress ? null : scheduleWhatsAppMsg()} className="text-primary mt-3">
                          <Card className="p-2 drops-bg-shade-8">
                            <div className="text-center">
                              <i className="mdi mdi-whatsapp me-1" />
                              Schedule Message
                            </div>
                          </Card>
                        </Link>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          }
        </Container>
      </div>
      <Modal size="md" isOpen={show} toggle={onCloseClick} centered={true}>
        <div className="modal-content">
          <ModalBody className="px-4 py-5 text-center">
            <p className="text-muted font-size-16 mb-4">Please scan the QR code and wait. It will take a while.</p>
            <QRCode size={300} level={"L"} value={qrResponse} />
          </ModalBody>
        </div>
      </Modal>
    </React.Fragment>
  )
}

InitialiseWhatsAppScheduler.propTypes = {
}

export default withRouter(InitialiseWhatsAppScheduler);