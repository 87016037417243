import PropTypes from "prop-types";
import React, {useState} from "react";
import { useNavigate } from "react-router-dom"
import { Row, Col, CardBody, Card, Alert, Container, Form, Input, FormFeedback, Label } from "reactstrap";
import Breadcrumb from "../../components/Common/Breadcrumb";
//redux
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

import { retrieveDetails } from "helpers/backend_helper"

import logoPNG from "assets/images/logo.png";

const CreateNewUser = props => {

  //meta title
  document.title = "New User | Drops";
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const [error, setError] = useState('')
  const [showLoading, setShowLoading] = useState(false)

  const validation = useFormik({
    // enableReinitialize : use this  flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: '',
      password: '',
      confirmPassword: '',
      mobileNo: '',
      userName: '',
      role: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please enter the Email"),
      password: Yup.string().required("Please enter the Password"),
      confirmPassword: Yup.string().required("Please re-enter the Password"),
      userName: Yup.string().required("Please enter the Name"),
      role: Yup.string().required("Please select the Role"),
    }),
    onSubmit: (values) => {
      //dispatch(loginUser(values, props.router.navigate));
      setError('')
      //doLogin(values)
    }
  });

  async function doLogin(values){
      setShowLoading(true)
      const inputToSend = {
        "data" : {
          "type" : "NEWUSER",
          "user" : values
        }
      }
      const response = await saveDetails(inputToSend);
      setShowLoading(false)
      if(response && response.status === 'failure'){
        setError(response.error.errorMsg)
      }
      else if(response && response.status === 'success' && response.data){
        //localStorage.setItem("authUser", JSON.stringify(response.data))
        //navigate('/dashboard');
      }
  }

  // const twitterResponse = e => {}

  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
        <Breadcrumb title="Admin" breadcrumbItem="Add New User" />
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <CardBody className="pt-2">
                  <div className="p-2">
                    <Form
                      className="form-horizontal"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      {error ? <Alert color="danger">{error}</Alert> : null}

                      <div className="mb-3">
                        <Label className="form-label">Name</Label>
                        <Input
                          id="name"
                          name="userName"
                          className="form-control"
                          placeholder="Enter Name"
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.userName || ""}
                          invalid={
                            validation.touched.userName && validation.errors.userName ? true : false
                          }
                        />
                        {validation.touched.userName && validation.errors.userName ? (
                          <FormFeedback type="invalid">{validation.errors.userName}</FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3">
                        <Label className="form-label">Email</Label>
                        <Input
                          name="email"
                          className="form-control"
                          placeholder="Enter email"
                          type="email"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ""}
                          invalid={
                            validation.touched.email && validation.errors.email ? true : false
                          }
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3">
                        <Label className="form-label">Mobile Number</Label>
                        <Input
                          name="mobileNo"
                          className="form-control"
                          placeholder="Enter Mobile No"
                          type="tel"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.mobileNo || ""}
                          invalid={
                            validation.touched.mobileNo && validation.errors.mobileNo ? true : false
                          }
                        />
                        {validation.touched.mobileNo && validation.errors.mobileNo ? (
                          <FormFeedback type="invalid">{validation.errors.mobileNo}</FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3">
                        <Label className="form-label">Password</Label>
                        <Input
                          name="password"
                          value={validation.values.password || ""}
                          type="password"
                          placeholder="Enter Password"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          invalid={
                            validation.touched.password && validation.errors.password ? true : false
                          }
                        />
                        {validation.touched.password && validation.errors.password ? (
                          <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                        ) : null}
                      </div>

                      <div className="mt-3 d-grid">
                        <button
                          className="btn btn-primary drops-bg-shade-9 btn-block"
                          type="submit"
                        >
                          Log In
                        </button>
                      </div>

                    </Form>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(CreateNewUser);

CreateNewUser.propTypes = {
  history: PropTypes.object,
};
