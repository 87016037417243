import React from "react"
import PropTypes from 'prop-types'
import { Link } from "react-router-dom"
import { Row, Col, Card, CardBody } from "reactstrap"

const DropsContactDetails = props => {
  return (
    <>
    <Row className="align-items-center justify-content-center bg-white mx-0 py-1 my-0">
    <Col className="text-center align-middle justify-content-center ">
      <span className="text-muted mb-0 me-2 text-wrap">
        +91 80 75 25 26 27 | dropsedakkara@gmail.com | www.dropscharity.com
      </span>
    </Col>
    </Row>
    </>
  )
}

DropsContactDetails.propTypes = {
}

export default DropsContactDetails
