import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import { Row, Container } from "reactstrap"
import FixedNavbar from "./Navbar/FixedNavbar"
import Footer from "./Footer/footer"
import withRouter from "components/Common/withRouter"

const RefundPolicy = props => {
  //meta title
  document.title = "Drops | ഒന്നായ് തണലേകാം"

  const [imglight, setimglight] = useState(false)
  const [navClass, setnavClass] = useState("nav-sticky")

  return (
    <React.Fragment>
      <FixedNavbar navClass={navClass} imglight={imglight} />
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <h1>DROPS Charitable and Welfare Society Refund Policy</h1>

            <p>
              DROPS Charitable and Welfare Society values the trust and support
              of our donors. We strive to ensure that your experience with us is
              positive and that your contributions are used effectively to
              support our cause. In certain circumstances, we understand that a
              donor may need to request a refund for their donation. This policy
              outlines the terms and conditions under which refunds will be
              made.
            </p>

            <h2>Types of Donations Accepted</h2>
            <p>One Time Donations</p>
            <p>Recurring Donations</p>

            <h2>Conditions for Refund</h2>

            <h3>Time Frame for Refund Requests:</h3>
            <p>
              Refund requests must be made within 30 days of the donation date.
            </p>

            <h3>Acceptable Reasons for Refund Requests:</h3>
            <p>
              <ul>
                <li>Errors in the amount donated.</li>
                <li>Duplicate donations due to technical issues.</li>
                <li>
                  Other genuine reasons as assessed by the society’s management.
                </li>
              </ul>
            </p>

            <h2>Method of Refund</h2>
            <p>
              Refunds will be processed using the same method by which the
              donation was made:
            </p>
            <ul>
              <li>Online Payment</li>
              <li>UPI</li>
              <li>Cash</li>
            </ul>

            <h2>Documentation Required for Refund</h2>
            <p>
              To process a refund request, the following documentation must be
              provided:
            </p>
            <ul>
              <li>Donation Receipt</li>
            </ul>

            <h2>Contact Information for Refund Requests</h2>
            <p>To request a refund, please contact us at:</p>
            <p>
              <strong>Mobile Number:</strong> 8075252627
            </p>

            <h2>Time Frame for Processing Refunds</h2>
            <p>
              All refund requests will be processed within 30 days from the date
              the request is received.
            </p>

            <h2>Non-Refundable Situations</h2>
            <p>There are no non-refundable situations outlined at this time.</p>

            <h2>Additional Notes</h2>
            <p>
              DROPS Charitable and Welfare Society reserves the right to amend
              this policy at any time. Refunds will be processed in the currency
              in which the donation was made.
            </p>

            <h2>How to Request a Refund</h2>
            <p>To request a refund, please follow these steps:</p>
            <ol>
              <li>Contact us at 8075252627 with your donation details.</li>
              <li>Provide the necessary documentation (donation receipt).</li>
              <li>Allow up to 30 days for the processing of your request.</li>
            </ol>

            <p>
              We appreciate your understanding and continued support. For any
              further questions or concerns, please do not hesitate to reach out
              to us.
            </p>
          </Row>
        </Container>
      </div>
      <Footer />
    </React.Fragment>
  )
}

export default withRouter(RefundPolicy)

RefundPolicy.propTypes = {
  history: PropTypes.object,
}
