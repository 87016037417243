import React from "react";
import { Container, Row, Col } from "reactstrap";
import PropTypes from 'prop-types';
import { withTranslation } from "react-i18next";
//Import images
import feature1 from "../../../assets/images/feature-1.jpg";
import feature2 from "../../../assets/images/feature-2.jpg";

const Features = (props) => {
  const features1 = [
    { id: 1, desc: "t-feature-1" },
    { id: 2, desc: "t-feature-2" },
    { id: 3, desc: "t-feature-3" },
    { id: 4, desc: "t-feature-4" },
    { id: 5, desc: "t-feature-5" },
  ];

  return (
    <React.Fragment>
      <section className="section drops-bg-shade-overlay-9 pt-4" id="features">
        <Container>
          <Row>
            <Col lg="12">
              <div className="text-center mb-2">
                <h4>Our Aim</h4>
              </div>
            </Col>
          </Row>

          <Row className="align-items-center pt-4">
            <Col md="5" sm="8">
              <div>
                <img
                  src={feature2}
                  alt=""
                  className="img-fluid mx-auto d-block"
                />
              </div>
            </Col>
            <Col md="6" className="ms-auto">
              <div className="mt-4 mt-md-auto">
                <div className="mx-2 font-size-16 text-justify">
                  <div className="">
                  {props.t("t-aim-1-p1")}{" "}<span className="font-size-17 fw-bold">{props.t("t-aim-1-p2")}.</span></div> 
                  <div className="mb-3 mt-3">{props.t("t-aim-2")}</div>
                  {props.t("t-aim-3")}
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="section  pt-4" id="features2">
        <Container>

          <Row className="align-items-center pt-4">
            
            <Col md="6" className="ms-auto ">
              <div className="mt-4 mt-md-auto mx-2">
                <p className="fw-bold font-size-16">{props.t("t-feature-heading")}</p>
                <div className="font-size-15 mt-4 mb-2">
                  {features1.map((feature, key) => (
                    <div key={key} className={"d-flex py-2"}>
                      <div><i className="mdi mdi-circle-medium text-success me-1" /></div>
                      <div>{props.t(feature.desc)}</div>
                    </div>
                  ))}
                </div>
              </div>
            </Col>
            <Col md="5" sm="8">
              <div>
                <img
                  src={feature1}
                  alt=""
                  className="img-fluid mx-auto d-block"
                />
              </div>
            </Col>
          </Row>

        </Container>
      </section>
    </React.Fragment>
  );
};

Features.propTypes = {
  t: PropTypes.any
};
export default withTranslation()(Features);