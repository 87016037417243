import React from 'react';
import { Badge } from 'reactstrap';
import { Link } from 'react-router-dom';
import { getFormattedDate } from "helpers/function_helper"

const BoldValue = (cell) => {
    return <Link to="#" className="text-body fw-bold">{cell.value ? cell.value : ''}</Link>
};

const MsgStatus = (cell) => {
    
    switch (cell.value) {
        case 2:
            return (
            <div>
                <Badge className="badge-soft-success">Sent</Badge>
                <span className='font-size-10 ms-1'>{getFormattedDate(cell.row.original.send_time, 'DD-MMM-YY HH:mm')}</span>
            </div>
            )
        case 1:
            return <Badge className="badge-soft-warning">In Progress</Badge>
        case 0:
            return <Badge className="badge-soft-info">Yet to Send</Badge>
        case 3:
            return <Badge className="badge-soft-danger">Deleted</Badge>
    };  
};

const MsgSendStatus = (cell) => {
    switch (cell.value) {
        case "SS":
            return <Badge className="badge-soft-success">Delivered</Badge>
        case "NV":
            return <Badge className="badge-soft-danger">Invalid</Badge>
        case "NR":
            return <Badge className="badge-soft-warning">Not Registered</Badge>
    };  
};

const NormalText = (cell) => {
    return cell.value ? cell.value : "";
};

const NormalDate = (cell) => {
    return cell.value ? getFormattedDate(cell.value,'DD-MMM') : "";
};

const ReferenceID = (cell) => {
    const regData = cell.row.original;
    return ''+regData.id + '' + regData.random_id;
};

const JobNo = (cell) => {
    return <Link to="#" className="text-body fw-bold">{cell.value ? cell.value : ''}</Link>
};

const JobTitle = (cell) => {
    return cell.value ? cell.value : "";
};

const CompanyName = (cell) => {
    return cell.value ? cell.value : "";
};

const Location = (cell) => {
    return cell.value ? cell.value : "";
};

const Experience = (cell) => {
    return cell.value ? cell.value : "";
};

const Position = (cell) => {
    return cell.value ? cell.value : "";
};

const Type = (cell) => {
    switch (cell.value) {
        case "Full Time":
            return <Badge className="badge-soft-success">Full Time</Badge>
        case "Part Time":
            return <Badge className="badge-soft-danger">Part Time</Badge>
        case "Freelance":
            return <Badge className="badge-soft-info">Freelance</Badge>
        case "Internship":
            return <Badge className="badge-soft-warning">Internship</Badge>
    };   
};

const PostedDate = (cell) => {
    return cell.value ? cell.value : "";
};

const LastDate = (cell) => {
    return cell.value ? cell.value : "";
};

const Status = (cell) => {
    switch(cell.value) {
        case "Active":
            return <Badge className="bg-success">Active</Badge>
        case "New":
            return <Badge className="bg-info">New</Badge>
        case "Close":
            return <Badge className="bg-danger">Close</Badge>
    }
};


export { MsgStatus, MsgSendStatus, ReferenceID, JobNo, JobTitle, CompanyName, Location, Experience, Position, Type, PostedDate, LastDate, Status, BoldValue, NormalText, NormalDate };