import React, { useState, useEffect, useRef } from "react"
import Select, { components } from "react-select";
import withRouter from "components/Common/withRouter";
import PropTypes from "prop-types";
import CreatableSelect from 'react-select/creatable';
import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  Input,
  Label,
  Row,
  Button,
  Alert,
  Modal
} from "reactstrap"
import { Link } from "react-router-dom"
import { useNavigate } from "react-router-dom"
import { useDispatch } from "react-redux";
import { saveDetails, retrieveDetails, processWP } from "helpers/backend_helper"
import { collDates, genders, modeOfPayments, panchayats, postOffices, subsDepMethod, wards } from "common/data/dropdowns"
import { validateAadhar, showCloseableToast } from "helpers/function_helper"
import EditEmployeeChanges from "./EditEmployeeChanges"
import Loader from "components/Common/Loader"
import DropsRegistrationDetails from "components/Common/DropsRegistrationDetails"
import DropsHeader from "components/Common/DropsHeader"
import DropsContactDetails from "components/Common/DropsContactDetails"
import bankImage from "../../assets/images/bank-image-small-woh.png";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { isEmpty } from "lodash";
//import html2canvas from 'html2canvas';
//import { jsPDF } from "jspdf";

const MySwal = withReactContent(Swal)

const RegistrationWithAttachments = props => {

  document.title = "Drops Registration form";

  const navigate = useNavigate();
  const dispatch = useDispatch()

  const scrollRef = useRef(null)

  const [showLoading, setShowLoading] = useState(false)
  const [downLoadInProgress, setDownLoadInProgress] = useState(false)
  const [showRegistrationSuccess, setShowRegistrationSuccess] = useState(false)
  const [registrationConfirmationId, setRegistrationConfirmationId] = useState('')
  const [error, setError] = useState('')
  const [newRegistration, setNewRegistration] = useState(true)
  const [retrieveRegistration, setRetrieveRegistration] = useState(false)
  const [refId, setRefId] = useState()
  const [isEdit, setIsEdit] = useState(false)
  const [modifiedFields, setModifiedFields] = useState([])
  const [showModal, setShowModal] = useState(false)

  const [selectedPanchayat, setSelectedPanchayat] = useState(null)
  const [selectedCollectionDate, setSelectedCollectionDate] = useState(null)
  const [selectedSubsDepositMethod, setSelectedSubsDepositMethod] = useState(null)
  const [selectedModeOfPayments, setSelectedModeOfPayments] = useState(null)
  const [selectedGender, setSelectedGender] = useState(null)
  const [selectedPO, setSelectedPO] = useState(null)
  const [poList, setPOList] = useState(postOffices)
  const [selectedWard, setSelectedWard] = useState(null)
  const [panchyathWards, setPanchyathWards] = useState([])
  const [previousEmpDetails, setPreviousEmpDetails] = useState()

  const mandatoryFields = ['name','houseName','postOffice','pinCode','panchayath','wardNo','mobileNo','whatsAppNo','monthlySubsAmount','collectionDate','subsDepositMethod','modeOfPayment','place','age','gender']
  const mandatoryRetFields = ['refId']

  const [empDetails, setEmpDetails] = useState(initialiseEmployeeDetails())

  useEffect(() => {
    const params = props.router.params;
    if (params && params.id) {
      retrieveRegistrationDetails(params.id)
      setRefId(params.id)
    }
  }, [])

  useEffect(()=>{
    if(window.location.pathname.indexOf("register") != -1){
      setNewRegistration(true)
      setRetrieveRegistration(false)
    }
    else if(window.location.pathname.indexOf("view-reg") != -1){
      setNewRegistration(false)
      setRetrieveRegistration(true)
    }
    else if(window.location.pathname.indexOf("edit-reg") != -1){
      setNewRegistration(false)
      setRetrieveRegistration(true)
    }
  },[window.location.pathname])

  const scrollToResults = () => {
    scrollRef.current?.scrollIntoView({ behavior: "smooth", block: "center" })
  }

  async function retrieveRegistrationDetails(id){
      initialiseForm()
      setError('')
      setShowLoading(true)
      const inputToSend = {
        "data" : {
          "type" : "RET_REG",
          "user" : {
            id:id.trim()
          }
        }
      }
      const response = await retrieveDetails(inputToSend);
      setShowLoading(false)
      if(response && response.status === 'failure'){
        setError(response.error.errorMsg)
      }
      else if(response && response.status === 'success' && response.data){
        //setEmpDetails(response.data)
        prefillEmpDetails(response.data)
        setIsEdit(true)
        setRegistrationConfirmationId(id)
        setRefId(id)
        //navigate('/dashboard');
      }
  }

  async function validateAndretrieveRegistrationDetails(){
    let formValid = validateRetrievalForm()
    if(formValid){
      retrieveRegistrationDetails(refId)
    }
  }

  function validateRetrievalForm(){

    const modifiedV = { ...retValidation }
    let formValid = true;
    if(refId && refId != undefined && refId.trim() != ""){
      modifiedV['refId'] = true
    }
    else{
      modifiedV['refId'] = false
      formValid = false
    }
    setRetValidation(modifiedV)
    return formValid
  }

  const prefillEmpDetails=(data)=>{
    const retData = {
      name: data.fullname,
      houseName: data.house_name,
      postOffice: data.post_office,
      pinCode: data.pin_code,
      panchayath: data.panchayath,
      otherpanchayath: data.otherpanchayath,
      wardNo:data.ward_no,
      mobileNo:data.mobile_no,
      whatsAppNo:data.whatsapp_no,
      aadharNo: data.aadhar_no,
      monthlySubsAmount:data.monthly_amount,
      collectionDate:data.collection_date,
      subsDepositMethod:data.deposit_method,
      modeOfPayment:data.mode_payment,
      recommendedPerson:data.recommended_person,
      place:data.place,
      age:data.age,
      gender:data.gender,
      recordStatus: data.record_status,
      randomId: data.random_id,
      id: data.id
    }
    setPreviousEmpDetails(retData)
    setEmpDetails(retData)

    const selPanchayath = panchayats.filter(each=>each.value == data.panchayath)
    if(selPanchayath && selPanchayath.length > 0){
      setSelectedPanchayat(selPanchayath[0])
    }

    const selColDate = collDates.filter(each=>each.value == data.collection_date)
    if(selColDate && selColDate.length > 0){
      setSelectedCollectionDate(selColDate[0])
    }

    const selDepMethod = subsDepMethod.filter(each=>each.value == data.deposit_method)
    if(selDepMethod && selDepMethod.length > 0){
      setSelectedSubsDepositMethod(selDepMethod[0])
    }

    const selModePayment = modeOfPayments.filter(each=>each.value == data.mode_payment)
    if(selModePayment && selModePayment.length > 0){
      setSelectedModeOfPayments(selModePayment[0])
    }

    const selGender = genders.filter(each=>each.value == data.gender)
    if(selGender && selGender.length > 0){
      setSelectedGender(selGender[0])
    }

    const selPostOffice = postOffices.filter(each=>each.value == data.post_office)
    if(selPostOffice && selPostOffice.length > 0){
      setSelectedPO(selPostOffice[0])
    }
    else{
      const newPO = {
        value: data.post_office,
        label: data.post_office,
      }
      postOffices.push(newPO)
      setSelectedPO(newPO)
    }

    if(wards[data.panchayath]){
      const tempWards = wards[data.panchayath]
      setPanchyathWards(tempWards)

      const selWards = tempWards.filter(each=>each.value == data.ward_no)
      if(selWards && selWards.length > 0){
        setSelectedWard(selWards[0])
      }
    }
    else{
      const tempWard = {
        value: data.ward_no,
        label: data.ward_no
      }
      panchyathWards.push(tempWard)
      setSelectedWard(tempWard)
    }

  }

  function initialiseEmployeeDetails(){
    return {
      name: "",
      houseName: "",
      postOffice: "",
      pinCode: "",
      panchayath: "",
      wardNo:"",
      mobileNo:"",
      aadharNo:"",
      whatsAppNo:"",
      monthlySubsAmount:"",
      collectionDate:"",
      subsDepositMethod:"",
      modeOfPayment:"",
      recommendedPerson:"",
      place:"",
      age:"",
      gender:"",
      otherpanchayath: "",
    }
  }

  const [validation, setValidation] = useState({
    name: null,
    houseName: null,
    postOffice: null,
    pinCode: null,
    panchayath: null,
    wardNo:null,
    mobileNo:null,
    whatsAppNo:null,
    aadharNo:null,
    monthlySubsAmount:null,
    collectionDate:null,
    subsDepositMethod:null,
    modeOfPayment:null,
    place:null,
    age:null,
    gender:null,
    otherpanchayath: null
  })

  const [retValidation, setRetValidation] = useState({
    refId: null
  })
  const onChangeRetValidation = (fieldName, value) => {
    const modifiedV = { ...retValidation }

    if(mandatoryRetFields.includes(fieldName)){
      
      if (value !== "") {
        modifiedV[fieldName] = true
      } else {
        modifiedV[fieldName] = false
      }
      setRetValidation(modifiedV)
    }
    setRetValidation(modifiedV)
  }

  const onChangeValidation = (fieldName, value) => {
    const modifiedV = { ...validation }

    if(mandatoryFields.includes(fieldName)){
      if (value !== "") {
        modifiedV[fieldName] = true
      } else {
        modifiedV[fieldName] = false
      }
      setValidation(modifiedV)
    }

    if(fieldName == 'aadharNo'){
      if(value != "" && !validateAadhar(value)){
        modifiedV[fieldName] = false
      }
      else{
        modifiedV[fieldName] = true
      }
    }
    
    setValidation(modifiedV)
  }

  async function addEmployee(){
    setError('')
    let formValid = validateForm()
    if(formValid){
      setShowLoading(true)

      const inputToSend = {
        "data" : {
          "type" : "REG_N_NOTIFY", //REGISTER
          "register" : empDetails,
          "key": 'uuid-8075252627-dps',
          "message" : {'caption':'DROPS registration confirmation'},
        }
      }
      //const response = await saveDetails(inputToSend);
      const response = await processWP(inputToSend);

      setShowLoading(false)
      scrollToResults()
      if(response && response.status === 'failure'){
        //setShowErrorPopUp(true)
        //setErrorMessage(response.errorResponse)
        setError(response.error.errorMsg)
      }
      else if(response && response.status === 'success'){
        //navigate('/registration-success');
        setRegistrationConfirmationId(response.data.id)
        setShowRegistrationSuccess(true)
        /*
        doSendRegistrationConfirmation({
          ...empDetails,
          refId: response.data.id
        })
        */
        
      }
    }
  }

  const confirmEditEmployee = async() => {
    setShowLoading(true)
    const oldValues = []
    const newValues = []
    modifiedFields.forEach(eachField => {
      oldValues.push(previousEmpDetails[eachField] ? previousEmpDetails[eachField] : '')
      newValues.push(empDetails[eachField] ? empDetails[eachField] : '')
    })
  
    //EMP_UPDATE
    const inputToSend = {
      "data" : {
        "type" : "UPD_REGISTER",
        "key" : ''+empDetails.id,
        "updatedData": {
          modifiedFields: modifiedFields,
          oldValues: oldValues,
          newValues: newValues
        }
      }
    }
    const resp = await saveDetails(inputToSend)
    setShowLoading(false)
    if(resp && resp.status == 'success'){
      setShowModal(false)
      setRegistrationConfirmationId(refId)
      setShowRegistrationSuccess(true)
      scrollToResults()
      //setModificationSuccessMsg('Details updated successfully!')
      //setShowModifySuccessPopUp(true)
    }
    else{
      setShowModal(false)
      setError(resp.error.errorMsg)
    }
  }

  function validateForm(){

    const modifiedV = { ...validation }
    let formValid = true;
    mandatoryFields.forEach(eachItem =>{
      if(empDetails[eachItem] && empDetails[eachItem].trim() != ""){
        modifiedV[eachItem] = true
      }
      else{
        modifiedV[eachItem] = false
        formValid = false
      }
    })

    if(empDetails['aadharNo'] != "" && !validateAadhar(empDetails['aadharNo'])){
      modifiedV['aadharNo'] = false
    }
    else{
      modifiedV['aadharNo'] = true
    }

    if(empDetails && empDetails.panchayath && empDetails.panchayath == 'Others'){
      if(isEmpty(empDetails.otherpanchayath)){
        modifiedV['otherpanchayath'] = false
        formValid = false
      }
      else{
        modifiedV['otherpanchayath'] = true
      }
    }

    if(empDetails && empDetails.monthlySubsAmount && Number(empDetails.monthlySubsAmount) < 100){
      modifiedV['monthlySubsAmount'] = false
      formValid = false
    }

    setValidation(modifiedV)
    
    return formValid
  }

  function isNumber(num){
    if(Number(num) || num == 0){
      return true;
    }
    else{
      return false;
    }
  }

const onCreateNewRegistration = () => {
    initialiseForm()
    setShowRegistrationSuccess(false)
    scrollToResults()
}

const onRetrieveRegistration=()=>{
  showRegistrationSuccess(false)
  setRetrieveRegistration(true)
}


function initialiseForm(){
  setRegistrationConfirmationId('')
  setSelectedPanchayat(null)
  setSelectedCollectionDate(null)
  setSelectedModeOfPayments(null)
  setSelectedGender(null)
  setSelectedPO(null)
  setSelectedWard(null)
  setSelectedSubsDepositMethod(null)
  setEmpDetails(initialiseEmployeeDetails())
}

const selectPanchayath=(data)=>{
  populateWardDD(data.panchayat)
  if(data && data.panchayat){
    const foundPanchayath = panchayats.filter(each=>each.value == data.panchayat)
    if(foundPanchayath && foundPanchayath.length > 0){
      setSelectedPanchayat(foundPanchayath[0])
      setEmpDetails({
        ...empDetails,
        panchayath : foundPanchayath[0].value,
        postOffice : data.value,
        pinCode: data.po || ''
      })
      onChangeValidation("panchayath", foundPanchayath[0].value)
    }
  }
  else{
    const otherPanchayath = {
      value:'Others',
      label: 'Other Pachayaths (മറ്റു പഞ്ചായത്തുകൾ)'
    }
    setSelectedPanchayat(otherPanchayath)
    setEmpDetails({
      ...empDetails,
      panchayath : otherPanchayath.value,
      postOffice : data.value,
      pinCode: data.po || ''
    })
    onChangeValidation("panchayath", otherPanchayath.value)
  }
}

const populateWardDD=(panchayath)=>{
  setSelectedWard(null)
  setEmpDetails({
    ...empDetails,
    wardNo:'',
    panchayath : panchayath
  })
  if(wards[panchayath]){
    setPanchyathWards(wards[panchayath])
  }
  else{
    setPanchyathWards([])
  }
}

function updateEmployee(){
  let formValid = validateForm()
  if(formValid){
    const changes = findChanges()
    if(changes.length == 0){
      showAlertPopUp('Sorry, there are no changes to update.')
    }
    else{
      setModifiedFields(changes)
      setShowModal(true)
    }
  }
}

function findChanges(){
  const changedKeys = []
  Object.keys(empDetails)
  .forEach(eachKey => {
    if(empDetails[eachKey] !== previousEmpDetails[eachKey]){
      changedKeys.push(eachKey)
    }
  })
  return changedKeys;
}

function toggleModal() {
  setShowModal(!showModal);
  removeBodyCss();
}

function removeBodyCss() {
  document.body.classList.add("no_padding");
}

function showAlertPopUp(msg){
  MySwal.fire({
    icon: 'error',
    title: 'Oops...',
    text: msg,
  })
}

const doSendRegistrationConfirmation=async(data)=>{
  const inputToSend = {
    "data" : {
      "type" : "SEND_REG_CONF_WP",
      "key": 'uuid-8075252627-dps',
      "message" : {'caption':'DROPS registration confirmation'},
      "register" : (data)
    }
  }
  const response = await processWP(inputToSend);
  if(response && response.status === 'success'){
    //showCloseableToast('Send Successfully','top-center','success')
  }
  else if(response && response.error && response.error.code && response.error.code == 'E1001'){
    //showCloseableToast('No valid session. Please initialise','top-center','error')
  }
}

const reEditSame=()=>{
  //setIsEdit(true)
  setShowRegistrationSuccess(false)
  retrieveRegistrationDetails(registrationConfirmationId)
}

const downloadReceipt=async()=>{

  setDownLoadInProgress(true)
  const inputToSend = {
    "data" : {
      "type" : "DOWNLOAD_REG",
      "register" : {
        ...empDetails,
        refId : registrationConfirmationId,
        monthlySubsAmount: Number(empDetails.monthlySubsAmount).toLocaleString('en-IN',{minimumFractionDigits: 2})
      }
    }
  }
  const response = await processWP(inputToSend);
  
  if(response && response.status === 'failure'){
    //setError(response.error.errorMsg)
    showCloseableToast('Sorry, unable to process.','top-center','error')
  }
  else if(response && response.status === 'success' && response.data){
    downloadPDF(response.data, registrationConfirmationId)
  }
  function downloadPDF(pdf, id) {
    //const linkSource = `data:application/pdf;base64,${pdf}`;
    const downloadLink = document.createElement("a");
    const fileName = "drops_registration_confirmation_"+id+".pdf";
    downloadLink.href = pdf;
    downloadLink.download = fileName;
    downloadLink.click();
  }

  setDownLoadInProgress(false)
  /*
  const input = document.getElementById('div-to-download');
  const canvas = await html2canvas(input, {
    scale:2,
    dpi: 144,
    })

  const imgData = canvas.toDataURL('image/png');
  const pdf = new jsPDF('p', 'pt','a4',true);
  pdf.addImage(imgData, 'PNG', -5, 0, ((canvas.width/2)*.5), ((canvas.height/2)*.5),'','FAST');
  pdf.save("drops-registration-confirmation-"+registrationConfirmationId+".pdf");
  */
  /*
  let dataURL = pdf.output('datauristring')

  console.log('dataURL:',dataURL)
  const base64String = getBase64StringFromDataURL(dataURL)
  console.log('base64String:',base64String)
  
  await doSendAttachment({
    type:'pdf',
    contactNo : messageToSend.contactNo,
    message : base64String,
  })
  */
}

  return (
    <React.Fragment>
      <div className="">
        <Loader showLoader={showLoading} />
        <Container fluid={false}>
          <DropsHeader/>
          <DropsRegistrationDetails/>
          
          <div ref={scrollRef}></div>

          {error ? <Alert color="danger" className="text-center mb-1">{error}</Alert> : null}

          {retrieveRegistration && !isEdit &&
          <Row className="justify-content-center">
              <Col xl={5} xs={12} md={9}>
                  <Card>
                      <CardBody>
                        <Row className="bg-light m-0 py-2 mb-1 mt-0">
                          <div className="text-center fw-bold font-size-15">
                            Retrieve Registration
                          </div>
                        </Row>
                        <div className="wizard clearfix">
                          <div className=" clearfix mt-2 mb-2">
                            <Form>
                              <Row>
                                <Col md="12" sm="12">
                                  <div className="mb-3">
                                    <Label for="refId">
                                      Confirmation No *
                                    </Label>
                                    <Input
                                      type="text"
                                      className="form-control"
                                      id="refId"
                                      maxLength="10"
                                      value={refId || ''}
                                      onChange={e => {
                                        setRefId(e.target.value)
                                        onChangeRetValidation("refId", e.target.value.trim())
                                      }}
                                    />
                                    {(retValidation["refId"] === false) &&
                                      <div className="custom-error">
                                        Please enter the Confirmation Number
                                      </div>
                                    }
                                  </div>
                                </Col>
                              </Row>
                            </Form>
                          </div>
                          <div className="text-center clearfix mb-3">
                            <Button
                              color="secondary"
                              className="btn w-lg btn-secondary me-2"
                              onClick={() => {
                                navigate('/reg-home');
                              }}
                              >
                                Home
                            </Button>
                            <Button
                              color="success"
                              className="btn w-lg btn-success drops-bg-shade-9 btn-block"
                              onClick={() => {
                                validateAndretrieveRegistrationDetails()
                              }}
                              >
                                Retrieve
                            </Button>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
              </Col>
          </Row>
          }

          {!showRegistrationSuccess && (newRegistration || isEdit) &&
          <>
            <Row className="bg-light m-0 py-2 mb-1 mt-0">
              <div className="text-center fw-bold font-size-15">
                {isEdit ? "Edit Registration Form" : "Registration Form"}
              </div>
            </Row>
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody className="p-0">
                    <div className="wizard clearfix">
                      <div className="content clearfix mt-2">
                        <Form>
                          <Row>
                            <Col md="4" sm="12">
                              <div className="mb-3">
                                <Label for="name">
                                  Full Name (പേര്)*
                                </Label>
                                <Input
                                  type="text"
                                  className="form-control"
                                  id="name"
                                  maxLength="80"
                                  value={empDetails.name || ''}
                                  onChange={e => {
                                    setEmpDetails({
                                      ...empDetails,
                                      name : e.target.value
                                    })
                                    onChangeValidation("name", e.target.value.trim())
                                  }}
                                />
                                {(validation["name"] === false) &&
                                  <div className="custom-error">
                                    Please enter the Name
                                  </div>
                                }
                              </div>
                            </Col>
                            <Col md="4" sm="12">
                              <div className="mb-3">
                                <Label for="houseName">
                                  House Name (വീട്ടു പേര്) *
                                </Label>
                                <Input
                                  type="text"
                                  className="form-control"
                                  id="houseName"
                                  maxLength="100"
                                  value={empDetails.houseName || ''}
                                  onChange={e => {
                                    setEmpDetails({
                                      ...empDetails,
                                      houseName : e.target.value
                                    })
                                    onChangeValidation("houseName", e.target.value.trim())
                                  }}
                                />
                                {(validation["houseName"] === false) &&
                                  <div className="custom-error">
                                    Please enter the House Name
                                  </div>
                                }
                              </div>
                            </Col>
                            <Col md="4" sm="12">
                              <div className="mb-3">
                                <Label for="age">
                                  Age (വയസ്സ്) *
                                </Label>
                                <Input
                                  type="tel"
                                  className="form-control"
                                  id="age"
                                  maxLength="3"
                                  value={empDetails.age || ''}
                                  onChange={e => {
                                    if(isNumber(e.target.value)){
                                      setEmpDetails({
                                        ...empDetails,
                                        age : e.target.value
                                      })
                                      onChangeValidation("age", e.target.value.trim())
                                    }
                                  }}
                                />
                                {(validation["age"] === false) &&
                                  <div className="custom-error">
                                    Please enter the Age
                                  </div>
                                }
                              </div>
                            </Col>
                          </Row>

                          <Row>
                            <Col md="4" sm="12">
                              <div className="mb-3">
                                <Label for="postOffice">
                                  Post Office (പോസ്റ്റ് ഓഫീസ്) * [സെലക്ട് ചെയ്യുക / ടൈപ്പ് ചെയ്യുക]
                                </Label>
                                <CreatableSelect
                                  value={selectedPO}
                                  formatCreateLabel={(inputText) => `"${inputText}"`}
                                  onChange={(e) => {
                                    setEmpDetails({
                                      ...empDetails,
                                      postOffice : e.value,
                                      pinCode: e.po || ''
                                    })
                                    onChangeValidation("postOffice", e.value)
                                    setSelectedPO(e)
                                    //selectPanchayath(e)
                                  }}
                                  options={poList}
                                  classNamePrefix="select2-selection"
                                  id="postOffice"
                                  onCreateOption={(e) => {
                                    setPOList([{value:e, label: e},...poList])
                                    setEmpDetails({
                                      ...empDetails,
                                      postOffice : e,
                                      pinCode: ''
                                    })
                                    onChangeValidation("postOffice", e)
                                    setSelectedPO({value:e, label: e})
                                    //selectPanchayath(e)
                                  }}
                                />
                                {(validation["postOffice"] === false) &&
                                  <div className="custom-error">
                                    Please enter the Post Office
                                  </div>
                                }
                              </div>
                            </Col>
                            <Col md="4" sm="12">
                              <div className="mb-3">
                                <Label for="pinCode">
                                PIN Code (പിൻകോഡ്) *
                                </Label>
                                <Input
                                  type="tel"
                                  className="form-control"
                                  id="pinCode"
                                  maxLength="6"
                                  value={empDetails.pinCode || ''}
                                  onChange={e => {
                                    if(isNumber(e.target.value)){
                                      setEmpDetails({
                                        ...empDetails,
                                        pinCode : e.target.value
                                      })
                                      onChangeValidation("pinCode", e.target.value.trim())
                                    }
                                  }}
                                />
                                {(validation["pinCode"] === false) &&
                                  <div className="custom-error">
                                    Please enter the Post Code
                                  </div>
                                }
                              </div>
                            </Col>
                            <Col md="4" sm="12">
                              <div className="mb-3">
                                <Label for="place">
                                  Place (സ്ഥലം) *
                                </Label>
                                <Input
                                  type="text"
                                  className="form-control"
                                  id="place"
                                  maxLength="50"
                                  value={empDetails.place || ''}
                                  onChange={e => {
                                      setEmpDetails({
                                        ...empDetails,
                                        place : e.target.value
                                      })
                                      onChangeValidation("place", e.target.value.trim())
                                  }}
                                />
                                {(validation["place"] === false) &&
                                  <div className="custom-error">
                                    Please enter the Place
                                  </div>
                                }
                              </div>
                            </Col>
                          </Row>
                            <Row>
                            <Col md="4" sm="12">
                              <div className="mb-3">
                                <Label for="panchayath">
                                  Panchayath (പഞ്ചായത്ത്) *
                                </Label>
                                <Select
                                  onChange={(e) => {
                                    setSelectedPanchayat(e)
                                    setEmpDetails({
                                      ...empDetails,
                                      panchayath : e.value
                                    })
                                    onChangeValidation("panchayath", e.value)
                                    populateWardDD(e.value)
                                  }}
                                  options={panchayats}
                                  classNamePrefix="select2-selection"
                                  isLoading={false}
                                  id={"panchayath"}
                                  value={selectedPanchayat}
                                />
                                {(validation["panchayath"] === false) &&
                                  <div className="custom-error">
                                    Please select the Panchayath
                                  </div>
                                }
                              </div>
                            </Col>
                            <Col md="4" sm="12">
                              <div className="mb-3">
                                <Label for="wardNo">
                                  Ward Number (വാർഡ് നമ്പർ)* [സെലക്ട് ചെയ്യുക / ടൈപ്പ് ചെയ്യുക]
                                </Label>
                                <CreatableSelect
                                  value={selectedWard}
                                  onChange={(e) => {
                                    setEmpDetails({
                                      ...empDetails,
                                      wardNo : e.value
                                    })
                                    onChangeValidation("wardNo", e.value)
                                    setSelectedWard(e)
                                  }}
                                  options={panchyathWards}
                                  classNamePrefix="select2-selection"
                                  id="wards"
                                  onCreateOption={(e) => {
                                    setPanchyathWards([{value:e, label: e},...panchyathWards])
                                    setEmpDetails({
                                      ...empDetails,
                                      wardNo : e
                                    })
                                    onChangeValidation("wardNo", e)
                                    setSelectedWard({value:e, label: e})
                                  }}
                                />
                                {(validation["wardNo"] === false) &&
                                  <div className="custom-error">
                                    Please enter the Ward Number
                                  </div>
                                }
                              </div>
                            </Col>
                            <Col md="4" sm="12">
                              <div className="mb-3">
                                <Label for="gender">
                                  Gender (ആൺ / പെൺ)*
                                </Label>
                                <Select
                                  onChange={(e) => {
                                    setSelectedGender(e)
                                    setEmpDetails({
                                      ...empDetails,
                                      gender : e.value
                                    })
                                    onChangeValidation("gender", e.value)
                                  }}
                                  options={genders}
                                  classNamePrefix="select2-selection"
                                  isLoading={false}
                                  id={"gender"}
                                  value={selectedGender}
                                />
                                {(validation["gender"] === false) &&
                                  <div className="custom-error">
                                    Please select the Gender
                                  </div>
                                }
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col md="4" sm="12">
                              <div className="mb-3">
                                <Label for="mobileNo">
                                  Mobile Number (മൊബൈൽ നമ്പർ) *
                                </Label>
                                <Input
                                  type="tel"
                                  className="form-control"
                                  id="mobileNo"
                                  maxLength="14"
                                  value={empDetails.mobileNo || ''}
                                  onChange={e => {
                                    if(isNumber(e.target.value)){
                                      setEmpDetails({
                                        ...empDetails,
                                        mobileNo : e.target.value
                                      })
                                      onChangeValidation("mobileNo", e.target.value.trim())
                                    }
                                  }}
                                />
                                {(validation["mobileNo"] === false) &&
                                  <div className="custom-error">
                                    Please enter a valid Mobile Number
                                  </div>
                                }
                              </div>
                            </Col>
                            <Col md="4" sm="12">
                              <div className="mb-3">
                                <Label for="whatsAppNo">
                                  Whatsapp Number (വാട്സ്ആപ്പ് നമ്പർ) *
                                </Label>
                                <Input
                                  type="tel"
                                  className="form-control"
                                  id="whatsAppNo"
                                  maxLength="14"
                                  value={empDetails.whatsAppNo || ''}
                                  onChange={e => {
                                    if(isNumber(e.target.value)){
                                      setEmpDetails({
                                        ...empDetails,
                                        whatsAppNo : e.target.value
                                      })
                                      onChangeValidation("whatsAppNo", e.target.value.trim())
                                    }
                                  }}
                                />
                                {(validation["whatsAppNo"] === false) &&
                                  <div className="custom-error">
                                    Please enter a valid WhatsApp Number
                                  </div>
                                }
                              </div>
                            </Col>
                            <Col md="4" sm="12">
                              <div className="mb-3">
                                <Label for="aadharNo">
                                  Aadhaar Number (ആധാർ നമ്പർ)
                                </Label>
                                <Input
                                  type="tel"
                                  className="form-control"
                                  id="aadharNo"
                                  maxLength="12"
                                  value={empDetails.aadharNo || ''}
                                  onChange={e => {
                                    if(isNumber(e.target.value)){
                                      setEmpDetails({
                                        ...empDetails,
                                        aadharNo : e.target.value
                                      })
                                      onChangeValidation("aadharNo", e.target.value.trim())
                                    }
                                  }}
                                />
                                {(validation["aadharNo"] === false) &&
                                  <div className="custom-error">
                                    Please enter a valid Aadhaar Number
                                  </div>
                                }
                              </div>
                            </Col>
                            
                          </Row>

                          <Row>
                            <Col md="4" sm="12">
                              <div className="mb-3">
                                <Label for="monthlySubsAmount">
                                  Monthly Donation (മാസ സംഭാവന) *
                                </Label>
                                <Input
                                  type="tel"
                                  className="form-control"
                                  id="monthlySubsAmount"
                                  maxLength="12"
                                  value={empDetails.monthlySubsAmount || ''}
                                  onChange={e => {
                                    if(isNumber(e.target.value)){
                                      setEmpDetails({
                                        ...empDetails,
                                        monthlySubsAmount : e.target.value
                                      })
                                      onChangeValidation("monthlySubsAmount", e.target.value.trim())
                                    }
                                  }}
                                />
                                {(validation["monthlySubsAmount"] === false) &&
                                  <div className="custom-error">
                                    Please enter a valid Monthly Donation
                                  </div>
                                }
                              </div>
                            </Col>
                            <Col md="4" sm="12">
                              <div className="mb-3">
                                <Label for="collectionDate">
                                  Collection Date (സംഭാവന നൽകാൻ കഴിയുന്ന തീയതി) *
                                </Label>
                                <Select
                                  onChange={(e) => {
                                    setSelectedCollectionDate(e)
                                    setEmpDetails({
                                      ...empDetails,
                                      collectionDate : e.value
                                    })
                                    onChangeValidation("collectionDate", e.value)
                                  }}
                                  options={collDates}
                                  classNamePrefix="select2-selection"
                                  isLoading={false}
                                  id={"collectionDate"}
                                  value={selectedCollectionDate}
                                />
                                {(validation["collectionDate"] === false) &&
                                  <div className="custom-error">
                                    Please enter a valid Collection Date
                                  </div>
                                }
                              </div>
                            </Col>
                            <Col md="4" sm="12">
                              <div className="mb-3">
                                <Label for="modeOfPayment">
                                  Mode of Payment (സംഭാവന നൽകാൻ സ്വീകരിക്കുന്ന മാർഗ്ഗം) *
                                </Label>
                                <Select
                                  onChange={(e) => {
                                    setSelectedModeOfPayments(e)
                                    setEmpDetails({
                                      ...empDetails,
                                      modeOfPayment : e.value
                                    })
                                    onChangeValidation("modeOfPayment", e.value)
                                  }}
                                  options={modeOfPayments}
                                  classNamePrefix="select2-selection"
                                  isLoading={false}
                                  id={"modeOfPayment"}
                                  value={selectedModeOfPayments}
                                />
                                {(validation["modeOfPayment"] === false) &&
                                  <div className="custom-error">
                                    Please select the Mode Of Payment
                                  </div>
                                }
                              </div>
                            </Col>
                            
                          </Row>
                          <Row>
                            <Col md="4" sm="12">
                              <div className="mb-3">
                                <Label for="subsDepositMethod">
                                Donation Deposit Method (സംഭാവന നൽകാൻ ഉദ്ദേശിക്കുന്ന രീതി) *
                                </Label>
                                <Select
                                  onChange={(e) => {
                                    setSelectedSubsDepositMethod(e)
                                    setEmpDetails({
                                      ...empDetails,
                                      subsDepositMethod : e.value
                                    })
                                    onChangeValidation("subsDepositMethod", e.value)
                                  }}
                                  options={subsDepMethod}
                                  classNamePrefix="select2-selection"
                                  isLoading={false}
                                  id={"subsDepositMethod"}
                                  value={selectedSubsDepositMethod}
                                />
                                {(validation["subsDepositMethod"] === false) &&
                                  <div className="custom-error">
                                    Please enter a valid Method
                                  </div>
                                }
                              </div>
                            </Col>
                            
                            <Col md="4" sm="12">
                              <div className="mb-3">
                                <Label for="recommendedPerson">
                                  Recommended Person (സൊസൈറ്റിയെക്കുറിച്ച് നിങ്ങളെ പരിചയപ്പെടുത്തിയ വ്യക്തി)
                                </Label>
                                <Input
                                  type="text"
                                  className="form-control"
                                  id="recommendedPerson"
                                  maxLength="60"
                                  value={empDetails.recommendedPerson || ''}
                                  onChange={e => {
                                    setEmpDetails({
                                      ...empDetails,
                                      recommendedPerson : e.target.value
                                    })
                                    onChangeValidation("recommendedPerson", e.target.value.trim())
                                  }}
                                />
                              </div>
                            </Col>

                            {empDetails && empDetails.panchayath &&  empDetails.panchayath == 'Others' && 
                             <Col md="4" sm="12">
                              <div className="mb-3">
                                <Label for="otherpanchayath">
                                  Panchayath Name (പഞ്ചായത്ത്) *
                                </Label>
                                <Input
                                  type="text"
                                  className="form-control"
                                  id="place"
                                  maxLength="50"
                                  value={empDetails.otherpanchayath || ''}
                                  onChange={e => {
                                      setEmpDetails({
                                        ...empDetails,
                                        otherpanchayath : e.target.value
                                      })
                                      onChangeValidation("otherpanchayath", e.target.value.trim())
                                  }}
                                />
                                {(validation["otherpanchayath"] === false) &&
                                  <div className="custom-error">
                                    Please enter the Panchayath Name
                                  </div>
                                }
                              </div>
                            </Col>
                            }

                          </Row>
                          
                        </Form>
                      </div>
                      <div className="text-center clearfix mb-5">
                        {isEdit ? 
                        <>
                        <Button
                          color="secondary"
                          className="btn w-lg btn-secondary me-2"
                          onClick={() => {
                            setRefId()
                            setIsEdit(false)
                          }}
                          >
                            Cancel
                        </Button>
                        <Button
                          color="success"
                          className="btn w-lg drops-bg-shade-9 "
                          onClick={() => {
                            updateEmployee()
                          }}
                          >
                            Update
                        </Button>
                        </>
                        :
                        <>
                        <Button
                          color="secondary"
                          className="btn w-lg btn-secondary me-2"
                          onClick={() => {
                            navigate('/reg-home');
                          }}
                          >
                            Home
                        </Button>
                        <Button
                          color="success"
                          className="btn w-lg drops-bg-shade-9 "
                          onClick={() => {
                            addEmployee()
                          }}
                          >
                            Submit
                        </Button>
                        </>
                        }
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </>
          }
          {showRegistrationSuccess &&
          <div id="div-to-download">
            <Row className="bg-light m-0 py-2 mb-1 mt-0 text-center">
              <Alert color="success" className="mb-0">
                <i className="mdi mdi-check-all me-2"></i>
                നിങ്ങളുടെ രജിസ്ട്രേഷൻ പൂർത്തീകരിച്ചിരിക്കുന്നു. <br/>
                <div className="mt-2">
                  Dear <b>{empDetails.name}</b>{", "}<br/>
                  <b>Thank You</b> for being part of <span className="drops-title fw-bold">DROPS</span> family.
                </div>
              </Alert>
            </Row>
            <Row className="justify-content-center mb-0">
              <Col xl={6} xs={12} md={9}>
                  <Card className="mb-1">
                      <CardBody>
                          <h5 className="fw-semibold">Summary</h5>

                          <div className="table-responsive">
                              <table className="table table-sm">
                                  <tbody>
                                      <tr>
                                        <td scope="col">Confirmation ID</td>
                                        <th scope="col">{registrationConfirmationId}</th>
                                      </tr>
                                      <tr>
                                        <td>Name</td>
                                        <th scope="row">{empDetails.name}</th>
                                      </tr>
                                      <tr>
                                        <td>House Name</td>
                                        <th scope="row">{empDetails.houseName}</th>
                                      </tr>
                                      <tr>
                                        <td>Mobile No.</td>
                                        <th scope="row">{empDetails.mobileNo}</th>
                                      </tr>
                                      <tr>
                                        <td>Monthly Donation (Rs)</td>
                                        <th scope="row">{Number(empDetails.monthlySubsAmount).toLocaleString('en-IN',{minimumFractionDigits: 2})}</th>
                                      </tr>
                                      <tr>
                                        <td>Collection Date</td>
                                        <th scope="row">{empDetails.collectionDate}</th>
                                      </tr>
                                  </tbody>
                              </table>
                          </div>
                          <div className="hstack gap-2 d-print-none">
                              <button onClick={() => { reEditSame() }} className="btn btn-soft-secondary w-100">Edit</button>
                              <button disabled={downLoadInProgress} onClick={()=>{downloadReceipt()}} className="btn btn-soft-primary w-100">
                                Download
                                {downLoadInProgress && <i className="bx bx-loader bx-spin font-size-18 align-middle ms-2" />}
                              </button>
                          </div>
                      </CardBody>
                  </Card>
              </Col>
              <Col xl={12} xs={12} md={9} className="d-none d-print-block bg-print mb-0">
                <Card className="mb-1">
                      <CardBody>
                          <h5 className="fw-semibold">Account Details</h5>
                          <div className="table-responsive">
                              <table className="table table-sm">
                                  <tbody>
                                      <tr>
                                        <td scope="col">Name</td>
                                        <th scope="col">DROPS CHARITABLE AND WELFARE SOCIETY</th>
                                        <td rowSpan="6">
                                          <img src={bankImage} alt="" height="280px"  />
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>Account Number</td>
                                        <th scope="row">15570200003957</th>
                                      </tr>
                                      <tr>
                                        <td>IFSC</td>
                                        <th scope="row">FDRL0001557</th>
                                      </tr>
                                      <tr>
                                        <td>Branch</td>
                                        <th scope="row">EDAKKARA</th>
                                      </tr>
                                      <tr>
                                        <td>Account Type</td>
                                        <th scope="row">Current Account</th>
                                      </tr>
                                      <tr>
                                        <td>UPI</td>
                                        <th scope="row">
                                          <span className="custom-bg-highlight px-1 py-0">drops2023@fbl</span>
                                        </th>
                                      </tr>
                                  </tbody>
                              </table>
                          </div>
                          
                      </CardBody>
                  </Card>
              </Col>
              <Col xl={12} xs={12} md={9} className="align-self-center d-print-none">
                <Card className="m-0 p-0">
                  <CardBody className="m-0 p-0">
                    <Row className="d-flex align-items-center justify-content-center">
                      <Col xl={6} xs={12} md={5} className="p-4 pb-0">
                      <h5 className="fw-semibold">Account Details</h5>
                      <div className="table-responsive">
                              <table className="table table-sm">
                                  <tbody>
                                      <tr>
                                        <td scope="col">Name</td>
                                        <th scope="col">DROPS CHARITABLE AND WELFARE SOCIETY</th>
                                      </tr>
                                      <tr>
                                        <td>Account Number</td>
                                        <th scope="row">15570200003957</th>
                                      </tr>
                                      <tr>
                                        <td>IFSC</td>
                                        <th scope="row">FDRL0001557</th>
                                      </tr>
                                      <tr>
                                        <td>Branch</td>
                                        <th scope="row">EDAKKARA</th>
                                      </tr>
                                      <tr>
                                        <td>Account Type</td>
                                        <th scope="row">Current Account</th>
                                      </tr>
                                      <tr>
                                        <td>UPI</td>
                                        <th scope="row">
                                          <span className="custom-bg-highlight px-1 py-0">drops2023@fbl</span>
                                        </th>
                                      </tr>
                                  </tbody>
                              </table>
                          </div>
                      </Col>
                      <Col xl={3} xs={12} md={6} className="d-flex align-items-center justify-content-center">
                        <img src={bankImage} alt="" height="300px"  />
                      </Col>
                    </Row>

                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row className="d-print-none mt-1">
              <div className="mt-1 text-center mb-2 ">
                <Link to="#" className="text-info me-3" onClick={()=>onRetrieveRegistration()}>
                  <i className="mdi mdi-card-search-outline me-1" />
                  Retrieve Registration
                </Link>
                <Link to="#" className="text-primary" onClick={()=>onCreateNewRegistration()}>
                  <i className="mdi mdi-plus-box-outline me-1" />
                  Add New Registration
                </Link>
                
              </div>
            </Row>
            <DropsContactDetails/>
          </div>
          }
        </Container>
      </div>

      <Modal
        size="lg"
        isOpen={showModal}
        toggle={() => {
          toggleModal();
        }}
        >
        <div className="modal-header">
          <h5
            className="modal-title mt-0"
            id="myExtraLargeModalLabel"
          >
            Confirm Changes
          </h5>
          <button
            onClick={() => {
              setShowModal(false);
            }}
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body mb-0">
          <EditEmployeeChanges changedFields={modifiedFields} oldValues={previousEmpDetails} newValues={empDetails} onClose={()=>setShowModal(false)}/>
        </div>
        <div className="text-center clearfix mb-3">
          <Button
            color="secondary"
            className="btn w-lg btn-secondary me-2"
            onClick={() => {
              setShowModal(false);
            }}
            >
              Close
          </Button>
          <Button
            color="success"
            className="btn w-lg btn-success "
            onClick={() => {
              confirmEditEmployee();
            }}
            >
              Confirm
          </Button>
        </div>
      </Modal>

    </React.Fragment>
  )
}

RegistrationWithAttachments.propTypes = {
  match: PropTypes.object,
}

//export default RegistrationWithAttachments

export default withRouter(RegistrationWithAttachments);
