import React from "react"
import PropTypes from 'prop-types'
import { Link } from "react-router-dom"
import { Row, Col, Button } from "reactstrap"
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

const ExcelExport = ({ csvData, fileName }) => {

  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const exportToCSV = (csvData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };

    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });

    FileSaver.saveAs(data, fileName + fileExtension);
  };

  return (
    <span >
      <Button color="primary" className="btn btn-primary me-2" onClick={(e) => exportToCSV(csvData, fileName)}>
       <i className="bx bx-download me-2"></i> Export
      </Button>
    </span>
  )
}

ExcelExport.propTypes = {
}

export default ExcelExport
