import React, { useEffect, useState, useRef } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Form,
  Label,
  Input,
  Alert,
  Button,
  Modal
} from "reactstrap";
//Import Breadcrumb
import Select, { components } from "react-select";
import { isEmpty } from "lodash";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import DropsContactDetails from "components/Common/DropsContactDetails"
import DropsRegistrationDetails from "components/Common/DropsRegistrationDetails"
import DropsHeader from "components/Common/DropsHeader"
import withRouter from "components/Common/withRouter";
import Loader from "components/Common/Loader"
import { retrieveDetails, saveDetails, processWP } from "helpers/backend_helper"
import RegistrantsDropDown from "components/Common/RegistrantsDropDown"
import {getCurrentUUID, getTodayDateStr, 
  getMonthsDD, getMonthsDifference, showSuccessPopUp, 
  getFormatedDateStr, downloadPDF, showCloseableToast,
  showAlertPopUp } from "helpers/function_helper"
import { modeOfPayments } from "common/data/dropdowns"
import { Link } from "react-router-dom"
import dayjs from "dayjs";
import customParseFormat from 'dayjs/plugin/customParseFormat'
dayjs.extend(customParseFormat)
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
const MySwal = withReactContent(Swal)
import EditPaymentChanges from "./EditPaymentChanges";

const AddPayment = props => {

  //meta title
  document.title = "Payment Receipt | Drops";

  const [showLoading, setShowLoading] = useState(false)
  const [error, setError] = useState('')
  const [detailsFetched, setDetailsFetched] = useState(false)
  const [showPaymentSuccess, setShowPaymentSuccess] = useState(false)
  const [receiptId, setReceiptId] = useState('')
  const [amountInWords, setAmountInWords] = useState('')
  const [empDetails, setEmpDetails] = useState(initialiseEmployeeDetails())
  const [monthsDD, setMonthsDD] = useState(getMonthsDD(-12,24))
  const [contributingMonths, setContributingMonths] = useState([])
  const [paymentDetails, setPaymentDetails] = useState(initialisePaymentDetails())
  const [selectedModeOfPayments, setSelectedModeOfPayments] = useState(null)
  const [downLoadInProgress, setDownLoadInProgress] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [previousPaymentDetails, setPreviousPaymentDetails] = useState({})
  const [modifiedFields, setModifiedFields] = useState([])
  const [showModal, setShowModal] = useState(false)
  const [gotChanges, setGotChanges] = useState(false)

  const scrollRef = useRef(null)

  useEffect(() => {
    const params = props.router.params;
    if (params && params.id) {
      retrievePaymentDetails(params.id)
      //setRefId(params.id)
    }
  }, [])

  async function retrievePaymentDetails(id){
    initialisePaymentDetails()
    setError('')
    setShowLoading(true)
    const inputToSend = {
      "data" : {
        "type" : "RET_REC",
        "payment" : {
          id:id.trim()
        }
      }
    }
    const response = await retrieveDetails(inputToSend);
    setShowLoading(false)
    if(response && response.status === 'failure'){
      setError(response.error.errorMsg)
    }
    else if(response && response.status === 'success' && response.data){
      prefillPaymentDetails(response.data)
      updateEmpDetails(response.data)
      setIsEdit(true)
      setDetailsFetched(true)
      setReceiptId(''+id.trim())
      //setRegistrationConfirmationId(id)
      //setRefId(id)
    }
}

//for edit
function updateEmpDetails(data){

  setEmpDetails({
    name: data.fullname,
    houseName: data.house_name,
    postOffice: data.post_office,
    pinCode: data.pin_code,
    panchayath: data.panchayath,
    wardNo:data.ward_no,
    mobileNo:data.mobile_no,
    whatsAppNo:data.whatsapp_no,
    place:data.place,
    refId: data.ref_fk,
    id: data.userId,
    randomId: data.random_id
  })
}

//for edit
function prefillPaymentDetails(paymentDetails){

  const prePayDetails = {
    id: paymentDetails.id,
    regNo: paymentDetails.userId,
    userName: paymentDetails.fullname,
    userId: paymentDetails.userId,
    subsAmount: paymentDetails.amount,
    receiptDate: getFormatedDateStr(paymentDetails.receipt_date,'YYYY-MM-DD'),
    contMonths: paymentDetails.cont_months,
    contMonthsDesc: paymentDetails.cont_months_desc,
    comments: paymentDetails.comments,
    lastPaymentMonth: '',
    refNo: paymentDetails.ref_fk,
    nextDueDate:'',
    modeOfPayment: paymentDetails.mode_of_payment,
    paymentRefNo: paymentDetails.payment_ref_no,
    refId: paymentDetails.ref_fk
  }

  setPaymentDetails(prePayDetails)
  setPreviousPaymentDetails(prePayDetails)
  setContributingMonths(JSON.parse(paymentDetails.cont_months))
  const selModePayment = modeOfPayments.filter(each=>each.value == paymentDetails.mode_of_payment)
  if(selModePayment && selModePayment.length > 0){
    setSelectedModeOfPayments(selModePayment[0])
  }
}

  function initialisePaymentDetails(){
    let userId = ''
    if (localStorage.getItem("authUser")) {
      const obj = JSON.parse(localStorage.getItem("authUser"));
      userId = obj.id
    }

    return {
      regNo: '',
      userId: ''+userId,
      subsAmount: "",
      receiptDate: getTodayDateStr('YYYY-MM-DD'),
      contMonths: [],
      contMonthsDesc: '',
      comments: '',
      lastPaymentMonth: '',
      refNo:'',
      nextDueDate:'',
      modeOfPayment:'',
      paymentRefNo:''
    }
  }

  function initialiseEmployeeDetails(){
    return {
      name: "",
      houseName: "",
      postOffice: "",
      pinCode: "",
      panchayath: "",
      wardNo:"",
      mobileNo:"",
      whatsAppNo:"",
      monthlySubsAmount:"",
      collectionDate:"",
      subsDepositMethod:"",
      modeOfPayment:"",
      recommendedPerson:"",
      place:"",
      age:"",
      gender:"",
    }
  }

  const onSelectRegDD=(sel)=>{
    setError('')
    //console.log('sel:',sel)
    if(!isEdit){
      setEmpDetails(initialiseEmployeeDetails())
      setDetailsFetched(false)
      setPaymentDetails(initialisePaymentDetails())
      setReceiptId('')
      setContributingMonths([])
      setSelectedModeOfPayments(null)
    }
    setAmountInWords('')
    retrieveRegistrationDetails(''+sel.reg_no)
    setDetailsFetched(true)
  }

  async function retrieveRegistrationDetails(id){
    //initialiseForm()
    //setError('')
    setShowLoading(true)
    const inputToSend = {
      "data" : {
        "type" : "RET_REG",
        "user" : {
          id:id.trim()
        }
      }
    }
    const response = await retrieveDetails(inputToSend);
    setShowLoading(false)
    if(response && response.status === 'failure'){
      setError(response.error.errorMsg)
    }
    else if(response && response.status === 'success' && response.data){
      prefillEmpDetails(response.data)
    }
  }

  const prefillEmpDetails=(data)=>{
    const retData = {
      name: data.fullname,
      houseName: data.house_name,
      postOffice: data.post_office,
      pinCode: data.pin_code,
      panchayath: data.panchayath,
      wardNo:data.ward_no,
      mobileNo:data.mobile_no,
      whatsAppNo:data.whatsapp_no,
      monthlySubsAmount:data.monthly_amount,
      collectionDate:data.collection_date,
      subsDepositMethod:data.deposit_method,
      modeOfPayment:data.mode_payment,
      recommendedPerson:data.recommended_person,
      place:data.place,
      age:data.age,
      gender:data.gender,
      recordStatus: data.record_status,
      randomId: data.random_id,
      refId: data.id+''+data.random_id,
      id: data.id,
      nextContDate: data.next_contribution_date,
      lastContDate: data.last_contribution_date,
    }
    setEmpDetails(retData)

    if(!isEdit){
      const selModePayment = modeOfPayments.filter(each=>each.value == data.mode_payment)
      if(selModePayment && selModePayment.length > 0){
        setSelectedModeOfPayments(selModePayment[0])
      }
      proposeAmount(data.monthly_amount,data.deposit_method,data.next_contribution_date,data.last_contribution_date, data.id, data.random_id, data.collection_date, data.mode_payment)
    }
    else{
      setPaymentDetails({
        ...paymentDetails,
        regNo: data.id,
        userName: data.fullname,
        refId: data.id+''+data.random_id,
      })
    }
  }

  const proposeAmount=(amt, subsMethod, nextContDate, lastContDate, regId, randomId, collectionDate, modeOfPayment)=>{
    let months = 1;
    if(subsMethod == 'Quarterly'){
      months = 3
    }
    else if(subsMethod == 'HalfYearly'){
      months = 6
    }
    else if(subsMethod == 'Yearly'){
      months = 12
    }

    const monthsDiff = getMonthsDifference(lastContDate) + 1 // +1 because both has changed to start of the month

    const prefilledMonths = getMonthsDD(monthsDiff,monthsDiff+months-1)
    setContributingMonths(prefilledMonths)

    const highestDate = findTheMax(prefilledMonths)
    
    setPaymentDetails({
      ...paymentDetails,
      subsAmount: ''+ months * Number(amt),
      contMonths: prefilledMonths,
      contMonthsDesc: getContMonthsDesc(prefilledMonths),
      lastPaymentMonth: highestDate,
      nextDueDate: getNextCollectionDate(highestDate,collectionDate),
      regNo: ''+regId,
      refNo: regId+''+randomId,
      modeOfPayment: modeOfPayment
    })
  }

  function isNumber(num){
    if(Number(num) || num == 0){
      return true;
    }
    else{
      return false;
    }
  }

  const mandatoryFields = ['subsAmount','contMonths','receiptDate']

  const [validation, setValidation] = useState({
    amount: null,
    receiptDate: null,
    contMonths: null
  })
  const onChangeValidation = (fieldName, value) => {
    const modifiedV = { ...validation }

    if(mandatoryFields.includes(fieldName)){
      if (value !== "") {
        modifiedV[fieldName] = true
      } else {
        modifiedV[fieldName] = false
      }
      setValidation(modifiedV)
    }
    setValidation(modifiedV)
  }

  function validateForm(){

    const modifiedV = { ...validation }
    let formValid = true;
    mandatoryFields.forEach(eachItem =>{
      if(!isEmpty(paymentDetails[eachItem])){
        modifiedV[eachItem] = true
      }
      else{
        modifiedV[eachItem] = false
        formValid = false
      }
    })
    setValidation(modifiedV)
    
    return formValid
  }

  const findTheMax=(datesArray)=>{
    const highestDate = datesArray.reduce((maxDate, currentDate) => {
      const parsedDate = dayjs(currentDate.value, 'MMM-YY');
      return parsedDate.isAfter(maxDate) ? parsedDate : maxDate;
    }, dayjs('1900-01-01'));
    return highestDate.format('YYYY-MM-DD')
  }

  const getNextCollectionDate=(lastPayMonth, collDate)=>{
    const nextDueDate = dayjs(lastPayMonth).add(1,"month").set("date",collDate)
    return nextDueDate.format('YYYY-MM-DD')
  }

  const getContMonthsDesc=(selectedMonths)=>{
    let contMonDescTemp = ''
    selectedMonths.forEach(each=>{
      contMonDescTemp += each.label + ', '
    })
    if(contMonDescTemp.endsWith(', ')){
      contMonDescTemp = contMonDescTemp.substring(0, contMonDescTemp.lastIndexOf(', '))
    }
    return contMonDescTemp
  }

  const updateContributingMonths=(selectedMonths)=>{

    const highestDate = findTheMax(selectedMonths)
    
    setPaymentDetails({
      ...paymentDetails,
      contMonths : selectedMonths,
      contMonthsDesc: getContMonthsDesc(selectedMonths),
      lastPaymentMonth: highestDate,
      nextDueDate: getNextCollectionDate(highestDate,empDetails.collectionDate)
    })
  }

  function findChanges(){
    const excludedList = ['lastPaymentMonth','nextDueDate']
    const changedKeys = []
    Object.keys(paymentDetails)
    .forEach(eachKey => {
      if(!excludedList.includes(eachKey) && paymentDetails[eachKey] !== previousPaymentDetails[eachKey]){
        changedKeys.push(eachKey)
      }
    })
    return changedKeys;
  }

  useEffect(()=>{
    if(isEdit){
      const change = findChanges()
      if(change.length == 0){
        setGotChanges(false)
      }
      else{
        setGotChanges(true)
      }
    }
  },[paymentDetails])

  async function editPayment(){
    setError('')
    let formValid = validateForm()
    console.log('formValid:',formValid)
    if(formValid){
      const changes = findChanges()
      if(changes.length == 0){
        showAlertPopUp('Sorry, there are no changes to update.')
      }
      else{
        setModifiedFields(changes)
        setShowModal(true)
      }
    }
  }

  async function addPayment(){
    setError('')
    let formValid = validateForm()
    if(formValid){
      setShowLoading(true)
      const inputToSend = {
        "data" : {
          "type" : "PAYMENT",
          "payment" : {
            ...paymentDetails
          }
        }
      }

      const response = await saveDetails(inputToSend);

      setShowLoading(false)
      scrollToResults()
      if(response && response.status === 'failure'){
        setError(response.error.errorMsg)
      }
      else if(response && response.status === 'success'){
        setShowPaymentSuccess(true)
        setReceiptId(response.data.id)
        setAmountInWords(response.data.amountInWords)
      }
    }
  }

  const onCreateNewPayment=()=>{
    setShowPaymentSuccess(false)
    setError('')
    setEmpDetails(initialiseEmployeeDetails())
    setPaymentDetails(initialisePaymentDetails())
    setContributingMonths([])
  }

  const scrollToResults = () => {
    scrollRef.current?.scrollIntoView({ behavior: "smooth", block: "center" })
  }

  const getEmpAddress=()=>{
    let address = ''
    if(!isEmpty(empDetails.houseName)){
      address += empDetails.houseName
    }
    if(!isEmpty(empDetails.place)){
      address += ", "+ empDetails.place
    }
    if(!isEmpty(empDetails.postOffice)){
      address += ", "+ empDetails.postOffice
    }
    if(!isEmpty(empDetails.panchayath) && empDetails.panchayath != empDetails.postOffice){
      address += ", "+ empDetails.panchayath
    }
    if(!isEmpty(empDetails.pinCode)){
      address += " - "+ empDetails.pinCode
    }
    return address
  }

  const downloadReceipt=async()=>{
    setDownLoadInProgress(true)
    const inputToSend = {
      "data" : {
        "type" : "DOWNLOAD_RECEIPT",
        "payment" : {
          id: receiptId,
          receiptDate: getFormatedDateStr(paymentDetails.receiptDate,'DD-MMM-YY'),
          uName: empDetails.name,
          address: getEmpAddress(),
          regNo : paymentDetails.refNo,
          mobNo : empDetails.mobileNo,
          subsAmount : Number(paymentDetails.subsAmount).toLocaleString('en-IN',{minimumFractionDigits: 2}),
          subsAmountInWords : amountInWords,
          modeOfPayment : paymentDetails.modeOfPayment,
          contMonthsDesc : paymentDetails.contMonthsDesc
        }
      }
    }
    const response = await processWP(inputToSend);
    
    if(response && response.status === 'failure'){
      showCloseableToast('Sorry, unable to process.','top-center','error')
    }
    else if(response && response.status === 'success' && response.data){
      downloadPDF(response.data, 'drops_payment_receipt_'+receiptId+'.pdf')
    }
    setDownLoadInProgress(false)

  }

  const sendReceipt=async()=>{
    const result  = await MySwal.fire({
      title: `Are you sure to send to whatsapp message to - ${empDetails.name} (${empDetails.whatsAppNo})?`,
      text: "Please confirm",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, send',
      showLoaderOnConfirm:true,
      allowOutsideClick: () => !MySwal.isLoading(),
      preConfirm: (sme) => {
        const inputToSend = {
          "data" : {
            "type" : "SEND_RECEIPT",
            "key": getCurrentUUID(),
            "message" : {'caption':'DROPS Payment Receipt'},
            "payment" : {
              id: receiptId,
              receiptDate: getFormatedDateStr(paymentDetails.receiptDate,'DD-MMM-YY'),
              uName: empDetails.name,
              address: getEmpAddress(),
              regNo : paymentDetails.refNo,
              mobNo : empDetails.mobileNo,
              subsAmount : Number(paymentDetails.subsAmount).toLocaleString('en-IN',{minimumFractionDigits: 2}),
              subsAmountInWords : amountInWords,
              modeOfPayment : paymentDetails.modeOfPayment,
              contMonthsDesc : paymentDetails.contMonthsDesc,
              whatsAppNo: empDetails.whatsAppNo,
            }
          }
        }
        return processWP(inputToSend)
        .then(response => {
          //console.log('response:',response)
          if (response.status != 'success') {
            if(response && response.error && response.error.code && response.error.code == 'E1001'){
              throw new Error('No valid session. Please initialise')
            }
            else{
              throw new Error(response.statusText)
            }
          }
          if(response && response.data && response.data.contactNos && response.data.contactNos[0] && response.data.contactNos[0].status){
            const wpStatus = response.data.contactNos[0].status
            if(wpStatus == 'SS'){
              return response
            }
            else if(wpStatus == 'SF'){
              MySwal.showValidationMessage(
                `Sending failed : Unknown Reason`
              )
            }
            else if(wpStatus == 'NR'){
              MySwal.showValidationMessage(
                `Sending failed : Number not registered in WhatsApp. Please check.`
              )
            }
            else if(wpStatus == 'NV'){
              MySwal.showValidationMessage(
                `Sending failed : Not a valid WhatsApp no.`
              )
            }
          }
          else{
            return response
          }
        })
        .catch(error => {
          MySwal.showValidationMessage(
            `Request failed: ${error}`
          )
        })
      },
    })

    if(result.isConfirmed){
      showSuccessPopUp('Message Send Successfully !')
    }
    //console.log('result:',result)
  }

  function toggleModal() {
    setShowModal(!showModal);
    removeBodyCss();
  }
  
  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  const confirmEditPayment = async() => {
    setShowLoading(true)
    const oldValues = []
    const newValues = []
    const index = modifiedFields.indexOf('userName') //not updating the name, since it is not in payment table
    if (index > -1) { // only splice array when item is found
      modifiedFields.splice(index, 1); // 2nd parameter means remove one item only
    }

    modifiedFields.forEach(eachField => {
      oldValues.push(previousPaymentDetails[eachField] ? previousPaymentDetails[eachField] : '')
      newValues.push(paymentDetails[eachField] ? paymentDetails[eachField] : '')
    })
  
    //EMP_UPDATE
    const inputToSend = {
      "data" : {
        "type" : "UPD_PAYMENT",
        "key" : ''+paymentDetails.id,
        "updatedData": {
          modifiedFields: modifiedFields,
          oldValues: oldValues,
          newValues: newValues
        }
      }
    }
    const resp = await saveDetails(inputToSend)
    setShowLoading(false)
    if(resp && resp.status == 'success'){
      setShowModal(false)
      setShowPaymentSuccess(true)
      setAmountInWords(resp.data.amountInWords)
      scrollToResults()
    }
    else{
      setShowModal(false)
      setError(resp.error.errorMsg)
    }
  }

  return (
    <React.Fragment>
      <Loader showLoader={showLoading} />
      <div className="page-content full-height">
        <Container fluid>
          {/* Render Breadcrumb */}
          {isEdit ? 
          <Breadcrumbs
            title={"Payments"}
            breadcrumbItem={"Edit Payment"}
          />
          :
          <Breadcrumbs
            title={"Payments"}
            breadcrumbItem={"Add Payment"}
          />
          }
          <div ref={scrollRef}></div>
          {!showPaymentSuccess && 
          <Card className="">
              <CardBody className="">
                {error ? <Alert color="danger" className="text-center mb-1">{error}</Alert> : null}
                <Row>
                  <Col xl={6} xs={12} md={6}>
                    <RegistrantsDropDown selectOnChange={onSelectRegDD} defaultId={paymentDetails.userId || null}/>
                    {detailsFetched && empDetails.id && 
                      <div className="wizard clearfix">
                        <div className="content clearfix mt-0">
                          <Form>
                            <Row className="mt-3">
                              <Col xl={6} xs={12} md={6}>
                                <div className="mb-3">
                                  <Label for="receiptDate">
                                    Date *
                                  </Label>
                                  <input
                                    className="form-control"
                                    type="date"
                                    value={paymentDetails.receiptDate || ''}
                                    //defaultValue={getTodayDateStr('YYYY-MM-DD')}
                                    id="receiptDate"
                                    onChange={e => {
                                      setPaymentDetails({
                                        ...paymentDetails,
                                        receiptDate : e.target.value
                                      })
                                      onChangeValidation("receiptDate", e.target.value.trim())
                                    }}
                                  />
                                  {(validation["receiptDate"] === false) &&
                                    <div className="custom-error">
                                      Please enter a valid Date
                                    </div>
                                  }
                                </div>
                              </Col>
                              <Col xl={6} xs={12} md={6}>
                                <div className="mb-3">
                                  <Label for="subsAmount">
                                    Amount *
                                  </Label>
                                  <Input
                                    type="tel"
                                    className="form-control"
                                    id="subsAmount"
                                    maxLength="12"
                                    value={paymentDetails.subsAmount || ''}
                                    onChange={e => {
                                      if(isNumber(e.target.value)){
                                        setPaymentDetails({
                                          ...paymentDetails,
                                          subsAmount : e.target.value
                                        })
                                        onChangeValidation("subsAmount", e.target.value.trim())
                                      }
                                    }}
                                  />
                                  {(validation["subsAmount"] === false) &&
                                    <div className="custom-error">
                                      Please enter a valid Amount
                                    </div>
                                  }
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col xl={12} xs={12} md={12}>
                                <div className="mb-3">
                                  <Label for="contMonths">
                                    Contribution for *
                                  </Label>
                                  <Select
                                    value={contributingMonths}
                                    isClearable={true}
                                    onChange={(e) => {
                                      setContributingMonths(e)
                                      updateContributingMonths(e)
                                    }}
                                    isMulti={true}
                                    options={monthsDD}
                                    classNamePrefix="select2-selection"
                                    id="contMonths"
                                    styles={{menuPortal:base => ({...base, zIndex:9999})}}
                                  />
                                  {(validation["contMonths"] === false) &&
                                    <div className="custom-error">
                                      Please select a valid Month
                                    </div>
                                  }
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col md="6" sm="12">
                                  <div className="mb-3">
                                    <Label for="modeOfPayment">
                                      Mode of Payment
                                    </Label>
                                    <Select
                                      onChange={(e) => {
                                        setSelectedModeOfPayments(e)
                                        setPaymentDetails({
                                          ...paymentDetails,
                                          modeOfPayment : e.value
                                        })
                                        onChangeValidation("modeOfPayment", e.value)
                                      }}
                                      options={modeOfPayments}
                                      classNamePrefix="select2-selection"
                                      isLoading={false}
                                      id={"modeOfPayment"}
                                      value={selectedModeOfPayments}
                                    />
                                  </div>
                                </Col>
                                <Col md="6" sm="12">
                                    <div className="mb-3">
                                      <Label for="paymentRefNo">
                                        Reference No (if any)
                                      </Label>
                                      <Input
                                        type="text"
                                        className="form-control"
                                        id="paymentRefNo"
                                        maxLength="50"
                                        value={paymentDetails.paymentRefNo || ''}
                                        onChange={e => {
                                            setPaymentDetails({
                                              ...paymentDetails,
                                              paymentRefNo : e.target.value
                                            })
                                            onChangeValidation("paymentRefNo", e.target.value.trim())
                                        }}
                                      />
                                    </div>
                                  </Col>
                            </Row>
                            <Row>
                              <Col xl={12} xs={12} md={12}>
                                <div className="mb-3">
                                  <Label for="comments">
                                    Remarks, if any
                                  </Label>
                                  <textarea
                                    id="comments"
                                    className="form-control"
                                    rows="3"
                                    maxLength="250"
                                    value={paymentDetails.comments || ''}
                                    onChange={e => {
                                      onChangeValidation("comments", e.target.value.trim())
                                      setPaymentDetails({
                                        ...paymentDetails,
                                        comments : e.target.value
                                      })
                                    }}
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col xl={12} xs={12} md={12}>
                                <div className="text-center clearfix mb-5">
                                  {isEdit ? 
                                  <div className="hstack gap-2 d-print-none">
                                    <Button
                                      color="success"
                                      disabled={gotChanges}
                                      className="btn btn-soft-primary w-100"
                                      onClick={() => {
                                        downloadReceipt()
                                      }}
                                      >
                                        <i className="mdi mdi-download font-size-18 align-middle" /> Download
                                      {downLoadInProgress && <i className="bx bx-loader bx-spin font-size-18 align-middle ms-2" />}
                                    </Button>
                                    <Button
                                      color="success"
                                      disabled={gotChanges}
                                      className="btn btn-soft-success w-100"
                                      onClick={() => {
                                        sendReceipt()
                                      }}
                                      >
                                        <i className="mdi mdi-whatsapp font-size-18 align-middle" /> Send
                                      {downLoadInProgress && <i className="bx bx-loader bx-spin font-size-18 align-middle ms-2" />}
                                    </Button>
                                    <Button
                                      color="success"
                                      className="btn drops-bg-shade-9 w-100"
                                      onClick={() => {
                                        editPayment()
                                      }}
                                      >
                                        <i className="mdi mdi-content-save-edit font-size-18 align-middle me-1" />
                                        Update
                                    </Button>
                                  </div>
                                  :
                                  <Button
                                    color="success"
                                    className="btn w-lg drops-bg-shade-9 "
                                    onClick={() => {
                                      addPayment()
                                    }}
                                    >
                                      Save
                                  </Button>
                                  }
                                </div>
                              </Col>
                            </Row>
                          </Form>
                        </div>
                      </div>
                      }
                  </Col>
                  <Col xl={6} xs={12} md={6} className="">
                  {detailsFetched && empDetails.id && 
                    <div className="bg-light table-responsive px-3 py-3">
                      <table className="table table-sm mb-2">
                          <tbody>
                              <tr>
                                <td scope="col">Reg ID</td>
                                <th scope="col">{empDetails.id+''+empDetails.randomId}</th>
                              </tr>
                              <tr>
                                <td>Name</td>
                                <th scope="row">{empDetails.name}</th>
                              </tr>
                              <tr>
                                <td>House Name</td>
                                <th scope="row">{empDetails.houseName}</th>
                              </tr>
                              <tr>
                                <td>Mobile No.</td>
                                <th scope="row">{empDetails.mobileNo}</th>
                              </tr>
                              {!isEdit && 
                              <>
                              <tr>
                                <td>Monthly Donation</td>
                                <th scope="row">{empDetails.monthlySubsAmount}</th>
                              </tr>
                              <tr>
                                <td>Donation Deposit Method</td>
                                <th scope="row">{empDetails.subsDepositMethod}</th>
                              </tr>
                              </>
                              }
                          </tbody>
                      </table>
                    </div>
                    }
                  </Col>
                </Row>
            </CardBody>
          </Card>
          }

          {showPaymentSuccess &&
          <div id="paymentReceipt">
            <Row className="bg-light m-0 py-2 mb-1 mt-0 text-center">
              <Alert color="success" className="mb-0">
                <i className="mdi mdi-check-all me-2"></i>
                Payment added successfully!
              </Alert>
              {/*<Alert color="success" className="mb-0">
                <i className="mdi mdi-check-all me-2"></i>
                Dear <b>{empDetails.name}</b>{", "}<br/>
                <b>Thank You</b> for the contribution and showing your care for your <span className="drops-title fw-bold">DROPS</span> family.
              </Alert>
              */}
            </Row>
            <Row className="justify-content-center">
              <Col xl={6} xs={12} md={9}>
                  <Card>
                      <CardBody>
                          <h5 className="fw-semibold">Payment Receipt</h5>

                          <div className="table-responsive">
                              <table className="table">
                                  <tbody>
                                      <tr>
                                        <td scope="col" style={{width:"40%"}}>Receipt No.</td>
                                        <th scope="col" style={{width:"60%"}}>{receiptId}</th>
                                      </tr>
                                      <tr>
                                        <td>Date</td>
                                        <th scope="row">{getFormatedDateStr(paymentDetails.receiptDate,'DD-MMM-YY')}</th>
                                      </tr>
                                      <tr>
                                        <td>Name</td>
                                        <th scope="row">{empDetails.name}</th>
                                      </tr>
                                      <tr>
                                        <td>Address</td>
                                        <th scope="row">{getEmpAddress()}</th>
                                      </tr>
                                      <tr>
                                        <td>Reference No.</td>
                                        <th scope="row">{paymentDetails.refNo}</th>
                                      </tr>
                                      <tr>
                                        <td>Mobile No.</td>
                                        <th scope="row">{empDetails.mobileNo}</th>
                                      </tr>
                                      <tr>
                                        <td>Amount (Rs)</td>
                                        <th scope="row">{Number(paymentDetails.subsAmount).toLocaleString('en-IN',{minimumFractionDigits: 2})}</th>
                                      </tr>
                                      <tr>
                                        <td>Amount in Words</td>
                                        <th scope="row">{amountInWords}</th>
                                      </tr>
                                      <tr>
                                        <td>Mode of Payment</td>
                                        <th scope="row">{paymentDetails.modeOfPayment}</th>
                                      </tr>
                                      <tr>
                                        <td>Contribution Period</td>
                                        <th scope="row">{paymentDetails.contMonthsDesc}</th>
                                      </tr>
                                  </tbody>
                              </table>
                          </div>
                          <div className="hstack gap-2 d-print-none">
                              <button onClick={()=>onCreateNewPayment()} className="btn btn-soft-info w-100">
                              <i className="mdi mdi-cash-plus font-size-18 align-middle" /> New Payment
                              </button>
                              <button disabled={downLoadInProgress} onClick={()=>downloadReceipt()} className="btn btn-soft-primary w-100">
                                <i className="mdi mdi-download font-size-18 align-middle" /> Download
                                {downLoadInProgress && <i className="bx bx-loader bx-spin font-size-18 align-middle ms-2" />}
                              </button>
                              <button disabled={downLoadInProgress} onClick={()=>sendReceipt()} className="btn btn-soft-success w-100">
                                <i className="mdi mdi-whatsapp font-size-18 align-middle" /> Send
                                {downLoadInProgress && <i className="bx bx-loader bx-spin font-size-18 align-middle ms-2" />}
                              </button>
                          </div>
                      </CardBody>
                  </Card>
              </Col>
            </Row>
          </div>
          }

          <Modal
            size="lg"
            isOpen={showModal}
            toggle={() => {
              toggleModal();
            }}
            >
            <div className="modal-header">
              <h5
                className="modal-title mt-0"
                id="myExtraLargeModalLabel"
              >
                Confirm Changes
              </h5>
              <button
                onClick={() => {
                  setShowModal(false);
                }}
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body mb-0">
              <EditPaymentChanges changedFields={modifiedFields} oldValues={previousPaymentDetails} newValues={paymentDetails} onClose={()=>setShowModal(false)}/>
            </div>
            <div className="text-center clearfix mb-3">
              <Button
                color="secondary"
                className="btn w-lg btn-secondary me-2"
                onClick={() => {
                  setShowModal(false);
                }}
                >
                  Close
              </Button>
              <Button
                color="success"
                className="btn w-lg btn-success "
                onClick={() => {
                  confirmEditPayment();
                }}
                >
                  Confirm
              </Button>
            </div>
          </Modal>

        </Container>
      </div>
    </React.Fragment>
  );
};


export default withRouter(AddPayment);
