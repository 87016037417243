import React, { useEffect, useState } from "react"
import Select from "react-select";
import {retrieveDetails} from "helpers/backend_helper"

const RegistrantsDropDown = props => {

  const [empDropDown, setEmpDropDown] = useState([])
  const [selectedOption, setSelectedOption] = useState()
  const [loadInProgress, setLoadInProgress] = useState(false)

  useEffect(()=>{
    getEmlpoyeeDropDown()
  },[])

  useEffect(()=>{
    if(props.defaultId && empDropDown){
      const selected = empDropDown.filter(each => each.id == props.defaultId)
      if(selected && selected.length > 0){
        setSelectedOption({
          ...selected[0],
          value:selected[0].id,
        })
      }
    }
    else{
      setSelectedOption(null)
    }
  },[props.defaultId, empDropDown])

  const onSelectTheDropDown=(value)=>{
    if(props.isMulti){
      props.selectOnChangeMulti(value)
    }
    else{
      props.selectOnChange(value)
    }
  }

  const getEmlpoyeeDropDown = async () =>{
    setLoadInProgress(true)
    let inputToSend = {
      "data" : {
        "type" : "REG_DD"
      }
    }
    const result = await retrieveDetails(inputToSend)
    if(result.status === 'success' && result.data){
      setEmpDropDown([...result.data])
    }
    setLoadInProgress(false)
  }

  // wlBranch wlBank
  //empNo, name, id, contact_no as contactNo, branch_name as wlBranch, wlBank, state
  const formatOptionLabel = ({ fullname, house_name, mobile_no, panchayath, place, reg_no }) => (
    <div style={{justifyContent: "space-between"}}>
      <div style={{paddingBottom:"0px"}}>{fullname} [{house_name}]</div>
      <div style={{ marginLeft: "2px", fontSize:"11px", marginTop:"-3px" }}>
        {mobile_no} | {place} | {reg_no}
      </div>
    </div>
  );

  return (
    <React.Fragment>
      <div className="pb-2">
        <Select
          value={selectedOption}
          isLoading={loadInProgress}
          isMulti={props.isMulti ? true : false}
          onChange={(e) => {
            onSelectTheDropDown(e)
            setSelectedOption(e)
          }}
          options={empDropDown}
          formatOptionLabel={formatOptionLabel}
          classNamePrefix="select2-selection"
        />
      </div>
    </React.Fragment>
  )
}

RegistrantsDropDown.propTypes = {
}

export default RegistrantsDropDown
